import { Component, OnDestroy, OnInit } from "@angular/core";
import { tap } from "rxjs/operators";

import { Incident } from "src/app/dto/items/incident";
import { Change, CHANGE_TYPE } from "src/app/dto/net/change";
import { EventsMissionsService } from "src/app/incident/event-mission.service";
import { MESSAGE_TYPE } from "src/app/global/messaging/messages";
import { MessagingService } from "src/app/global/messaging/messaging.service";
import { AutomationService } from "../../automation.service";
import { BufferProvider } from "../../buffer/buffer.provider";
import { Subscription } from "rxjs";

@Component({
	selector: "app-incident-player",
	templateUrl: "app-incident-player.component.html",
	styleUrls: ["app-incident-player.css"]
})
export class IncidentPlayerComponent implements OnInit, OnDestroy {
	public changes = new Array<Change>();
	public playing: boolean = false;
	public playbackFraction = 0;
	public speed = 1;
	public replay: boolean = false;

	private readonly bufferProvider: BufferProvider;
	private readonly auto: AutomationService;
	private readonly ems: EventsMissionsService;
	private readonly mssg: MessagingService;

	private playbackTimeReference_ms = 0;
	private mission: Incident;
	private t_delta: number = 0;

	private initialX = 0;
	private playerBar: HTMLElement = document.createElement("div") as HTMLElement; //temp value until is possible to get html player bar reference

	private readonly subscriptions = new Subscription();
	private firstTick = false;

	constructor(bp: BufferProvider, automs: AutomationService, ems: EventsMissionsService, mssg: MessagingService) {
		this.mssg = mssg;
		this.auto = automs;
		this.ems = ems;
		this.bufferProvider = bp;

		this.mission = ems.getCurrentMission()!;

		this.bufferProvider.$filled.subscribe(() => {
			this.changes = this.bufferProvider.changes.filter((e) => e.__time_percent! > 0 && e.__time_percent! < 100 && e.datatype !== CHANGE_TYPE.RESOURCE);
		});

		this.changes = this.bufferProvider.changes.filter((e) => e.__time_percent! > 0 && e.__time_percent! < 100 && e.datatype !== CHANGE_TYPE.RESOURCE);

		mssg.registerListener(
			MESSAGE_TYPE.PLAY_MODE_CHANGE,
			(liveMode: boolean) => {
				if (liveMode) this.playbackFraction = 0;
				else {
					if (this.bufferProvider.buffered) {
						this.movePlaybackToFraction(0);
					} else {
						this.movePlaybackToFraction(0);
					}
				}
			},
			true
		);
	}

	ngOnInit(): void {
		this.bufferProvider.timestamp = this.mission.start_time_ms;
		this.auto.updateRecordingTimestamp(this.bufferProvider.timestamp);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	public readonly startPlaying: Function = () => {
		this.playing = true;
		const timerListener = this.auto.tick$.subscribe(() => {
			if (!this.firstTick) {
				this.bufferProvider.timestamp = this.mission.start_time_ms;
				this.playbackTimeReference_ms = Date.now();
				this.firstTick = true;
			}
			this.t_delta = Date.now() - this.playbackTimeReference_ms;
			this.bufferProvider.timestamp += this.t_delta * this.speed;
			this.moveVideo(this.bufferProvider.timestamp, false);
			this.playbackFraction = (this.bufferProvider.timestamp - this.mission.start_time_ms) / this.mission.duration;
			this.playbackTimeReference_ms = Date.now();
			this.auto.updateRecordingTimestamp(this.bufferProvider.timestamp);
		});
		this.subscriptions.add(timerListener);
		this.mssg.fire(MESSAGE_TYPE.PLAY_PAUSE_CHANGE, this.auto.go);
	};

	public readonly getPlaybackFraction: () => number = () => {
		const value = this.playbackFraction * 100;
		return value > 100 ? 100 : value < 0 ? 0 : value;
	};

	public readonly seek: (evt: MouseEvent) => void = (evt) => {
		let bar = evt.target as HTMLElement;
		const x = evt.offsetX;
		const percentage = x / bar.offsetWidth;
		this.movePlaybackToFraction(percentage);
	};

	public readonly isPaused: () => boolean = () => {
		return this.auto.go;
	};

	public readonly playPause: Function = () => {
		this.auto.go = !this.auto.go;
		if (this.auto.go) this.playbackTimeReference_ms = Date.now();
		this.mssg.fire(MESSAGE_TYPE.PLAY_PAUSE_CHANGE, this.auto.go);
		//TO-ASK
		if (this.ems.getCurrentMission()!.end_time_ms) {
			this.ngOnInit();
		}
		/* --- MILESTONE CODE ---
        var iframe = document.getElementById("milestoneIframe");
        if (iframe) iframe.contentWindow.postMessage("playpause", '*');
        */
	};
	public readonly isOver: () => boolean = () => {
		if (this.ems.getCurrentMission()!.end_time_ms <= this.getCurrentTimestamp()) return true;
		return false;
	};
	public readonly getCurrentSpeed: () => number = () => {
		return this.speed;
	};

	public readonly increaseSpeed: Function = () => {
		switch (this.speed) {
			case 1:
				this.speed = 4;
				return;
			case 4:
				this.speed = 60;
				return;
			default:
				this.speed = 1;
		}
	};

	public readonly getFirstTimestamp: () => number = () => {
		return this.ems.getCurrentMission()!.start_time_ms;
	};

	public readonly getLastTimestamp: () => number = () => {
		return this.ems.getCurrentMission()!.end_time_ms;
	};

	public readonly getCurrentTimestamp: () => number = () => {
		let currentTimestamp = this.getFirstTimestamp() + (this.getLastTimestamp() - this.getFirstTimestamp()) * this.playbackFraction;
		return currentTimestamp;
	};

	public readonly pastHalfwayPoint: () => boolean = () => {
		return this.playbackFraction >= 0.5;
	};

	public readonly moveBackwards: Function = () => {
		var last = this.bufferProvider.changes.length - 1;
		for (var i = last; i >= 0; i--) {
			if (this.bufferProvider.changes[i].last_update < this.bufferProvider.timestamp - 5000) {
				this.bufferProvider.changes[i].last_update < this.mission!.start_time_ms ? this.movePlaybackToTimestamp(this.mission!.start_time_ms) : this.movePlaybackToTimestamp(this.bufferProvider.changes[i].last_update);
				break;
			} else if (last == 0) this.movePlaybackToTimestamp(this.mission!.start_time_ms);
		}
	};

	public readonly moveForward: Function = () => {
		for (var i = 0; i < this.bufferProvider.changes.length; i++) {
			if (this.bufferProvider.changes[i].last_update > this.bufferProvider.timestamp) {
				this.bufferProvider.changes[i].last_update > this.mission!.end_time_ms ? this.movePlaybackToTimestamp(this.mission!.end_time_ms) : this.movePlaybackToTimestamp(this.bufferProvider.changes[i].last_update);
				break;
			} else if (i == this.bufferProvider.changes.length - 1) this.movePlaybackToTimestamp(this.mission!.end_time_ms);
		}
	};

	public readonly movePlaybackToFraction: Function = (fraction: number) => {
		this.playbackFraction = fraction > 1 ? 1 : fraction < 0 ? 0 : fraction;
		var mission = this.ems.getCurrentMission();
		if (!this.auto.liveMode && mission) {
			this.playbackTimeReference_ms = Date.now();
			this.bufferProvider.timestamp = mission.start_time_ms + mission.duration * this.playbackFraction;
			this.auto.go = true;
			this.auto.tryUpdate();
			this.moveVideo(this.bufferProvider.timestamp, true);
		}
	};
	public readonly movePlaybackToTimestamp: Function = (timestamp: number) => {
		const mission = this.ems.getCurrentMission()!;
		this.playbackFraction = (timestamp - mission.start_time_ms) / mission.duration;
		if (!this.auto.liveMode && mission) {
			this.playbackTimeReference_ms = Date.now();
			this.bufferProvider.timestamp = timestamp;
			this.auto.go = true;
			this.auto.tryUpdate();
			this.moveVideo(this.bufferProvider.timestamp, true);
		}
	};

	public readonly cursorMousedown: Function = (evt: MouseEvent) => {
		this.initialX = evt.clientX;
		this.playerBar = (evt.target as HTMLElement).parentElement as HTMLElement;
		document.onmousemove = this.cursorDrag as (this: GlobalEventHandlers, evt: MouseEvent) => any;
		document.onmouseup = this.stopDragging as (this: GlobalEventHandlers, evt: MouseEvent) => any;
	};

	public readonly cursorTouchstart = (evt: TouchEvent): void => {
		this.initialX = evt.touches[0].clientX;
		this.playerBar = (evt.target as HTMLElement).parentElement as HTMLElement;
		document.ontouchmove = this.cursorDrag as any;
		document.addEventListener("touchmove", this.cursorDrag as any);
		document.addEventListener("touchcancel", this.stopDragging as any);
		document.addEventListener("touchend", this.stopDragging as any);
	};

	public readonly cursorDrag = (evt: MouseEvent | TouchEvent): void => {
		let newX: number;
		if (evt instanceof MouseEvent) newX = evt.clientX;
		else newX = (evt as TouchEvent).touches[0].clientX;
		const deltaX = newX - this.initialX;
		this.initialX = newX;
		const newProportion = this.getPlaybackFraction() / 100 + deltaX / this.playerBar.offsetWidth;
		this.movePlaybackToFraction(newProportion);
	};

	public readonly stopDragging: Function = (evt: MouseEvent | TouchEvent) => {
		this.initialX = 0;
		document.onmousemove = document.onmouseup = null;
	};

	private readonly moveVideo = (ts: number, isSkip: boolean): void => {
		this.mssg.fire(MESSAGE_TYPE.TIMESTAMP_CHANGE, {
			ts: ts,
			isSkip: isSkip
		});

		/* --- OLD MILESTONE CODE, IN CASE IT STILL EXISTS --- */
		var iframe = document.getElementById("milestoneIframe");
		if (iframe) (iframe as any).contentWindow.postMessage("goto" + this.bufferProvider.timestamp + "_" + Date.now(), "*");
	};
}
