import { CloneFactory } from "../net/clone-factory";
import { CommandStructure } from "./command-structure";
import { CSCommander } from "./cs-commander";
import { CSResourceRelation } from "./cs-resource";
import { CSSector } from "./cs-sector";
import { CSSupport } from "./cs-support";

export class CommandStructureCollection {
	private structures: Array<CommandStructure>;

	constructor() {
		this.structures = new Array();
	}
	// GET AREA

	public readonly getStructureByCommanderId: (id: number) => CommandStructure | undefined = (id: number) => {
		return this.structures.find((e) => e.id === id);
	};

	public readonly getStructureByMissionId: (id: number) => CommandStructure | undefined = (id) => {
		return this.structures.find((e) => e.id_mission === id);
	};

	public readonly getCommander: Function = (id: number) => {
		return this.structures.find((e) => e.id === id)?.commander;
	};

	public getStructureBySectorId(id_sector: number): CommandStructure | undefined {
		const sector = this.getSector(id_sector);
		if(sector) return this.structures.find((e) => e.commander.id === sector.id_commander);
		return undefined;
	}

	public readonly getSector : (id: number) => CSSector | undefined = (id) => {
		for (let i = 0; i < this.structures.length; i++) {
			if (this.structures[i].sectors.find((e: CSSector) => e.id === id)) return this.structures[i].sectors.find((e: CSSector) => e.id === id);
		}
		return undefined;
	};

	public readonly getSectorByAreaId: (id: number) => CSSector | undefined = (id) => {
		for (let i = 0; i < this.structures.length; i++) {
			if (this.structures[i].sectors.find((e: CSSector) => e.id_area === id)) return this.structures[i].sectors.find((e: CSSector) => e.id_area === id);
		}
		return undefined;
	};

	public readonly getSectorByResourceId: Function = (id: number) => {
		for (let i = 0; i < this.structures.length; i++) {
			const ans = this.structures[i].sectors.find((e: CSSector) => e.resources.find((j: CSResourceRelation) => j.id === id));
			if (ans) return ans;
		}
		return undefined;
	};

	public readonly getSectorBySectorCommanderId: Function = (id: number) => {
		for (let i = 0; i < this.structures.length; i++) {
			const ans = this.structures[i].sectors.find((e: CSSector) => e.id_agent_comm === id);
			if (ans) return ans;
		}
		return undefined;
	};

	public readonly getCommanderByResourceId: (id: number) => CommandStructure | undefined = (id) => {
		return this.structures.find((e) => e.commander.id_resource === id);
	}


	public readonly getSectorBySafetyOfficerId: Function = (id: number) => {
		for (let i = 0; i < this.structures.length; i++) {
			const ans = this.structures[i].sectors.find((e: CSSector) => e.id_safety_officer === id);
			if (ans) return ans;
		}
		return undefined;
	};

	public readonly getResource: Function = (id: number) => {
		for (let i = 0; i < this.structures.length; i++) {
			for (let j = 0; j < this.structures[i].sectors.length; j++) {
				if (this.structures[i].sectors[j].resources.find((e: CSResourceRelation) => e.id === id)) return this.structures[i].sectors[j].resources.find((e: CSResourceRelation) => e.id === id);
			}
		}
		return undefined;
	};

	public readonly getResourceByAgentId: Function = (id: number) => {
		for (let i = 0; i < this.structures.length; i++) {
			for (let j = 0; j < this.structures[i].sectors.length; j++) {
				if (this.structures[i].sectors[j].resources.find((e: CSResourceRelation) => e.id_agent === id)) return this.structures[i].sectors[j].resources.find((e: CSResourceRelation) => e.id_agent === id);
			}
		}
		return undefined;
	};

	public readonly getSectorResources: Function = (id: number) => {
		for (let i = 0; i < this.structures.length; i++) {
			for (let j = 0; j < this.structures[i].sectors.length; j++) {
				if (this.structures[i].sectors[j].id === id) return this.structures[i].sectors[j].resources;
			}
		}
		return undefined;
	};

	public readonly getSupport: Function = (id: number) => {
		for (let i = 0; i < this.structures.length; i++) {
			let idx = this.structures[i].supports.findIndex((e) => e.id === id);
			if (idx > -1) return this.structures[i].supports[idx];
		}
		return undefined;
	};

	public readonly getAllSectors: () => Array<CSSector> = () => {
		let ans = new Array<CSSector>();
		this.structures.forEach((structure) => {
			structure.sectors.forEach((sector) => {
				if (!ans.find((el) => el.id_sector === sector.id_sector)) ans.push(sector);
			});
		});
		return ans;
	};

	// INSERT AREA

	public readonly insertStructure: Function = (cs: CommandStructure) => {
		const idx = this.structures.findIndex((e) => e.id === cs.id);
		if (idx === -1) this.structures.push(cs);
		else this.structures.splice(idx, 1, cs);
	};

	public readonly insertSupport: Function = (supp: CSSupport) => {
		let oldSupp = this.getSupport(supp.id);
		if (oldSupp) CloneFactory.cloneProperties(oldSupp, supp);
		else {
			const cs = this.getStructureByCommanderId(supp.id_commander);
			if (cs) cs.supports.push(supp);
		}
	};

	public readonly insertCommander: Function = (comm: CSCommander) => {
		let oldComm = this.getCommander(comm.id);
		if (oldComm) {
			CloneFactory.cloneProperties(oldComm, comm);
		} else {
			this.insertStructure(new CommandStructure(comm));
		}
	};

	public readonly insertSector: Function = (sector: CSSector) => {
		let oldS = this.getSector(sector.id);
		if (oldS) {
			CloneFactory.cloneProperties(oldS, sector);
		} else {
			const cs = this.getStructureByCommanderId(sector.id_commander);
			if (cs) cs.sectors.push(sector);
		}
	};


	public readonly insertResource: (resource: CSResourceRelation) => boolean = 
	(resource: CSResourceRelation) => {
		const sector = this.getSector(resource.id_sector);
		if (!sector) return false;
		const comm = this.getStructureBySectorId(sector.id);
		if (!comm) return false;
		// database procedure already has checks to prevent duped resource/sector relations, 
		// but if timing is unfortunate (two people move the same resource on two different sectors at the same time) 
		// frontend might get two lastchanges for same relation so we need to check to prevent displaying the same resource on two sectors
		// we only want to do this for the current incident, otherwise we might remove a resource for the cs of a closed incident!
		this.removeResourceOnIncidentByAgentId(resource.id_agent, comm.id_mission);
		
		let oldR = sector.resources.find((e: CSResourceRelation) => e.id === resource.id);
		if (!oldR) sector.resources.push(resource);
		else CloneFactory.cloneProperties(oldR, resource);

		return true;
	};

	public readonly insertResourceByIncident: (resource: CSResourceRelation, id_incident: number) => boolean = 
	(resource: CSResourceRelation, id_incident: number) => {
		const sector = this.getSector(resource.id_sector);
		if (!sector) return false;
		// database procedure already has checks to prevent duped resource/sector relations, 
		// but if timing is unfortunate (two people move the same resource on two different sectors at the same time) 
		// frontend might get two lastchanges for same relation so we need to check to prevent displaying the same resource on two sectors
		// we only want to do this for the current incident, otherwise we might remove a resource for the cs of a closed incident!
		this.removeResourceOnIncidentByAgentId(resource.id_agent, id_incident);
		
		let oldR = sector.resources.find((e: CSResourceRelation) => e.id === resource.id);
		if (!oldR) sector.resources.push(resource);
		else CloneFactory.cloneProperties(oldR, resource);

		return true;
	};

	public readonly isResourceInIncident : (res : CSResourceRelation) => number = (res) => { // returns id of sector
		let ans = -1;
		const sector = this.getSector(res.id_sector);
		const commander = this.getCommander(sector?.id_commander);
		const missionId = commander.id_mission;
		if(!missionId) return ans;
		const struct = this.getStructureByMissionId(missionId);
		struct?.sectors.forEach((sector) => {
			if(sector.resources.find((e) => e.id_agent === res.id_agent)) ans = sector.id;
		})
		return ans;
	} 

	public readonly count: Function = () => {
		return this.structures.length;
	};

	// REMOVE AREA

	public readonly removeStructure: Function = (id: number) => {
		let idx = this.structures.findIndex((e) => e.id === id);
		if (idx > -1) {
			this.structures.splice(idx, 1);
			return true;
		}
		return false;
	};

	public readonly removeSupport: Function = (id_supp: number) => {
		for (let i = 0; i < this.structures.length; i++) {
			let idx = this.structures[i].supports.findIndex((e) => e.id === id_supp);
			if (idx > -1) {
				this.structures[i].supports.splice(idx, 1);
				return true;
			}
		}
		return false;
	};

	public readonly removeSector: Function = (id_sector: number) => {
		for (let i = 0; i < this.structures.length; i++) {
			let idx = this.structures[i].sectors.findIndex((e) => e.id === id_sector);
			if (idx > -1) {
				this.structures[i].sectors.splice(idx, 1);
				return true;
			}
		}
		return false;
	};

	public readonly removeResource: Function = (res: CSResourceRelation | number) => {
		let sector: CSSector | undefined;
		let id: number;
		if (typeof res === "number") {
			sector = this.getSectorByResourceId(res);
			id = res;
		} else {
			id = res.id;
			sector = this.getSector(res.id_sector);
			if(!sector) return false;
		}
		if (!sector) return false;
		const idx = sector.resources.findIndex((e: CSResourceRelation) => e.id === id);
		if (idx > -1) {
			sector.resources.splice(idx, 1);
			return true;
		}
		return false;
	};

	public readonly removeResourceOnIncidentByAgentId: (id_resource: number, id_incident: number) => boolean = (id_resource: number, id_incident: number) => {
		const struct = this.structures.find((e) => e.id_mission === id_incident);
		if(!struct) return false;
			for (let i = 0; i < struct.sectors.length; i++) {
				const idx = struct.sectors[i].resources.findIndex((e: CSResourceRelation) => e.id_agent === id_resource);
				if (idx > -1) {
					struct.sectors[i].resources.splice(idx, 1);
					return true;
				}
			}
		return false;
	};


	// UPDATE AREA

	public readonly updateAllSupports: Function = (arr: Array<CSSupport>) => {
		this.structures.forEach((struct) => {
			struct.supports = arr.filter((e) => e.id_commander === struct.id);
		});
	};

	public readonly updateSupports: (id_comm: number, arr: Array<CSSupport>) => void = (id_comm, arr: Array<CSSupport>) => {
		let structure = this.structures.find((e) => e.id === id_comm);
		if (structure) {
			structure.supports = arr;
		}
	};

	public readonly updateSectors: Function = (sectors: Array<CSSector>) => {
		sectors.forEach((sector) => {
			let struct = this.getStructureByCommanderId(sector.id_commander);
			if (struct) {
				let idx = struct.sectors.findIndex((e: CSSector) => e.id === sector.id);
				if (idx > -1) CloneFactory.cloneProperties(struct.sectors[idx], sector);
				else struct.sectors.push(sector);
			}
		});
	};

	public readonly updateResources: Function = (resources: Array<CSResourceRelation>) => {
		resources.forEach((resource) => {
			let sector = this.getSector(resource.id_sector);
			if (sector) {
				const idx = sector.resources.findIndex((e: CSResourceRelation) => e.id === resource.id);
				if (idx > -1) CloneFactory.cloneProperties(sector.resources[idx], resource);
				else sector.resources.push(resource);
			}
		});
	};

	// unload

	public readonly unload: Function = () => {
		this.structures.length = 0;
	};
}
