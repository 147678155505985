<div class="container {{ show() ? '' : 'invisible' }}">
	<span>{{ getText() }}</span>
</div>

<div class="spinner-container {{ show() ? '' : 'invisible' }}">
	<lottie-player
		src="{{ 'resources/lottie/loading-blue.json' | baseHref }}"
		background="transparent"
		speed="1"
		autoplay
	></lottie-player>
</div>
