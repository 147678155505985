import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, Sanitizer, ViewChild, ViewChildren, ɵdetectChanges } from "@angular/core";
import { Event } from "src/app/dto/items/event";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import * as html2canvas from "html2canvas";
import { DocumentService } from "src/app/document/document.service";
import { DocumentTemplate } from "../../../template.interface";
import { FormsService } from "src/app/incident/incident-tools/forms/forms.service";
import { Member } from "src/app/dto/jesip/member";
import { JESIP_FORMS } from "src/app/global/constants/enums/jesip_forms";
import { IiMarch, IIMARCH_ITEM } from "src/app/dto/jesip/iimarch";
import { UserService } from "src/app/settings/user/user.service";
import { User } from "src/app/dto/user/user";
import { PAGE_SIZES } from "src/app/dto/Documents/page-sizes";

@Component({
	selector: "app-iimarch-current-template",
	templateUrl: "iimarch-current.component.html",
	styleUrls: ["iimarch-current.css", "../../jesip.css", "../../../../document.css"]
})
export class CurrentIimarchTemplateComponent implements OnInit, DocumentTemplate, AfterViewInit {
	@Input() generationTime!: number;
	@Input() event!: Event;
	@Input() iimarch!: IiMarch;

	@Output() docReady = new EventEmitter<HTMLCanvasElement[]>();

	@ViewChildren("pageElements") pageElements!: QueryList<ElementRef>;
	@ViewChild("inspectedOptionElement") inspectedOptionElement!: ElementRef;
	@ViewChild("inspectedHeaderElement") inspectedHeaderElement!: ElementRef;

	public inspectedHeader: boolean = false;
	public inspectedOption: IimarchItem | undefined;

	public numberOfPages: number = 1;
	public pages = new Array<Array<IimarchItem>>();
	public readonly pageSize = PAGE_SIZES.STANDARD;

	public filledBy = new User(-1);
	public commanders = new Array<Member>();

	public readonly text: () => LocaleMap;

	private readonly jesip: FormsService;
	private readonly userService: UserService;
	private readonly docService: DocumentService;
	private abortDownload = false;

	constructor(tp: TextProvider, doc: DocumentService, jes: FormsService, user: UserService) {
		this.text = tp.getStringMap;
		this.jesip = jes;
		this.userService = user;
		this.docService = doc;
	}

	ngOnInit(): void {
		if (!this.event) this.docService.downloadError$.next();
		this.commanders = this.jesip.teamInfo.get(JESIP_FORMS.METHANE) ? this.jesip.teamInfo.get(JESIP_FORMS.METHANE)! : new Array<Member>();
		this.filledBy = this.userService.Users.find((e) => e.id === this.iimarch.id_user)!;
		this.setPages();
		this.docService.onDownloadCancel$.subscribe(() => (this.abortDownload = true));
	}

	async ngAfterViewInit(): Promise<void> {
		try {
			const pages = this.pageElements["_results"];
			//Patch for preventing browser crash aborting de generation of documents and download
			if (pages.length >= 20) {
				this.docService.setUnavailabeError();
				return;
			}
			const output = new Array<HTMLCanvasElement>();
			for (let i = 0; i < pages.length; i++) {
				if (!this.docService.downloadingFullIncident) this.docService.setLoadingMessage(this.text().GENERATING_DOCUMENTATION_PAGE(i, pages.length));
				else {
					console.info(this.text().GENERATING_DOCUMENTATION_PAGE(i, pages.length));
					this.docService.setLoadingMessage(this.text().DOWNLOAD_PROCESS_TEXT);
				}
				const canvas = await html2canvas.default(this.pageElements["_results"][i].nativeElement);
				if (this.abortDownload) return;
				output.push(canvas);
			}
			this.docReady.emit(output);
		} catch (error) {
			if (this.abortDownload) return;
			console.error(error);
			this.docService.downloadError$.next();
		}
	}

	public readonly setPages: Function = () => {
		let page = new Array<IimarchItem>();
		let height = this.calculateHeaderHeight();
		let index = 0;
		for (let keys in IIMARCH_ITEM) {
			const key = keys as keyof IiMarch;
			const newOption = new IimarchItem((this.text() as any)["JESIP_IIMARCH_ITEMS_TITLE_" + index], (this.text() as any)["JESIP_IIMARCH_ITEMS_DESCRIPTION_" + index], (this.iimarch as any)[key]);
			if (height + this.calculateOptionHeight(newOption) > this.pageSize.height - 150) {
				this.pages.push(page);
				page = new Array<IimarchItem>();
				height = this.calculateHeaderHeight();
			}
			page.push(newOption);
			height += this.calculateOptionHeight(newOption);
			index++;
		}
		if (!this.pages.find((e) => e === page)) this.pages.push(page);
	};

	private readonly calculateHeaderHeight: () => number = () => {
		this.inspectedHeader = true;
		ɵdetectChanges(this);
		const output = this.inspectedHeaderElement!.nativeElement.getBoundingClientRect().height;
		this.inspectedHeader = false;
		return output;
	};

	private readonly calculateOptionHeight: (opt: IimarchItem) => number = (opt) => {
		this.inspectedOption = opt;
		ɵdetectChanges(this);
		const output = this.inspectedOptionElement!.nativeElement.getBoundingClientRect().height;
		this.inspectedOption = undefined;
		return output;
	};
}

class IimarchItem {
	header: string;
	question: string;
	answer: string;

	constructor(h: string, q: string, a: string) {
		this.header = h;
		this.question = q;
		this.answer = a;
	}
}
