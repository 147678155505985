import { MAP_ITEM_TYPE } from "src/app/global/constants/enums/map-item-type";
import { Area } from "../items/area";
import { CircleArea } from "../items/circle-area";
import { Overlay } from "../items/overlay";
import { Poi } from "../items/poi";
import { PolygonArea } from "../items/polygon-area";
import { SHAPE_TYPE } from "../items/shape-type";
import { DTO } from "../net/dto";
import { Resource } from "../resources/resource";
import { BufferItem } from "./buffer-item";

export class ReplayBuffer implements DTO {
	id: number;
	timestamp: number;

	agents: Array<BufferItem>;

	areas: Array<BufferItem>;

	pois: Array<BufferItem>;

	overlays: Array<BufferItem> = [];

	constructor(id: number, agents?: Array<BufferItem>, areas?: Array<BufferItem>, pois?: Array<BufferItem>, timestamp?: number) {
		this.id = id;
		this.timestamp = timestamp ? timestamp : Date.now();

		this.agents = agents
			? agents.map(function (agent) {
					return new BufferItem(agent.json, MAP_ITEM_TYPE.RESOURCE, agent.timestamp, Resource.fromJson(agent.json));
			  })
			: [];
		this.areas = areas
			? areas.map(function (area) {
					const object = Area.getTypeFromJson(area.json) === SHAPE_TYPE.CIRCLE ? CircleArea.fromJson(area.json) : PolygonArea.fromJson(area.json);
					return new BufferItem(area.json, MAP_ITEM_TYPE.AREA, area.timestamp, object);
			  })
			: [];
		this.pois = pois
			? pois.map(function (poi) {
					return new BufferItem(poi.json, MAP_ITEM_TYPE.POI, poi.timestamp, Poi.fromJson(poi.json));
			  })
			: [];
	}

	public static fromJson: Function = (json: string) => {
		const rb = JSON.parse(json);
		return new ReplayBuffer(rb.id, rb.agents, rb.areas, rb.pois, rb.timestamp);
	};

	// get the array situation for the specified timestamp
	public get: (itemName: MAP_ITEM_TYPE, timestamp: number) => string[] = (itemName, timestamp) => {
		let array = this.getArray(itemName);
		if (!array || array.length === 0) return [];

		let filteredArray: BufferItem[] = array.reduce((items: BufferItem[], item: BufferItem) => {
			if (item.timestamp <= timestamp) {
				const idx = items.findIndex((e) => e.object?.id === item.object?.id);
				if (idx === -1) items.push(item);
				else if (item.timestamp > items[idx].timestamp) items[idx] = item;
			}
			return items;
		}, []);
		return filteredArray.map((a) => a.json);
	};

	public getAllValues: (itemName: MAP_ITEM_TYPE, timestamp: number) => string[] = (itemName, timestamp) => {
		let array = this.getArray(itemName);
		if (!array || array.length === 0) return [];

		let filteredArray: BufferItem[] = array.reduce((items: BufferItem[], item: BufferItem) => {
			if (item.timestamp <= timestamp) {
				items.push(item);
			}
			return items;
		}, []);
		return filteredArray.map((a) => a.json);
	};

	public getJson = (): string => JSON.stringify(this, ["id", "agents", "areas", "pois", "timestamp"]);

	public readonly setItemArray: (arr: Array<string>, type: MAP_ITEM_TYPE) => void = (arr, type) => {
		switch (type) {
			case MAP_ITEM_TYPE.OVERLAY:
				this.overlays = arr.map(function (item) {
					return new BufferItem(item, MAP_ITEM_TYPE.OVERLAY, 0, Overlay.fromJson(item));
				});
				break;
		}
	};

	private readonly getArray: (itemName: MAP_ITEM_TYPE) => Array<BufferItem>  = (itemName) => {
		let array = new Array<BufferItem>();
		//Get the array and last item index
		switch (itemName) {
			case MAP_ITEM_TYPE.RESOURCE:
				array = this.agents;
				break;
			case MAP_ITEM_TYPE.AREA:
				array = this.areas;
				break;
			case MAP_ITEM_TYPE.POI:
				array = this.pois;
				break;
			case MAP_ITEM_TYPE.OVERLAY: // overlays are not temporalized yet, so at any time we'll load all of them
				array = this.overlays;
		}
		return array;

	}
}
