import { Component } from "@angular/core";
import { MainService } from "../../main.service";

@Component({
	selector: "app-main-load-snackbar",
	templateUrl: "app-main-load-snackbar.component.html",
	styleUrls: ["app-main-load-snackbar.css"]
})
export class MainLoadSnackbarComponent {
	public main: MainService;

	constructor(main: MainService) {
		this.main = main;
	}

	public readonly show: () => boolean = () => {
		return this.main.showLoadSnackbar;
	};

	public readonly getText: () => string = () => {
		return this.main.loadSnackbarText;
	};
	public readonly onCancel = (): void => {
		this.main.showLoadSnackbar = false;
	};
}
