import { Incident } from "./incident";
import { EventType } from "./types/event-type";

export class Event {
	id: number;
	name: string;
	perimeter: number;
	type: number;
	id_mission: number;
	num: number;
	timestamp: number;
	mission: Incident | undefined;
	__typeObj: EventType | undefined;

	constructor(id: number, name: string, perimeter: number, type: number, id_mission: number, num: number, timestamp?: number) {
		this.id = id;
		this.name = name;
		this.perimeter = perimeter;
		this.type = type;
		this.id_mission = id_mission;
		this.num = num;
		this.timestamp = timestamp ? timestamp : 0;
	}

	public static readonly fromJson: (json: string) => Event = (json: string) => {
		var jm = JSON.parse(json);
		return new Event(jm.id, jm.name, jm.perimeter, jm.type, jm.id_mission, jm.num, jm.timestamp);
	};
}
