import { MAP_ITEM_TYPE } from "src/app/global/constants/enums/map-item-type";
import { EsriMarker } from "src/app/incident/map/components/map/esri/components/graphics/esri-marker/esri-marker.interface";
import { MapItem } from "../items/map-item";
import { GMapMarker } from "../map/gmap-marker";
import { DTO } from "../net/dto";
import { UploadedFile } from "../net/uploaded-file";
import { FsgFlatHistory } from "./flat-history";
import { FsgFlat } from "./fsg-flat";

export class FsgBuilding implements DTO, MapItem {
	id_fsg: number;
	id_mission: number;
	id_last_mission: number;
	address: string;
	lat: number;
	lon: number;
	name: string;
	fire_floor: number;
	fire_flat: number;
	deleted: boolean;
	timestamp_ms: number;
	flat_per_floor: number;
	floor_number: number;
	visible: boolean;
	id_user: number;
	active: boolean;
	last_update_ms: number;
	creation_ms: number;
	naming_convention: number;

	__flats: Array<FsgFlat> | undefined;
	__last_update_ts: number | undefined;
	__history: Array<FsgFlatHistory> | undefined;

	description: string = "";
	fire_flat_name: string = "";
	attachment_id: number = -1;
	attachment_filename: string = "";

	itemType: MAP_ITEM_TYPE | null = MAP_ITEM_TYPE.FSG;
	__typeObj = undefined;
	__shape: any = undefined;
	__marker: GMapMarker | EsriMarker | undefined;
	__markerAttachment: any;
	__showRadialMenu: boolean | undefined;
	attachmentList: Array<UploadedFile> = [];

	id: number;
	timestamp = Date.now();
	constructor(
		id_fsg: number,
		id_mission: number,
		id_last_mission: number,
		address: string,
		lat: number,
		lon: number,
		name: string,
		active: boolean,
		fire_floor?: number,
		fire_flat?: number,
		deleted?: boolean,
		timestamp_ms?: number,
		flat_per_floor?: number,
		floor_number?: number,
		id_user?: number,
		last_update_ms?: number,
		creation_ms?: number,
		naming?: FSG_NAMING_CONVENTION,
		description?: string
	) {
		this.id = this.id_fsg = id_fsg;
		this.id_mission = id_last_mission;
		this.id_last_mission = id_last_mission;
		this.address = address;
		this.lat = lat;
		this.lon = lon;
		this.name = name;
		this.active = active ? active : false;
		this.fire_floor = fire_floor ? fire_floor : -1;
		this.fire_flat = fire_flat ? fire_flat : -1;
		this.deleted = deleted ? deleted : false;
		this.timestamp_ms = timestamp_ms ? timestamp_ms : Date.now();
		this.flat_per_floor = flat_per_floor ? flat_per_floor : -1;
		this.floor_number = floor_number ?? 0;
		this.visible = true;
		this.id_user = id_user ? id_user : -1;
		this.last_update_ms = last_update_ms ? last_update_ms : -1;
		this.creation_ms = creation_ms ? creation_ms : -1;
		this.naming_convention = naming ? naming : FSG_NAMING_CONVENTION.ALPHABETICAL;
		this.description = description ? description : "";
	}

	public static readonly fromJson: (json: string) => FsgBuilding = (json: string) => {
		const jc = JSON.parse(json);
		if (typeof jc.condition === "string") jc.condition = jc.condition.split(",");
		return new FsgBuilding(
			jc.id_fsg,
			jc.id_mission,
			jc.id_last_mission,
			jc.address,
			jc.lat,
			jc.lon,
			jc.name,
			jc.active,
			jc.fire_floor,
			jc.fire_flat,
			jc.deleted,
			jc.timestamp_ms,
			jc.flat_per_floor,
			jc.floor_number,
			jc.idUser,
			jc.last_update_ms,
			jc.creation_ms,
			jc.naming_convention,
			jc.description
		);
	};

	public belongsToCurrentMission(currentMissionId: number): boolean {
		return currentMissionId === this.id_last_mission;
	}

	public getLastUpdateTs(): number {
		let tss = [this.creation_ms, this.last_update_ms];
		if(this.__flats && this.__flats.length > 0) tss = tss.concat(this.__flats!.map((flat) => flat.timestamp_ms));
		return tss.reduce((a, b) => {
			return a > b ? a : b;
		})
	}

	public readonly getJson: Function = () => {
		// @ts-ignore
		this.idUser = this.id_user;
		return JSON.stringify(this, ["id_fsg", "address", "id_last_mission", "id_mission", "flat_per_floor", "floor_number", "lat", "lon", "name", "active", "deleted", "fire_floor", "fire_flat", "timestamp_ms", "idUser", "last_update_ms", "creation_ms", "naming_convention", "description"]);
	};
}

export enum FSG_NAMING_CONVENTION {
	ALPHABETICAL = 1,
	NUMERIC_PER_FLOOR = 2,
	NUMERIC_PER_BUILDING = 3
}
