import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, Sanitizer, ViewChild, ViewChildren, ɵdetectChanges } from "@angular/core";
import { Event } from "src/app/dto/items/event";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import * as html2canvas from "html2canvas";
import { DocumentService } from "src/app/document/document.service";
import { DocumentTemplate } from "../../../template.interface";
import { DEBRIEF_OPTION } from "src/app/dto/jesip/debrief";
import { FormsService } from "src/app/incident/incident-tools/forms/forms.service";
import { Methane, METHANE_ITEM } from "src/app/dto/jesip/Methane";
import { PAGE_SIZES } from "src/app/dto/Documents/page-sizes";

@Component({
	selector: "app-methane-blank-template",
	templateUrl: "methane-blank.component.html",
	styleUrls: ["methane-blank.css", "../../jesip.css", "../../../../document.css"]
})
export class BlankMethaneTemplateComponent implements OnInit, DocumentTemplate, AfterViewInit {
	@Input() generationTime!: number;
	@Input() event!: Event;

	@Output() docReady = new EventEmitter<HTMLCanvasElement[]>();

	@ViewChildren("pageElements") pageElements!: QueryList<ElementRef>;

	public numberOfPages: number = 1;
	public pages = new Array<Array<MethaneItem>>();
	public readonly pageSize = PAGE_SIZES.STANDARD;

	public readonly text: () => LocaleMap;
	private readonly docService: DocumentService;
	private abortDownload = false;

	constructor(tp: TextProvider, doc: DocumentService) {
		this.text = tp.getStringMap;
		this.docService = doc;
	}

	ngOnInit(): void {
		if (!this.event) this.docService.downloadError$.next();
		this.setPages();
		this.docService.onDownloadCancel$.subscribe(() => this.abortDownload = true);
	}

	async ngAfterViewInit(): Promise<void> {
		try {
			const pages = this.pageElements["_results"];
			const output = new Array<HTMLCanvasElement>();
			for (let i = 0; i < pages.length; i++) {
				this.docService.setLoadingMessage(this.text().GENERATING_DOCUMENTATION_PAGE(i, pages.length));
				const canvas = await html2canvas.default(this.pageElements["_results"][i].nativeElement);
				if(this.abortDownload) return;
				output.push(canvas);
			}
			this.docReady.emit(output);
		} catch (error) {
			if(this.abortDownload) return;
			console.error(error);
			this.docService.downloadError$.next();
		}
	}

	public readonly setPages: Function = () => {
		const page = new Array<MethaneItem>();
		let index = 0;
		for (let keys in METHANE_ITEM) {
			page.push(new MethaneItem((this.text() as any)["JESIP_METHANE_ITEMS_TITLE_" + index], (this.text() as any)["JESIP_METHANE_ITEMS_DESCRIPTION_" + index]));
			index++;
		}
		this.pages.push(page);
	};
}

class MethaneItem {
	header: string;
	question: string;

	constructor(h: string, q: string) {
		this.header = h;
		this.question = q;
	}
}
