import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ConfigurationService } from "src/app/settings/types/configuration.service";
import { ResourceType } from "src/app/dto/items/types/resource-type";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { LocaleMap } from "src/app/global/constants/text/text-interface";

@Component({
	selector: "app-new-resource-type-modal",
	templateUrl: "new-resource-type.component.html",
	styleUrls: ["new-resource-type.css"]
})
export class NewResourceTypeModalComponent {
	@Output() cancelCallback = new EventEmitter<void>();
	@Output() saveCallback = new EventEmitter<ResourceType>();

	text: () => LocaleMap;
	newName: string = "";
	resourceTypes: Array<ResourceType>;
	conf: ConfigurationService;

	constructor(conf: ConfigurationService, textProv: TextProvider) {
		this.text = textProv.getStringMap;
		this.conf = conf;
		this.resourceTypes = conf.configuration.agentTypes;
	}

	public readonly refreshNewName: Function = (name: string) => {
		this.newName = name;
	};

	public readonly delete: Function = (type: ResourceType) => {
		this.conf.deleteResourceType(type).then((ans: boolean) => {
			if (ans) {
				const idx = this.resourceTypes.findIndex((e) => e.id === type.id);
				if (idx > -1) this.resourceTypes.splice(idx, 1);
			}
		});
	};

	public readonly save: Function = () => {
		const type = new ResourceType(-1, this.newName);
		this.conf.saveResourceType(type).then((ans: ResourceType) => {
			if (ans && ans.id > -1) {
				this.saveCallback.emit(ans);
				this.cancelCallback.emit();
			}
		});
	};
}
