import { Injectable } from "@angular/core";
import moment from "moment";
import { Subject } from "rxjs";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import { UserService } from "src/app/settings/user/user.service";
import { Ara } from "src/app/dto/ara/ara";
import { ControlMeasure } from "src/app/dto/ara/control-measure";
import { CM_DONE_STATUS, ControlMeasureRelation } from "src/app/dto/ara/control-measure-relation";
import { RiskInformation } from "src/app/dto/ara/risk-information";
import { RiskInformationRelation } from "src/app/dto/ara/risk-information-relation";
import { RiskType } from "src/app/dto/ara/risk-type";
import { RiskTypeRelation } from "src/app/dto/ara/risk-type-relation";
import { Scenario } from "src/app/dto/ara/scenario";
import { CSCommander } from "src/app/dto/command-structure/cs-commander";
import { CSSector } from "src/app/dto/command-structure/cs-sector";
import { CloneFactory } from "src/app/dto/net/clone-factory";
import { DTOArray } from "src/app/dto/net/dto-array";
import { UploadedFile } from "src/app/dto/net/uploaded-file";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { MainService } from "src/app/global/main.service";
import { CommandStructureService } from "../command-structure/command-structure.service";
import { LoginService } from "src/app/login/login.service";
import { EventsMissionsService } from "../../event-mission.service";
import { ARA_SCREENS } from "./components/screen/ara.component";
import { ScenarioRelation } from "src/app/dto/ara/scenario-relation";

@Injectable({
	providedIn: "root"
})
export class AraService {
	public ARAs: Array<Ara> = [];
	public Risks: Array<RiskType> = [];
	public riskRelations: Array<RiskTypeRelation> = [];
	public sectors: Array<CSSector> = new Array<CSSector>();
	public Scenarios: Array<Scenario> = [];
	public RiskInformations: Array<RiskInformation> = [];
	public ControlMeasureTypes: Array<ControlMeasure> = [];

	public incidentCommanders: Array<CSCommander> = [];
	public controlMeasureRelations: Array<ControlMeasureRelation> = [];
	public riskInformationRelations: Array<RiskInformationRelation> = [];

	public arasWithNotification: Array<number> = [];
	public currentAraScreen: ARA_SCREENS = ARA_SCREENS.HOME;
	public currentSector: CSSector = new CSSector(-1, -1, -1, "");
	public readonly araDrafts = new Map<number, Ara>();
	public readonly araDraftFixedScenarios = new Map<number, Array<Scenario>>();
	public readonly skipUpdate: number[] = [];

	// Events
	public readonly sectorsLoaded$ = new Subject<void>();
	public readonly RILoaded$ = new Subject<void>();
	public readonly CMLoaded$ = new Subject<CONTROL_MEASURE_UPDATES>();
	public readonly araNotification$ = new Subject<Ara>();


	// tags are to be used with the language object from the constants factory to be translated to the user's language on the fly
	public readonly likelihoodOptions = [
		{ tag: "UNLIKELY", rating: 1 },
		{ tag: "REMOTE", rating: 2 },
		{ tag: "REASONABLY", rating: 3 },
		{ tag: "PROBABLE", rating: 4 },
		{ tag: "HIGHLY_LIKELY", rating: 5 }
	];

	public readonly severityOptions = [
		{ tag: "MARGINAL", rating: 1 },
		{ tag: "SERIOUS", rating: 2 },
		{ tag: "VERY_SERIOUS", rating: 3 },
		{ tag: "CRITICAL", rating: 4 },
		{ tag: "CATASTROPHIC", rating: 5 }
	];

	public readonly tacticalOptions = ["OFFENSIVE", "DEFENSIVE", "TRANSITIONAL"];

	private readonly main: MainService;
	private readonly wreq: WebRequestFactory;
	private readonly cs: CommandStructureService;
	private readonly user: UserService;
	private readonly loginService: LoginService;
	private readonly text: () => LocaleMap;
	private readonly ems: EventsMissionsService;
	private staticDataLoadCalled: boolean = false;
	private incidentDataLoadCalledFor: number | undefined;

	constructor(main: MainService, wreq: WebRequestFactory, cs: CommandStructureService, user: UserService, textProv: TextProvider, login: LoginService, ems: EventsMissionsService) {
		this.main = main;
		this.wreq = wreq;
		this.cs = cs;
		this.user = user;
		this.loginService = login;
		this.ems = ems;
		this.text = textProv.getStringMap;

		this.ems.loadMission$.subscribe(() => this.createNewARADraft());
	}

	public readonly load: Function = () => {
		return this.loadStaticData(this.ems.getCurrentMission()!.id);
	};

	// force flag is used for ara updates
	// TODO: create endpoint so ARA updates don't force a full reload
	public readonly loadStaticData = async (id_mission: number, force?: boolean): Promise<void> => {
		if ((this.staticDataLoadCalled && !force) || !id_mission || id_mission === -1) return;
		this.staticDataLoadCalled = true;
		const scenarioJson = await this.wreq.getScenario(-1);
		if (scenarioJson && scenarioJson.length > 0) DTOArray.UpdateFromJsonArray(this.Scenarios, scenarioJson, Scenario).sort((a: Scenario, b: Scenario) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0));
		const riJson = await this.wreq.getRiskInformation(-1);
		if (riJson && riJson.length > 0) DTOArray.UpdateFromJsonArray(this.RiskInformations, riJson, RiskInformation);
		const cmtJson = await this.wreq.getControlMeasureType(-1);
		if (cmtJson && cmtJson.length > 0) DTOArray.UpdateFromJsonArray(this.ControlMeasureTypes, cmtJson, ControlMeasure);
		

		
		this.setupStaticObjects();
		return;
	};

	public readonly loadMissionData = async (id_mission: number, force?: boolean): Promise<void> => {
		const missionId = id_mission ? id_mission : this.main.getCurrentMission() ? this.main.getCurrentMission()!.id : -1;

		if (id_mission === this.incidentDataLoadCalledFor && !force) return;
		this.incidentDataLoadCalledFor = id_mission;
		if (!this.staticDataLoadCalled) await this.loadStaticData(missionId);

		
		const risksJson = await this.wreq.getRiskTypes(id_mission);
		if (risksJson && risksJson.length > 0) DTOArray.UpdateFromJsonArray(this.Risks, risksJson, RiskType);

		const riskTypRelJson = await this.wreq.getRiskRelations(missionId);
		if (riskTypRelJson && riskTypRelJson.length > 0) DTOArray.UpdateFromJsonArray(this.riskRelations, riskTypRelJson, RiskTypeRelation);
		else this.riskRelations = [];


		// TODO: with the new ara endpoint there shouldn't be a need to download the relations separatedly, but we need them to regenerate deleted data on the details popup (if we delete a control measure on a review)

		const riRelJson = await this.wreq.getRiskInformationRelations(missionId);
		if (riRelJson && riRelJson.length > 0) DTOArray.UpdateFromJsonArray(this.riskInformationRelations, riRelJson, RiskInformationRelation);
		else this.riskInformationRelations = [];

		const controlMeasJson = await this.wreq.getControlMeasureRelations(missionId);
		if (controlMeasJson && controlMeasJson.length > 0) DTOArray.UpdateFromJsonArray(this.controlMeasureRelations, controlMeasJson, ControlMeasureRelation);
		else this.controlMeasureRelations = [];


		const araJson = await this.wreq.getAllMissionFullARAs(missionId);
		if (araJson && araJson.length > 0) {
			DTOArray.UpdateFromJsonArray(this.ARAs, araJson, Ara);
			this.setupMissionObjects();
		} else this.ARAs = [];

		this.setupSectors(missionId);
		const commandersJson = await this.wreq.getAllCommanders(missionId);
		if (commandersJson && commandersJson.length > 0) DTOArray.UpdateFromJsonArray(this.incidentCommanders, commandersJson, CSCommander);
		else this.incidentCommanders = [];
		this.sectorsLoaded$.next();
	};

	public readonly checkAraUpdate = async (id_ara: number, id_mission: number): Promise<void> => {
		const currentMissionId = this.main.getCurrentMission()?.id;
		if (!currentMissionId || currentMissionId === -1 || !id_mission || id_mission === -1 || (id_mission !== -1 && id_mission !== currentMissionId)) return;
		if (id_ara && id_ara !== -1) {
			if (this.skipUpdate.includes(id_ara)) this.skipUpdate.splice(this.skipUpdate.indexOf(id_ara), 1);
			else {
				await this.loadStaticData(currentMissionId);
				await this.loadMissionData(currentMissionId, true);
				const newARA = this.ARAs.find((ara) => ara.id_ara === id_ara);
				if (newARA) {
					this.araNotification$.next(newARA);
					if (newARA.id_fill_user !== this.loginService.user.id) this.arasWithNotification.push(newARA.id_ara);
				}
			}
		}
	};

	public readonly unload: Function = () => {
		this.ARAs.length = 0;
		this.Risks.length = 0;
		this.riskRelations.length = 0;
	};

	public readonly saveScenario: Function = async (scenario: Scenario) => {
		const reply = await this.wreq.saveScenario(scenario);
		if (reply) {
			const newScenario = Scenario.fromJson(reply);
			CloneFactory.cloneProperties(scenario, newScenario);
			const idx = this.Scenarios.findIndex((e) => e.id === newScenario.id);
			if (idx === -1) this.Scenarios.unshift(newScenario);
			else CloneFactory.cloneProperties(this.Scenarios[idx], scenario);
			return newScenario;
		}
		return undefined;
	};

	public readonly saveRiskInformation: Function = async (ri: RiskInformation) => {
		const reply = await this.wreq.saveRiskInformation(ri);
		if (reply) {
			const newRisk = RiskInformation.fromJson(reply);
			CloneFactory.cloneProperties(ri, newRisk);
			let scenario = this.Scenarios.find((e) => e.id_scenario == ri.id_scenario);
			if (scenario) {
				if (scenario.riskInformations && !scenario.riskInformations.find((e) => e.id === ri.id)) scenario.riskInformations.unshift(ri);
				else scenario.riskInformations = [ri];
			}
			const idx = this.RiskInformations.findIndex((e) => e.id === ri.id);
			if (idx === -1) this.RiskInformations.push(ri);
			else CloneFactory.cloneProperties(this.RiskInformations[idx], ri);
			return ri;
		}
		return undefined;
	};

	public readonly saveControlMeasureType: Function = async (cm: ControlMeasure) => {
		const reply = await this.wreq.saveControlMeasureType(cm);
		if (reply) {
			const newCM = ControlMeasure.fromJson(reply);
			CloneFactory.cloneProperties(cm, newCM);
			const ri = this.RiskInformations.find((e) => e.id === cm.id_risk_information);
			ri && ri.controlMeasures && !ri.controlMeasures.find((e) => e.id_cm === newCM.id_cm) && ri.controlMeasures.push(cm);
			const idx = this.ControlMeasureTypes.findIndex((e) => e.id === cm.id);
			if (idx === -1) this.ControlMeasureTypes.unshift(cm);
			else CloneFactory.cloneProperties(this.ControlMeasureTypes[idx], cm);
			return cm;
		}
		return undefined;
	};

	public readonly saveRisk: Function = async (risk: RiskType) => {
		const reply = await this.wreq.saveRisk(risk);
		if (reply) {
			let newRisk = RiskType.fromJson(reply);
			this.Risks.unshift(newRisk);
			CloneFactory.cloneProperties(risk, newRisk);
			return newRisk;
		}
		return undefined;
	};

	public readonly deleteRiskType: Function = async (risk_type: RiskType) => {
		const reply = await this.wreq.deleteRiskType(risk_type);
		if (reply) {
			const idx = this.Risks.findIndex((e) => e.id === risk_type.id);
			if (idx > -1) this.Risks.splice(idx, 1);
			return true;
		}
		return false;
	};

	public readonly deleteControlMeasureType: Function = async (cm: ControlMeasureRelation) => {
		const reply = await this.wreq.deleteControlMeasureType(cm.__object.id);
		if (reply) {
			this.RiskInformations.forEach((ri) => {
				if (ri.controlMeasures && ri.controlMeasures.length > 0) {
					const idx = this.ControlMeasureTypes.findIndex((e) => e.id_cm == cm.id_cm && e.id_risk_information === ri.id_risk_information);
					if (idx > -1) this.ControlMeasureTypes[idx].deleted = true;
				}
			});
			const idx = this.ControlMeasureTypes.findIndex((e) => e.id_cm === cm.id_cm);
			if (idx > -1) this.ControlMeasureTypes[idx].deleted = true;
		}
		return reply;
	};

	public readonly deleteRiskInformation: Function = async (ri: RiskInformation) => {
		const reply = await this.wreq.deleteRiskInformation(ri.id_risk_information);
		if (reply) {
			const idx = this.RiskInformations.findIndex((e) => e.id === ri.id_risk_information);
			if (idx > -1) this.RiskInformations[idx].deleted = true;
			return true;
		}
		return false;
	};

	public async saveNewARA(ara: Ara): Promise<boolean> {
		const res = await this.wreq.saveFullARA(ara);
		if (res) {
			const newAra = Ara.fromJson(res);
			this.skipUpdate.push(newAra.id);
			ara.id = ara.id_ara = newAra.id;
			this.setupNewARA(ara);
			// we're gonna add the new relations without needing the actual id for them, since all that matters is ara id and type id, which we can get
			// no need to update the references of the ara object, as ara objects are inmutable by design
			ara.scenarios.forEach((sce) => {
				sce.riskInformations.forEach((ri) => {
					const _ri = new RiskInformationRelation(-1, ara.id, ri.id_risk_information); 
					_ri.__object = ri.__object;
					this.riskInformationRelations.push(_ri);
					ri.controlMeasures.forEach((cm) => {
						const _cm = new ControlMeasureRelation(-1, ara.id, cm.id_cm, cm.done, cm.level, cm.comments);
						_cm.__object = _cm.__object
						this.controlMeasureRelations.push(_cm);
					})
				})
			})

			ara.atRisk.forEach((risk) => {
				 let _risk = new RiskTypeRelation(-1, risk.id_risktype, ara.id, risk.id_mission);
				 _risk.__object = risk.__object;
				 this.riskRelations.push(_risk);
			})
			
			setTimeout(() => this.araNotification$.next(ara));
			return true;
		}
		return false;
	}

	public readonly saveARA = async (ara: Ara): Promise<Ara | undefined> => {
		this.setupARA(ara);
		const res = await this.wreq.saveARA(ara);
		if (!res) return undefined;
		const newAra = Ara.fromJson(res);
		this.setupARA(newAra);
		CloneFactory.cloneProperties(ara, newAra);
		if (!this.ARAs.find((e) => e.id === newAra.id)) this.ARAs.push(newAra);
		return newAra;
	};

	public readonly setupStaticObjects: Function = () => {
		this.Scenarios.forEach((scenario) => {
			scenario.riskInformations = this.RiskInformations.filter((e) => e.id_scenario === scenario.id);
		});

		this.RiskInformations.forEach((ri) => {
			ri.controlMeasures = this.ControlMeasureTypes.filter((e) => e.id_risk_information === ri.id_risk_information);
		});
	};

	public readonly setupMissionObjects: Function = () => {
		this.controlMeasureRelations.forEach((cm) => (cm.__object = this.ControlMeasureTypes.find((e) => e.id_cm === cm.id_cm)!));
		this.riskInformationRelations.forEach((ri) => {
			ri.__object = this.RiskInformations.find((e) => e.id_risk_information == ri.id_risk_information);
			ri.controlMeasures = this.controlMeasureRelations.filter((e) => e.__object && e.__object.id_risk_information === ri.id_risk_information && e.id_ara === ri.id_ara);
		});
		this.ARAs.forEach(this.setupARA as (value: Ara, index: number, array: Ara[]) => void);
	};

	public setupARAArray(array: Array<Ara>): void {
		this.controlMeasureRelations.forEach((cm) => (cm.__object = this.ControlMeasureTypes.find((e) => e.id_cm === cm.id_cm)!));
		this.riskInformationRelations.forEach((ri) => {
			ri.__object = this.RiskInformations.find((e) => e.id_risk_information == ri.id_risk_information);
			ri.controlMeasures = this.controlMeasureRelations.filter((e) => e.__object && e.__object.id_risk_information === ri.id_risk_information && e.id_ara === ri.id_ara);
		});
		array.forEach(this.setupARA as (value: Ara, index: number, array: Ara[]) => void);
	}

	public readonly setupARA: Function = (ARA: Ara) => {
		ARA.__sector = ARA.id_ics_sector !== -3 ? this.cs.commandStructures.getSector(ARA.id_ics_sector) : new CSSector(-3, -1, -1, this.text().WHOLE_INCIDENT);
		ARA.__scenario = this.Scenarios.find((e) => e.id_scenario === ARA.id_scenario);
		ARA.__fill_user = this.user.Users.find((e) => e.id === ARA.id_fill_user);
		ARA.__sign_user = this.user.Users.find((e) => e.id === ARA.id_sign_user);
		ARA.__timestamp_string = moment(ARA.timestamp_ms).format("DD/MM/YYYY HH:mm");
		ARA.__likelihoodOption = this.likelihoodOptions.find((e) => e.rating === ARA.getSL().likelihood);
		ARA.__severityOption = this.severityOptions.find((e) => e.rating === ARA.getSL().severity);
		ARA.atRisk = this.riskRelations.filter((e) => e.id_ARA === ARA.id).map((risk) => {
			risk.__object = this.Risks.find((e) => e.id_risk == risk.id_risktype);
			return risk;
		 })

		if(!ARA.scenarios.length) ARA.scenarios = [new ScenarioRelation(-1, ARA.id, ARA.id_scenario)]
		ARA.scenarios.forEach((scenarioRelation) => {
			scenarioRelation.__object = this.Scenarios.find((e) => e.id_scenario === scenarioRelation.id_scenario);
			scenarioRelation.riskInformations.forEach((risk) => {
				risk.__object = this.RiskInformations.find((e) => e.id_risk_information == risk.id_risk_information)!;
				risk.controlMeasures.forEach((cm) => {
					cm.__object = this.ControlMeasureTypes.find((e) => e.id_cm === cm.id_cm)!;
				})
			});
		});
	};

	public readonly getScenario: Function = async (id: number) => {
		if (!id) id = -1;
		const jsonArray = await this.wreq.getScenario(id);
		if (jsonArray) {
			if (id === -1) {
				DTOArray.UpdateFromJsonArray(this.Scenarios, jsonArray, Scenario);
			} else {
				let scenario = Scenario.fromJson(jsonArray[0]);
				let idx = this.Scenarios.findIndex((e) => e.id_scenario === scenario.id_scenario);
				if (idx === -1) this.Scenarios.push(scenario);
				else this.Scenarios.splice(idx, 1, scenario);
			}
		}
	};
	public readonly getRiskInfo: Function = async (id: number) => {
		if (!id) id = -1;
		const jsonArray = await this.wreq.getRiskInformation(id);
		if (jsonArray) {
			if (id === -1) DTOArray.UpdateFromJsonArray(this.RiskInformations, jsonArray, RiskInformation);
			else {
				const ri = RiskInformation.fromJson(jsonArray[0]);
				const idx = this.RiskInformations.findIndex((e) => e.id_risk_information === ri.id_risk_information);
				if (idx === -1) this.RiskInformations.push(ri);
				else this.RiskInformations.splice(idx, 1, ri);
				this.addRisksToScenarios();
			}
			this.RILoaded$.next();
		}
	};

	public readonly getControlMeasureType = async (id: number, update?: CONTROL_MEASURE_UPDATES): Promise<void> => {
		id = id ?? -1;
		const jsonArray = await this.wreq.getControlMeasureType(id);
		if (jsonArray) {
			if (id === -1) {
				DTOArray.UpdateFromJsonArray(this.ControlMeasureTypes, jsonArray, ControlMeasure);
			} else {
				const cm = ControlMeasure.fromJson(jsonArray[0]);
				const idx = this.ControlMeasureTypes.findIndex((e) => e.id_cm === cm.id_cm);
				if (idx === -1) this.ControlMeasureTypes.push(cm);
				else {
					this.ControlMeasureTypes[idx].info = cm.info;
					this.ControlMeasureTypes[idx].deleted = cm.deleted;
				}
				this.addControlMeasuresToRisks();
			}
			if (update !== undefined) {
				switch (update) {
					case CONTROL_MEASURE_UPDATES.CONTROL_MEASURE_ADD:
						this.CMLoaded$.next(CONTROL_MEASURE_UPDATES.CONTROL_MEASURE_ADD);
						break;
					case CONTROL_MEASURE_UPDATES.CONTROL_MEASURE_DELETE:
						this.CMLoaded$.next(CONTROL_MEASURE_UPDATES.CONTROL_MEASURE_DELETE);
						break;
				}
			}
		}
	};

	public readonly uploadFile = (image: File): Promise<UploadedFile> => {
		return this.wreq.uploadFile(image.name, image);
	};

	public readonly getFileUrl: (id: number) => Promise<string> = async (id) => {
		const blob = await this.wreq.getFile(id);
		let url = "";
		blob ? (url = URL.createObjectURL(blob)) : (url = "resources/img/document.svg");
		return url;
	};

	public readonly loadAraSignature: (ara: Ara) => Promise<void> = (ara) => {
		if (ara.id_uploaded_file_signature && ara.id_uploaded_file_signature > 0 && !ara.signImg) {
			return this.wreq.getFile(ara.id_uploaded_file_signature).then((blob: Blob | MediaSource) => {
				if (blob) {
					ara.signImg = URL.createObjectURL(blob);
				}
				return;
			});
		}
	};

	public removeNotification(araId: number): void {
		this.arasWithNotification.splice(this.arasWithNotification.indexOf(araId), 1);
	}

	public cloneARA(clone: Ara, CloneFrom: Ara): Ara {
		CloneFactory.cloneProperties(clone, CloneFrom);
		clone.scenarios = CloneFrom.scenarios.map((scenario) => {
			let newSce = new ScenarioRelation(scenario.id, scenario.id_ara, scenario.id_scenario);
			newSce.riskInformations = scenario.riskInformations.map((ri) => {
				let risk = new RiskInformationRelation(ri.id, ri.id_ara, ri.id_risk_information, [], ri.selected, ri.preselected);
				risk.severity = ri.severity;
				risk.likelihood = ri.likelihood;
				risk.controlMeasures = ri.controlMeasures.map((cm) => {
					let contr = new ControlMeasureRelation(cm.id, cm.id_ara, cm.id_cm, cm.done, cm.level, cm.comments);
					contr.__object = cm.__object;
					return contr;
				})
				risk.__object = ri.__object
				return risk;
			})
			newSce.__object = scenario.__object
			return newSce;
		})
		clone.atRisk = [...CloneFrom.atRisk];
		return clone;
	}

	public resetCurrentARA(sectorId: number, reviewARA: boolean = false): void {
		sectorId !== -1 && this.araDrafts.delete(sectorId);
		if (!reviewARA) {
			const newARA: Ara = new Ara(-1, -1, -1, -1, -1, 0, 0);
			newARA.__scenario = new Scenario(-1, "");
			this.araDrafts.set(-1, newARA);
		}
	}

	public updateCMTimestamps(controlMeasure: ControlMeasureRelation): number | undefined {
		if (controlMeasure.done === null || controlMeasure.done === CM_DONE_STATUS.UNSET || controlMeasure.id_ara === -1) return;
		const sector = this.sectors.find((sector) => sector.aras.some((ara) => ara.id === controlMeasure.id_ara));
		if (!sector) return;
		const aras = [...sector.aras];
		if (!aras || !aras.length) return;
		const oldToNewARAs = aras.sort((a, b) => a.id - b.id);
		oldToNewARAs.forEach((ara) => {
			if (ara.id <= controlMeasure.id_ara) {
				ara.scenarios.forEach((scenario) => {
					scenario.riskInformations.forEach((ri) => {
						ri.controlMeasures.forEach((cmRel) => {
							if (cmRel.id_cm === controlMeasure.id_cm) {
								if (cmRel.done === CM_DONE_STATUS.UNSET || cmRel.done !== controlMeasure.done) controlMeasure.timestamp_ms = 0;
								else if ((!controlMeasure.timestamp_ms && cmRel.done === controlMeasure.done) || ara.is_new) controlMeasure.timestamp_ms = ara.timestamp_ms;
							}
						});
					});
				})
				
			}
		});
		return controlMeasure.timestamp_ms;
	}

	public hasARAbeenModified(sectorId: number): boolean {
		const draft = this.araDrafts.get(sectorId);
		if (draft) {
			let ara: Ara;
			const newARA = new Ara(-1, -1, -1, -1, -1, 0, 0);
			newARA.__scenario = new Scenario(-1, "");
			const reviewARA = this.sectors.find((sector: CSSector) => sector.id_sector === sectorId)?.aras[0];
			if (sectorId === -1) {
				ara = newARA;
				if (draft.atRisk.length) return true;
			} else if (reviewARA) {
				ara = reviewARA;
				if (!this.areScenariosEqual(draft.scenarios, ara.scenarios)) return true;
				if (!this.areRiskTypesEqual(draft.atRisk, ara.atRisk)) return true;
			} else return false;

			if (
				draft.id_ics_sector !== ara.id_ics_sector ||
				draft.environment_details !== ara.environment_details ||
				draft.tactics !== ara.tactics ||
				draft.id_scenario !== ara.id_scenario ||
				draft.getSL().severity !== ara.getSL().severity ||
				draft.getSL().likelihood !== ara.getSL().likelihood ||
				draft.id_sign_user !== ara.id_sign_user
			)
				return true;
			return false;
		}
		return false;
	}

	private areScenariosEqual(scenario1: ScenarioRelation[], scenario2: ScenarioRelation[]): boolean {
		const riskInformations1 = scenario1.flatMap((scenario) => scenario.riskInformations);
		const riskInformations2 = scenario2.flatMap((scenario) => scenario.riskInformations);
		const areScenariosEqual =
			riskInformations1.length === riskInformations2.length &&
			scenario1.every((scenario) => {
				return !!scenario2.find((e) => e.id === scenario.id);
			}) && 
			this.areRiskInformationsEqual(riskInformations1, riskInformations2);
		return areScenariosEqual;
	}

	private areRiskInformationsEqual(riskRelation1: RiskInformationRelation[], riskRelation2: RiskInformationRelation[]): boolean {
		const controlMeasures1 = riskRelation1.flatMap((riskRel) => riskRel.controlMeasures);
		const controlMeasures2 = riskRelation2.flatMap((riskRel) => riskRel.controlMeasures);
		const areCMsEqual =
			controlMeasures1.length === controlMeasures2.length &&
			controlMeasures1.every((cm1) => {
				const cm2 = controlMeasures2.find((cm2) => cm2.id === cm1.id);
				return (cm2 && cm1.done === cm2.done) || cm1.comments === cm2?.comments;
			});
		if (!areCMsEqual) return false;
		return (
			riskRelation1.length === riskRelation2.length &&
			riskRelation1.every((riskRel1) => {
				return riskRelation2.some((riskRel2) => riskRel1.id_risk_information === riskRel2.id_risk_information);
			})
		);
	}

	private areRiskTypesEqual(riskRelation1: RiskTypeRelation[], riskRelation2: RiskTypeRelation[]): boolean {
		return (
			riskRelation1.length === riskRelation2.length &&
			riskRelation1.every((riskType1) => {
				return riskRelation2.some((riskType2) => riskType1.id === riskType2.id);
			})
		);
	}

	private setupNewARA(ara: Ara): void {
		ara.__fill_user = this.user.Users.find((e) => e.id === ara.id_fill_user);
		ara.__sign_user = this.user.Users.find((e) => e.id === ara.id_sign_user);
		ara.__timestamp_string = moment(ara.timestamp_ms).format("DD/MM/YY HH:mm");
		this.updateRelations(ara);
		!this.ARAs.find((e) => e.id === ara.id) && this.ARAs.push(ara);
		this.setupSectors(this.ems.getCurrentMission()!.id);
	}

	private updateRelations(ara: Ara): void {
		ara.scenarios = ara.scenarios.map((scenario) => {
			scenario.id_ara = ara.id_ara;
			scenario.riskInformations = scenario.riskInformations.map((riskInfo) => {
				riskInfo.id_ara = ara.id_ara;
				riskInfo.controlMeasures.map((CMRel) => (CMRel.id_ara = ara.id_ara));
				return riskInfo;
			});
			return scenario;
		})
	}

	private createNewARADraft(): void {
		this.currentAraScreen = ARA_SCREENS.HOME;
		this.currentSector = new CSSector(-1, -1, -1, "");
		this.araDrafts.clear();
		const newARA = new Ara(-1, -1, -1, -1, -1, 0, 0);
		newARA.__scenario = new Scenario(-1, "");
		this.araDrafts.set(-1, newARA);
	}

	private readonly addRisksToScenarios: Function = () => {
		this.Scenarios.forEach((scenario) => {
			scenario.riskInformations = this.RiskInformations.filter((e) => e.id_scenario == scenario.id_scenario);
		});
	};

	private readonly addControlMeasuresToRisks: Function = () => {
		this.RiskInformations.forEach((ri) => {
			ri.controlMeasures = this.ControlMeasureTypes.map((e) => {
				let cm = new ControlMeasure(e.id, e.info, ri.id_risk_information, e.deleted, false, e.scm_edit);
				return cm;
		});
		});
	};

	private readonly setupSectors = (missionId: number): void => {
		const wholeIncSector = new CSSector(-3, -1, -1, this.text().WHOLE_INCIDENT);
		this.sectors = this.cs.commandStructures.getAllSectors();
		this.sectors.unshift(wholeIncSector);
		this.sectors.forEach((sector: CSSector) => {
			sector.aras = this.ARAs.filter((ara: Ara) => (ara.id_ics_sector === sector.id_sector || (ara.id_ics_sector === -1 && sector.id_sector === -3)) && ara.id_mission === missionId).sort((a, b) => b.timestamp_ms - a.timestamp_ms);
		});
	};
}

export enum SEVERITY_VALUE {
	MARGINAL = 1,
	SERIOUS = 2,
	VERY_SERIOUS = 3,
	CRITICAL = 4,
	CATASTROPHIC = 5
}

export enum LIKELIHOOD_VALUE {
	UNLIKELY = 1,
	REMOTE = 2,
	REASONABLY = 3,
	PROBABLE = 4,
	HIGHLY_LIKELY = 5
}

export enum CONTROL_MEASURE_UPDATES {
	CONTROL_MEASURE_ADD,
	CONTROL_MEASURE_DELETE,
	SUB_CONTROL_MEASURE_ADD,
	SUB_CONTROL_MEASURE_DELETE
}

export interface SEV_LIKE_VALUES {
	tag: string;
	rating: number;
}

export interface LIKELIHOOD_LIKE_VALUES {
	tag: string;
	rating: number;
}

export interface TACTICAL_LIKE_VALUES {
	tag: string;
}
