import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren, ɵdetectChanges } from "@angular/core";
import { Event } from "src/app/dto/items/event";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import * as html2canvas from "html2canvas";
import { DocumentService } from "src/app/document/document.service";
import { DocumentTemplate } from "../../template.interface";
import { FsgFlat } from "src/app/dto/fsg/fsg-flat";
import { FsgBuilding } from "src/app/dto/fsg/fsg-building";
import { PAGE_SIZES } from "src/app/dto/Documents/page-sizes";

@Component({
	selector: "app-fsg-blank-template",
	templateUrl: "fsg-blank.component.html",
	styleUrls: ["fsg-blank.css", "../fsg.css", "../../../document.css"]
})
export class FsgBlankTemplateComponent implements OnInit, DocumentTemplate, AfterViewInit {
	@Input() generationTime!: number;
	@Input() event!: Event;
	@Input() fsg!: FsgBuilding;

	@Output() docReady = new EventEmitter<HTMLCanvasElement[]>();

	@ViewChildren("pageElements") pageElements!: QueryList<ElementRef>;
	@ViewChild("inspectedHeaderElement") inspectedHeaderElement!: ElementRef;

	public inspectedHeader: Event | undefined;

	public numberOfPages: number = 1;
	public pages = new Array<FsgFlat[]>();
	public readonly pageSize = PAGE_SIZES.FSG;

	public readonly text: () => LocaleMap;

	private readonly docService: DocumentService;
	private abortDownload = false;

	constructor(tp: TextProvider, doc: DocumentService) {
		this.text = tp.getStringMap;
		this.docService = doc;
	}

	ngOnInit(): void {
		if (!this.event || !this.fsg || !this.fsg.__flats) this.docService.downloadError$.next();
		this.setPages();
		this.docService.onDownloadCancel$.subscribe(() => this.abortDownload = true);
	}

	async ngAfterViewInit(): Promise<void> {
		try {
			const pages = this.pageElements["_results"];
			const output = new Array<HTMLCanvasElement>();
			for (let i = 0; i < pages.length; i++) {
				this.docService.setLoadingMessage(this.text().GENERATING_DOCUMENTATION_PAGE(i, pages.length));
				const canvas = await html2canvas.default(this.pageElements["_results"][i].nativeElement);
				if(this.abortDownload) return;
				output.push(canvas);
			}
			this.docReady.emit(output);
		} catch (error) {
			if(this.abortDownload) return;
			console.error(error);
			this.docService.downloadError$.next();
		}
	}

	public readonly setPages: Function = () => {
		this.calculatePageHeaderHeight(this.event);
		const headerHeight = this.calculatePageHeaderHeight(this.event);
		const bottomMargin = 75;
		for (let i = 0; i < this.fsg.__flats!.length; ) {
			let newPage = new Array<FsgFlat>();
			let y = headerHeight;
			while (y < this.pageSize.height - bottomMargin && this.fsg.__flats![i]) {
				newPage.push(this.fsg.__flats![i]);
				y += this.calculateFlatHeight(this.fsg.__flats![i]);
				i++;
			}
			this.pages.push(newPage);
		}
	};

	public readonly getFloorInFire: () => FsgFlat | undefined = () => {
		return this.fsg.__flats!.find((e) => e.condition.find((d) => d.toLowerCase() === "fire"));
	};

	public readonly isFlatOnFire: (flat: FsgFlat) => boolean = (flat) => {
		return !!flat.condition.find((e) => e.toLowerCase() === "fire");
	};

	// builds a mock header to calculate its height
	private readonly calculatePageHeaderHeight: (event: Event) => number = (event) => {
		this.inspectedHeader = event;
		ɵdetectChanges(this);
		const output = this.inspectedHeaderElement!.nativeElement.getBoundingClientRect().height;
		this.inspectedHeader = undefined;
		return output;
	};

	private readonly calculateFlatHeight: (flat: FsgFlat) => number = (flat) => {
		return 44;
	};
}
