import { Area } from "./area";
import { WGSPoint } from "../map/location";
import { DTO } from "../net/dto";
import { SHAPE_TYPE } from "./shape-type";

export class CircleArea extends Area implements DTO {
	shapeType: string = SHAPE_TYPE.CIRCLE;
	center: WGSPoint;
	radius: number;

	timestamp = Date.now();
	public constructor(id_mission: number, id: number, type: number, latitude: number, longitude: number, radius: number, name: string, description?: string, deleted?: boolean, attachment_id?: number, attachment_filename?: string, timestamp?: number) {
		super(id_mission, id, type, name, SHAPE_TYPE.CIRCLE, description ? description : "", deleted ? deleted : false, attachment_id ? attachment_id : -1, attachment_filename ? attachment_filename : "", true, timestamp ? timestamp : Date.now());
		let center: WGSPoint = new WGSPoint(latitude, longitude, null);
		this.center = (center as any).lat ? new WGSPoint((center as any).lat, (center as any).lng) : center;
		this.radius = radius;
	}

	public static readonly fromJson: (json: string) => CircleArea = (json: string) => {
		let ja: any = JSON.parse(json);
		return new CircleArea(ja.id_mission ? ja.id_mission : ja.mission_id, ja.id, ja.type, ja.center.lat, ja.center.lng, ja.radius, ja.name, ja.description, ja.deleted, ja.attachment_id, ja.attachment_filename, ja.timestamp);
	};

	public readonly getJson: () => string = () => {
		// @ts-ignore
		this.center.lat = this.center.latitude;
		// @ts-ignore
		this.center.lng = this.center.longitude;
		// @ts-ignore
		this.mission_id = this.id_mission;
		return JSON.stringify(this, ["mission_id", "id", "type", "center", "radius", "name", "description", "shapeType", "lat", "lng", "alt", "deleted", "attachment_filename", "attachment_id", "timestamp"]);
	};
}
