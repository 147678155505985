<div #pageElements *ngFor="let page of pages; let i = index" class="page-container">
	<div class="block-layout">
		<section class="document-header">
			<h1>{{ text().CHECKLIST }}</h1>
			<div class="download-date inline-item">
				<b>{{ text().DOCUMENT_DOWNLOADED }}:</b>
				<span>{{ generationTime | date : "HH:mm dd/MM/YY" }}</span>
			</div>
		</section>

		<section class="document-subheader">
			<ul class="subheader-details">
				<li class="inline-item">
					<b>{{ text().TIME }}:</b>
					<span>{{ event.mission?.start_time_ms | date : "HH:mm" }}</span>
				</li>
				<li class="inline-item">
					<b>{{ text().TYPE_OF_INCIDENT }}:</b>
					<span>{{ incidentType }}</span>
				</li>
				<li class="inline-item">
					<b>{{ text().FILLED_BY }}:</b>
					<span>{{ filledBy?.name }}</span>
				</li>
				<li class="inline-item">
					<b>{{ text().DATE }}:</b>
					<span>{{ event.mission?.start_time_ms | date : "dd/MM/YYYY" }}</span>
				</li>
				<li class="inline-item">
					<b>{{ text().INCIDENT_LOCATION }}:</b>
					<span>{{ incidentLocation }}</span>
				</li>
				<li class="inline-item">
					<b>{{ text().COMMANDER }}:</b>
					<span>{{ commander?.name }}</span>
				</li>
			</ul>
		</section>

		<section class="document-body">
			<div class="body-header">
				<div>{{ text().ACTION }}:</div>
				<div>{{ text().TIME_STAMP }}:</div>
			</div>

			<div class="body-list-item" *ngFor="let item of page">
				<article>
					<div>- {{ item.text }}</div>
					<div class="timestamp-group">
						<div *ngFor="let ts of item.timestamps">
							{{ (ts.checked ? text().COMPLETED : text().UNCHECKED) + " " + ts.time }}
						</div>
					</div>
				</article>
				<hr />
			</div>
		</section>

		<section class="document-footer">
			<div class="inline-item">
				<b>{{ text().PAGE }}:</b>
				<span>{{ i + 1 }} / {{ pages.length }}</span>
			</div>
		</section>
	</div>
</div>
