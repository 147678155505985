import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from "@angular/core";
import { Incident } from "src/app/dto/items/incident";
import { ResourceType } from "src/app/dto/items/types/resource-type";
import { Size } from "src/app/dto/map/size";
import { Appliance } from "src/app/dto/resources/appliance";
import { Resource } from "src/app/dto/resources/resource";
import { Station } from "src/app/dto/resources/station";
import { EventsMissionsService } from "src/app/incident/event-mission.service";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { ALERT_TYPE, MainService } from "src/app/global/main.service";
import { ResourceService } from "src/app/settings/resource/resource.service";
import { ConfigurationService } from "src/app/settings/types/configuration.service";
import { Camera } from "src/app/dto/resources/camera";

@Component({
	selector: "app-appliance-card",
	templateUrl: "appliance-card.component.html",
	styleUrls: ["resource-card.css"]
})
export class ApplianceCardComponent implements OnInit {
	@Input() appliance: Appliance = new Appliance(-1, -1, 0, 0, -1, -1, "");

	@Input() canDelete: boolean = false;

	@Output() saveCb = new EventEmitter<Appliance>();

	@Output() deleteCb = new EventEmitter<Appliance>();

	@Output() assignHardwareCb = new EventEmitter<Appliance>();

	@Output() addNewType = new EventEmitter<Resource>();

	@Output() editing = new EventEmitter<boolean>();

	public text: () => LocaleMap;
	public showColorPicker: boolean = false;
	public resourceTypes: Array<ResourceType> = [];
	public incidents: Array<Incident> = [];
	public stations: Array<Station> = [];
	public stationPlaceholder: string = "Station";

	private readonly main: MainService;
	private readonly ems: EventsMissionsService;
	private readonly res: ResourceService;
	private readonly conf: ConfigurationService;
	private readonly cdRef: ChangeDetectorRef;
	constructor(conf: ConfigurationService, main: MainService, res: ResourceService, ems: EventsMissionsService, textProv: TextProvider, cdRef: ChangeDetectorRef) {
		this.text = textProv.getStringMap;
		this.main = main;
		this.ems = ems;
		this.res = res;
		this.conf = conf;
		this.cdRef = cdRef;

		this.conf.resourceTypeDeleted$.subscribe(this.refreshResourceTypes as any);
		this.conf.resourceTypeCreated$.subscribe(this.refreshResourceTypes as any);
	}

	ngOnInit() {
		this.refreshResourceTypes();

		this.incidents.push(new Incident(-1, -1, this.text().NONE));
		this.incidents.push(...this.ems.Missions.filter((incident) => !incident.closed));

		this.stations.push(new Station(-1, this.text().UNSPECIFIED));
		this.stations.push(...this.res.Stations);

		if (!this.appliance.__typeObj) this.appliance.__typeObj = this.resourceTypes.find((e) => e.id === this.appliance.type);

		this.save = () => {
			this.saveCb.emit(this.appliance);
		};

		this.delete = () => {
			this.deleteCb.emit(this.appliance);
		};
	}

	save: Function = () => {};
	delete: Function = () => {};

	public typeDropdownGetMainText: (selected: any) => string = () => {
		const type = this.resourceTypes.find((e) => e.id === this.appliance.type);
		return type ? type.name : "";
	};
	public incidentDropdownGetMainText: (selected: any) => string = () => {
		const incident = this.ems.Missions.find((e) => e.id === this.appliance.id_mission);
		return incident ? incident.name : "";
	};
	public stationDropdownGetMainText: (selected: any) => string = () => {
		const station = this.stations.find((e) => e.name === this.appliance.station);
		return station ? station.name : "";
	};

	public typeDropdownGetOptionText: (option: any) => string = (type: ResourceType) => {
		return type.name;
	};
	public incidentDropdownGetOptionText: (option: any) => string = (incident: Incident) => {
		return incident.name;
	};
	public stationDropdownGetOptionText: (option: any) => string = (station: Station) => {
		return station.name;
	};

	public typeDropdownCompareSelect: (selected: any, option: any) => boolean = (obj: Resource, type: ResourceType) => {
		return obj.type === type.id;
	};
	public incidentDropdownCompareSelect: (selected: any, option: any) => boolean = (obj: Resource, incident: Incident) => {
		return obj.id_mission === incident.id;
	};
	public stationDropdownCompareSelect: (selected: any, option: any) => boolean = (obj: Resource, station: Station) => {
		return obj.station === station.name;
	};

	public typeDropdownChangeCallback: Function = (type: ResourceType) => {
		if (type.id < 0) {
			this.addNewType.emit(this.appliance);
		} else {
			this.appliance.type = type.id;
			this.appliance.__typeObj = type;
		}
	};
	public incidentDropdownChangeCallback: Function = (incident: Incident) => {
		this.appliance.id_mission = incident.id;
	};
	public stationDropdownChangeCallback: Function = (station: Station) => {
		this.appliance.station = station.name;
	};

	public hasResourceBeenEdited: Function = () => {
		const originalResource = this.res.Resources.find((e) => e.id === this.appliance.id);
		if (!originalResource) return true;
		else {
			originalResource.name.toUpperCase() === this.appliance.name.toUpperCase() && this.editing.emit(false);
			return (
				originalResource.name.toUpperCase() !== this.appliance.name.toUpperCase() ||
				originalResource.type !== this.appliance.type ||
				originalResource.color !== this.appliance.color ||
				originalResource.fixed_position !== this.appliance.fixed_position ||
				originalResource.station !== this.appliance.station ||
				originalResource.id_mission !== this.appliance.id_mission ||
				originalResource.description !== this.appliance.description ||
				originalResource.icon_path !== this.appliance.icon_path
			);
		}
	};

	public editingAppliance(): void {
		this.editing.emit(true);
	}

	public readonly toggleGpsSwitch: Function = () => {
		this.appliance.fixed_position = !this.appliance.fixed_position;
	};

	public readonly isResourceTracked: Function = () => {
		return !this.appliance.fixed_position;
	};

	addNewIcon: Function = (evt: any) => {
		const file = evt.target.files[0];
		this.readAsText(file);
	};

	attachSvgToResource: Function = (svg: any) => {
		let icon_prev_path = this.appliance.icon_path;
		try {
			let svgElem = svg.children[0];
			const width = svgElem.getAttribute("width").match(/\d+/)[0];
			const height = svgElem.getAttribute("height").match(/\d+/)[0];
			// to take the first path to make it single path and avoid the clip-path issue
			let pathElem = svgElem.getElementsByTagName("path");
			const path = pathElem[0].getAttribute("d");
			if (!this.testSvgPathDAttr || path == null || pathElem[1]) {
				this.appliance.icon_path = icon_prev_path;
				throw "invalid path";
			}
			const color = pathElem[0].getAttribute("fill");
			if (!this.testSvgPathFillAttr) throw "invalid color";
			this.appliance.icon_size = new Size(width, height);
			this.appliance.icon_width = width;
			this.appliance.icon_height = height;
			this.appliance.icon_path = path;
			this.appliance.color = color;
			return true;
		} catch (e) {
			this.main.addAlert(ALERT_TYPE.DANGER, this.text().INVALID_ICON);
			return false;
		}
	};

	testSvgPathDAttr: Function = (path: string) => {
		// copied from https://stackoverflow.com/questions/54961620/test-if-svg-path-d-property-string-is-valid
		const reEverythingAllowed = /[MmZzLlHhVvCcSsQqTtAa0-9-,.\s]/g;

		const bContainsIllegalCharacter = !!path.replace(reEverythingAllowed, "").length;
		const bContainsAdjacentLetters = /[a-zA-Z][a-zA-Z]/.test(path);
		const bInvalidStart = /^[0-9-,.]/.test(path);
		const bInvalidEnd = /.*[-,.]$/.test(path.trim());

		return !bContainsIllegalCharacter && !bContainsAdjacentLetters && !bInvalidStart && !bInvalidEnd;
	};

	testSvgPathFillAttr: Function = (fill: string) => {
		// copied from https://stackoverflow.com/questions/1636350/how-to-identify-a-given-string-is-hex-color-format
		return fill.match(/^#(([0-9a-fA-F]{2}){3}|([0-9a-fA-F]){3})$/) !== null;
	};

	public readonly getIconColorAsBg: Function = () => {
		return { "background-color": this.appliance.color };
	};

	public readonly getResourceCamera: () => Camera | undefined = () => {
		return this.res.Cameras.find((e) => e.id === this.appliance.id_camera);
	};
	public readonly openColorPicker: Function = (evt: Event) => {
		this.showColorPicker = true;
		evt.stopPropagation();
	};

	public readonly closeColorPicker: Function = () => {
		this.showColorPicker = false;
	};

	public readonly changeColor: Function = (color: string) => {
		this.appliance.color = color;
		this.cdRef.detectChanges();
	};

	private readAsText: Function = (file: File) => {
		let e = new FileReader();
		e.readAsText(file);
		e.onload = () => {
			const parser = new DOMParser();
			const svg = parser.parseFromString(e.result as string, "image/svg+xml");
			this.attachSvgToResource(svg);
		};
	};

	private readonly refreshResourceTypes: Function = () => {
		this.resourceTypes = [...this.conf.configuration.agentTypes].reverse().sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
		this.resourceTypes.push(new ResourceType(-2, "+ " + this.text().ADD.toUpperCase()));
	};
}
