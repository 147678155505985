import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import { CheckList } from "src/app/dto/jesip/checklist";
import { Debrief } from "src/app/dto/jesip/debrief";
import { IiMarch } from "src/app/dto/jesip/iimarch";
import { IncidentInformation } from "src/app/dto/jesip/incident-information";
import { Jdm } from "src/app/dto/jesip/jdm";
import { Member } from "src/app/dto/jesip/member";
import { Methane } from "src/app/dto/jesip/Methane";
import { Note } from "src/app/dto/jesip/notes";
import { CloneFactory } from "src/app/dto/net/clone-factory";
import { DTOArray } from "src/app/dto/net/dto-array";
import { NotificationUpdate, NOTIFICATION_TYPE } from "src/app/dto/notification/notification";
import { JESIP_FORMS } from "src/app/global/constants/enums/jesip_forms";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { MainService } from "src/app/global/main.service";
import { FORM_SCREENS } from "./screens.enum";
import { LoginService } from "src/app/login/login.service";
import { LOG_TYPE } from "src/app/global/constants/enums/log_types";
import { FsgBuilding } from "src/app/dto/fsg/fsg-building";

@Injectable({
	providedIn: "root"
})
export class FormsService {
	public readonly text: () => LocaleMap;

	public currentMethane: Methane | undefined;
	public methaneHistory: Methane[] = [];
	public currentIiMarch: IiMarch | undefined;
	public IiMarchHistory: Array<IiMarch> = new Array();
	public currentJdm: Jdm | undefined;
	public jdmHistory: Jdm[] = [];
	public currentDebrief: Debrief | undefined;
	public currentScreen: FORM_SCREENS | undefined;
	public currentFsg: FsgBuilding | undefined;
	public missionChecklist: CheckList | undefined;
	public notes = new Map<FORM_SCREENS, Array<Note>>();
	public readonly notifications = new Array<NotificationUpdate>();
	public readonly jesipUpdate$ = new Subject<{ form: FORM_SCREENS; mission: number }>();
	public teamInfo = new Map<JESIP_FORMS, Array<Member>>();

	private readonly wreq: WebRequestFactory;
	private readonly main: MainService;
	private readonly loginService: LoginService;

	constructor(wreq: WebRequestFactory, main: MainService, textProv: TextProvider, loginService: LoginService) {
		this.wreq = wreq;
		this.main = main;
		this.text = textProv.getStringMap;
		this.loginService = loginService;
	}

	// METHANE AREA START
	public readonly getMethane = (id_mission: number): Methane => {
		return (this.currentMethane = this.currentMethane ? this.currentMethane : new Methane(-1, id_mission, this.loginService.user.id, "", "", "", "", "", "", "", "", "", Date.now()));
	};

	public readonly loadMethane = (id_mission: number): Promise<Methane | undefined> => {
		const methane = new Methane(-1, id_mission, -1);
		return this.wreq.getJesipMethane(methane).then((ans: string[]) => {
			if (ans && ans.length) {
				if (this.methaneHistory && this.methaneHistory.length === ans.length && this.currentMethane?.id_mission === id_mission) return this.currentMethane;
				return this.setMethaneData(id_mission, ans);
			} else {
				this.methaneHistory = [];
				return (this.currentMethane = undefined);
			}
		});
	};

	public readonly saveJesipMethane = (methane: Methane): Promise<void> => {
		return this.wreq.saveJesipMethane(methane).then(() => {
			return this.loadMethane(methane.id_mission).then(() => this.main.setSnackbar(this.text().METHANE_SAVED));
		});
	};

	// METHANE AREA END

	// IIMARCH AREA START
	public readonly getIiMarch = (id_mission: number): IiMarch => {
		return (this.currentIiMarch = this.currentIiMarch ? this.currentIiMarch : new IiMarch(-1, this.loginService.user.id, id_mission, "", "", "", "", "", "", "", Date.now()));
	};

	public readonly loadIiMarch = (id_mission: number): Promise<IiMarch | undefined> => {
		const iimarch = new IiMarch(-1, -1, id_mission);
		return this.wreq.getJesipIiMarch(iimarch).then((ans: string[]) => {
			if (ans && ans.length) {
				if (this.IiMarchHistory && this.IiMarchHistory.length === ans.length && this.currentIiMarch?.id_mission === id_mission) return this.currentIiMarch;
				return this.setIimarchData(id_mission, ans);
			} else {
				this.IiMarchHistory = [];
				return (this.currentIiMarch = undefined);
			}
		});
	};

	public readonly saveJesipIiMarch = async (iimarch: IiMarch): Promise<void> => {
		return this.wreq.saveJesipIiMarch(iimarch).then(async () => {
			return this.loadIiMarch(iimarch.id_mission).then(() => this.main.setSnackbar(this.text().IIMARCH_SAVED));
		});
	};
	//IIMARCH AREA END

	// JDM AREA START

	public readonly getJdm = (id_mission: number): Jdm => {
		return (this.currentJdm = this.currentJdm ? this.currentJdm : new Jdm(-1, this.loginService.user.id, id_mission, "", "", "", "", "", Date.now()));
	};

	public readonly loadJdm = (id_mission: number): Promise<Jdm | undefined> => {
		const jdm = new Jdm(-1, -1, id_mission);
		return this.wreq.getJesipJdm(jdm).then((ans: string[]) => {
			if (ans && ans.length) {
				if (this.jdmHistory && this.jdmHistory.length === ans.length && this.currentJdm?.id_mission === id_mission) return this.currentJdm;
				return this.setJdmData(id_mission, ans);
			} else {
				this.jdmHistory = [];
				return (this.currentJdm = undefined);
			}
		});
	};

	public readonly saveJesipJdm = (jdm: Jdm): Promise<void> => {
		return this.wreq.saveJesipJdm(jdm).then(() => {
			return this.loadJdm(jdm.id_mission).then(() => this.main.setSnackbar("JDM" + " " + this.text().HAS_BEEN_SAVED));
		});
	};

	// JDM AREA END

	public readonly loadDebrief: (id_mission: number) => Promise<Debrief | undefined> = (id_mission) => {
		const request = new Debrief(-1, id_mission, this.loginService.user.id, Date.now());
		return this.wreq.getJesipDebrief(request).then((ans: string) => {
			if (!ans || !ans.length) {
				return this.wreq.addDebriefToMission(request).then(() => {
					return (this.currentDebrief = request);
				});
			} else return (this.currentDebrief = Debrief.fromJson(ans[0]));
		});
	};

	public readonly getDebrief: (id_mission: number) => Promise<Debrief | undefined> = (id_mission) => {
		if (this.currentDebrief && this.currentDebrief.id_mission === id_mission) return Promise.resolve(this.currentDebrief);
		else return this.loadDebrief(id_mission);
	};

	public readonly saveJesipDebrief = (debrief: Debrief): Promise<void> => {
		return this.wreq.saveJesipDebrief(debrief).then((ans) => {
			if (ans) {
				this.currentDebrief = debrief;
				this.main.setSnackbar(this.text().DEBRIEF + " " + this.text().HAS_BEEN_SAVED);
			} else this.main.setSnackbar(this.text().SAVE_ERROR);
		});
	};

	public readonly loadFormTeam: (id_mission: number, form: JESIP_FORMS) => Promise<Array<Member>> = (id_mission, form) => {
		const request = new Member(-1, id_mission, form);
		return this.wreq.getJesipCommander(request).then((ans: Array<string>) => {
			if (!ans || !ans.length) return [];
			let arr = new Array<Member>();
			arr = DTOArray.UpdateFromIdlessJsonArray(arr, ans, Member);
			arr = arr.filter((el) => el.form === form);
			arr.sort((a, b) => {
				if (a.id > b.id) return 1;
				else return -1;
			});
			// This following command requires to be rechecked, the arr returns all the member identified only by mission_id
			this.teamInfo.set(form, arr);
			return arr;
		});
	};

	public readonly saveMember: (mem: Member) => Promise<boolean> = (mem) => {
		return this.wreq.saveJesipCommander(mem).then((ans: string) => {
			if (!ans) return false;
			return true;
		});
	};

	public readonly removeMember = (): void => {
		console.log("functionality not implemented yet on the BE");
	};

	public readonly saveIncidentInformation: (info: IncidentInformation) => Promise<boolean> = (info) => {
		return this.wreq.saveJesipIncidentInformation(info).then((ans: string) => {
			if (!ans) return false;
			return true;
		});
	};

	public readonly loadIncidentInformation: (id_mission: number, form: JESIP_FORMS.DEBRIEF | JESIP_FORMS.JDM) => Promise<IncidentInformation | undefined> = (id_mission, form) => {
		const request = new IncidentInformation(id_mission, form);
		return this.wreq.getJesipIncidentInformation(request).then((ans: Array<string>) => {
			if (!ans || !ans.length) return;
			return IncidentInformation.fromJson(ans[0]);
		});
	};

	// *** CHECKLIST AREA START
	public readonly addChecklist = (id_mission: number, id_user: number): Promise<CheckList | undefined> => {
		const checklist = new CheckList(id_mission, id_user, false, false, false, false, false, false, false, false, false, "");
		return this.wreq.saveNewChecklist(checklist).then((res: string[]) => {
			if (res && res.length) {
				return (this.missionChecklist = CheckList.fromJson(res[0]));
			}
			return;
		});
	};

	public readonly loadChecklist = (id_mission: number, id_user: number): Promise<CheckList | undefined> => {
		const checklist = new CheckList(id_mission, id_user, false, false, false, false, false, false, false, false, false, "");
		return this.wreq.getChecklist(checklist).then((res: string) => {
			if (res && res.length) {
				this.missionChecklist = CheckList.fromJson(res[0]);
				return this.missionChecklist;
			}
			return (this.missionChecklist = undefined);
		});
	};

	public readonly updateChecklist = (checklist: CheckList): Promise<CheckList | undefined> => {
		return this.wreq.saveChecklist(checklist).then((res: string[]) => {
			if (res && res.length) {
				this.missionChecklist = CheckList.fromJson(res[0]);
				return this.missionChecklist;
			}
			return;
		});
	};
	public readonly isDebriefInit: () => boolean = () => {
		if (!this.currentDebrief) return false;
		for (let i = 0; i < this.currentDebrief.debrief.length; i++) {
			const option = this.currentDebrief.debrief[i];
			if (option.items.find((e) => e && e !== "")) return true;
		}
		return false;
	};

	// CHECKLIST AREA END ***

	// NOTIFICATIONS AREA
	public readonly setNotification = (form: NOTIFICATION_TYPE, id: number, mission: number): void => {
		const notification = this.notifications.find((notification) => notification.title === form && notification.mission === mission && notification.id_data === id);
		if (notification) notification.read = false;
		else this.notifications.push(new NotificationUpdate(id, form, mission, false));
	};

	public readonly getUnreadNotification = (form: NOTIFICATION_TYPE, mission: number): boolean => {
		return this.notifications.some((notification) => notification.title === form && notification.mission === mission && !notification.read);
	};

	public readonly clearNotification = (form: NOTIFICATION_TYPE, mission: number): void => {
		const notifications = this.notifications.filter((notification) => notification.title === form && notification.mission === mission);
		notifications.length && notifications.forEach((item) => (item.read = true));
		this.jesipUpdate$.next();
	};

	public readonly updateForms = (form: FORM_SCREENS, id_mission: number): void => {
		const currentMissionId = this.main.getCurrentMission()?.id;
		if (!currentMissionId || currentMissionId === -1 || !id_mission || id_mission === -1 || id_mission !== currentMissionId) return;
		switch (form) {
			case FORM_SCREENS.METHANE:
				this.loadMethane(id_mission).then(() => {
					if (this.currentMethane && this.currentMethane.id_user !== this.loginService.user.id) this.setNotification(NOTIFICATION_TYPE.METHANE, this.currentMethane.id, id_mission);
					this.jesipUpdate$.next({ form: FORM_SCREENS.METHANE, mission: id_mission });
				});
				break;
			case FORM_SCREENS.JDM:
				this.loadJdm(id_mission).then(() => {
					if (this.currentJdm && this.currentJdm.id_user !== this.loginService.user.id) this.setNotification(NOTIFICATION_TYPE.JDM, this.currentJdm.id, id_mission);
					this.jesipUpdate$.next({ form: FORM_SCREENS.JDM, mission: id_mission });
				});
				break;
			case FORM_SCREENS.IIMARCH:
				this.loadIiMarch(id_mission).then(() => {
					if (this.currentIiMarch && this.currentIiMarch.id_user !== this.loginService.user.id) this.setNotification(NOTIFICATION_TYPE.IIMARCH, this.currentIiMarch.id, id_mission);
					this.jesipUpdate$.next({ form: FORM_SCREENS.IIMARCH, mission: id_mission });
				});
				break;
			case FORM_SCREENS.DEBRIEF:
				this.loadDebrief(id_mission).then(() => {
					if (this.currentDebrief && this.currentDebrief.id_user !== this.loginService.user.id) this.setNotification(NOTIFICATION_TYPE.DEBRIEF, this.currentDebrief.id, id_mission);
					this.jesipUpdate$.next({ form: FORM_SCREENS.DEBRIEF, mission: id_mission });
				});
				break;
		}
	};

	public readonly changeScreen = (screen: FORM_SCREENS): void => {
		this.currentScreen = screen;
		if (screen !== FORM_SCREENS.MAIN) this.wreq.logInformation(LOG_TYPE.CHANGE_SCREEN, screen);
	};

	public readonly setIimarchData = (id_mission: number, data: string[]): IiMarch => {
		this.IiMarchHistory = new Array<IiMarch>();
		data.forEach((item) => {
			this.IiMarchHistory.push(IiMarch.fromJson(item));
		});
		this.IiMarchHistory.sort((a, b) => {
			return a.timestamp_ms - b.timestamp_ms;
		});
		this.currentIiMarch = new IiMarch(-1, id_mission, -1);
		CloneFactory.cloneProperties(this.currentIiMarch, this.IiMarchHistory.reverse()[0]);
		return this.currentIiMarch;
	};

	private readonly setMethaneData = (id_mission: number, data: string[]): Methane => {
		this.methaneHistory = new Array<Methane>();
		data.forEach((item) => {
			this.methaneHistory.push(Methane.fromJson(item));
		});
		this.methaneHistory.sort((a, b) => {
			return a.timestamp_ms - b.timestamp_ms;
		});
		this.currentMethane = new Methane(-1, id_mission, -1);
		CloneFactory.cloneProperties(this.currentMethane, this.methaneHistory.reverse()[0]);
		return this.currentMethane;
	};

	private readonly setJdmData = (id_mission: number, data: string[]): Jdm => {
		this.jdmHistory = new Array<Jdm>();
		data.forEach((item) => {
			this.jdmHistory.push(Jdm.fromJson(item));
		});
		this.jdmHistory.sort((a, b) => {
			return a.timestamp_ms - b.timestamp_ms;
		});
		this.currentJdm = new Jdm(-1, id_mission, -1);
		CloneFactory.cloneProperties(this.currentJdm, this.jdmHistory.reverse()[0]);
		return this.currentJdm;
	};
}
