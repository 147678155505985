import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren, ɵdetectChanges } from "@angular/core";
import * as html2canvas from "html2canvas";
import { Event } from "src/app/dto/items/event";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { DocumentService } from "src/app/document/document.service";
import { DocumentTemplate } from "../../template.interface";
import { FsgFlat } from "src/app/dto/fsg/fsg-flat";
import { FsgBuilding } from "src/app/dto/fsg/fsg-building";
import { FsgFlatHistory } from "src/app/dto/fsg/flat-history";
import { UserService } from "src/app/settings/user/user.service";
import { FLAT_SECTOR } from "src/app/dto/fsg/enums";
import { PAGE_SIZES } from "src/app/dto/Documents/page-sizes";

@Component({
	selector: "app-fsg-history-template",
	templateUrl: "fsg-history.component.html",
	styleUrls: ["fsg-history.css", "../fsg.css", "../../../document.css"]
})
export class FsgHistoryTemplateComponent implements OnInit, DocumentTemplate, AfterViewInit {
	@Input() event!: Event;
	@Input() fsg!: FsgBuilding;

	@Output() docReady = new EventEmitter<HTMLCanvasElement[]>();

	@ViewChildren("pageElements") pageElements!: QueryList<ElementRef>;
	@ViewChild("inspectedFlatElement") inspectedFlatElement: ElementRef | undefined;
	@ViewChild("inspectedHeaderElement") inspectedHeaderElement: ElementRef | undefined;
	@ViewChild("inspectedPageHeaderElement") inspectedPageHeaderElement: ElementRef | undefined;

	public numberOfPages: number = 1;
	public pages = new Array<Array<FsgFlatHistory>>();
	public readonly pageSize = PAGE_SIZES.STANDARD;

	public readonly text: () => LocaleMap;

	public inspectedFlat: FsgFlat | undefined;
	public inspectedHeader: FsgFlat | undefined;
	public inspectedPageHeader: boolean = false;

	private readonly docService: DocumentService;
	private readonly userServ: UserService;
	private abortDownload = false;

	constructor(tp: TextProvider, doc: DocumentService, userServ: UserService) {
		this.text = tp.getStringMap;
		this.docService = doc;
		this.userServ = userServ;
	}

	ngOnInit(): void {
		if (!this.event || !this.fsg || !this.fsg.__history) this.docService.downloadError$.next();
		this.setPages();
		this.docService.onDownloadCancel$.subscribe(() => (this.abortDownload = true));
	}

	async ngAfterViewInit(): Promise<void> {
		try {
			const pages = this.pageElements["_results"];
			//Patch for preventing browser crash aborting de generation of documents and download
			if (pages.length >= 20) {
				this.docService.setUnavailabeError();
				return;
			}
			const output = new Array<HTMLCanvasElement>();
			for (let i = 0; i < pages.length; i++) {
				if (!this.docService.downloadingFullIncident) this.docService.setLoadingMessage(this.text().GENERATING_DOCUMENTATION_PAGE(i, pages.length));
				else {
					console.info(this.text().GENERATING_DOCUMENTATION_PAGE(i, pages.length));
					this.docService.setLoadingMessage(this.text().DOWNLOAD_PROCESS_TEXT);
				}
				const canvas = await html2canvas.default(this.pageElements["_results"][i].nativeElement);
				if (this.abortDownload) return;
				output.push(canvas);
			}

			this.docReady.emit(output);
		} catch (error) {
			if (this.abortDownload) return;
			console.error(error);
			this.docService.downloadError$.next();
		}
	}

	public readonly setPages: Function = () => {
		const history = this.fsg.__history?.filter((e) => e.id_mission === this.event.id_mission)!;
		history.forEach((his) => {
			// removes changes of attributes that are not reflected on documents, and reverts the order
			his.history = his.history.filter((e, idx) => {
				if (idx === 0) return true;
				const prev = his.history[idx - 1];
				return (
					e.source_of_information !== prev.source_of_information ||
					e.people !== prev.people ||
					e.condition.join() !== prev.condition.join() ||
					e.fsg_advice !== prev.fsg_advice ||
					e.priority !== prev.priority ||
					e.comments !== prev.comments ||
					e.casualty_status !== prev.casualty_status ||
					e.cleared !== prev.cleared
				);
			});
			his.history.sort((a, b) => {
				return b.timestamp_ms - a.timestamp_ms;
			});
		});

		history.sort((a, b) => (b.id_flat > a.id_flat ? 1 : -1));

		const headerHeight = this.calculatePageHeaderHeight(); //88
		const footerHeight = 42;
		const pagePadding = 32;
		const flatHeaderHeight = 34;
		let currentPageHeight = headerHeight + footerHeight + pagePadding;

		let newPage = new Array<FsgFlatHistory>();
		let currentIndex = -1;

		for (let flatHistory of history) {
			currentIndex++;
			currentPageHeight += flatHeaderHeight;
			for (let item of flatHistory.history) {
				const flatHeight = this.calculateFlatHeight(item);
				if (currentPageHeight + flatHeight < this.pageSize.height) {
					if (!newPage[currentIndex]) {
						newPage.push(new FsgFlatHistory(flatHistory.id, flatHistory.id_mission, flatHistory.flat_name, flatHistory.floor_number));
					}
					newPage[currentIndex].history.push(item);

					currentPageHeight += flatHeight;
				} else {
					this.pages.push(newPage);
					currentPageHeight = headerHeight + footerHeight + pagePadding + flatHeaderHeight;
					newPage = new Array<FsgFlatHistory>();
					const newPageHistory = new FsgFlatHistory(flatHistory.id, flatHistory.id_mission, flatHistory.flat_name, flatHistory.floor_number);
					newPageHistory.history = [item];
					newPage.push(newPageHistory);
					currentPageHeight += flatHeight;
					currentIndex = 0;
				}
			}
		}

		if (!this.pages.find((e) => e === newPage)) {
			this.pages.push(newPage);
		}
	};

	public readonly hasValueChanged = (flatchange: FsgFlatHistory, idx: number, col: keyof FsgFlat): boolean => {
		return flatchange.history[idx].__changeAttr === col || (col === "priority" && flatchange.history[idx].priority !== -1) || !!this.getFlatClearedInfo(flatchange.history[idx], flatchange.history, idx).trim() || flatchange.history[idx].deleted;
	};

	public getUserNameById(id: number): string {
		return this.userServ.Users.find((user) => user.id === id)?.name ?? "";
	}

	public getFlatLatestName(flat: FsgFlatHistory): string {
		const latestFlatValue = this.fsg.__flats?.find((f) => f.id_flat === flat.id_flat);
		if (latestFlatValue) return latestFlatValue.flat_name;
		else if (flat.history.length) return flat.history[0].flat_name;
		return flat.flat_name;
	}

	public getSectorName(sector: FLAT_SECTOR): string {
		if (!sector || sector === FLAT_SECTOR.DEFAULT) return "-";
		switch (sector) {
			case FLAT_SECTOR.FIRE:
				return this.text().FSG_FIRE_SECTOR;
			case FLAT_SECTOR.BRIDGE:
				return this.text().FSG_BRIDGE_SECTOR;
			case FLAT_SECTOR.SEARCH:
				return this.text().FSG_SEARCH_SECTOR;
			default:
				return this.text().FSG_LOBBY_SECTOR;
		}
	}

	public getPriorityText(flat: FsgFlat, flatsHistory: FsgFlat[], i: number): string {
		let priority: string = "";
		let deleted: string = "";
		if (flat.priority !== -1) priority = "P" + flat.priority;
		if (flat.deleted) deleted = this.text().DELETED;
		const cleared = this.getFlatClearedInfo(flat, flatsHistory, i);
		const result = `${priority} ${priority && (deleted || cleared) ? "," : ""} ${deleted} ${priority && deleted ? "," : ""} ${cleared}`;
		return result.trim() || "-";
	}

	private getFlatClearedInfo(flat: FsgFlat, flatsHistory: FsgFlat[], flatIndex: number): string {
		if (flat.cleared) return this.text().CLEARED;
		if (flatIndex < flatsHistory.length - 1 && !flat.cleared && flatsHistory[flatIndex + 1].cleared) return this.text().UNCLEARED;
		return "";
	}

	// builds a mock header to calculate its height
	private calculatePageHeaderHeight(): number {
		this.inspectedPageHeader = true;
		ɵdetectChanges(this);
		const output = this.inspectedPageHeaderElement?.nativeElement?.getBoundingClientRect()?.height;
		this.inspectedPageHeader = false;
		return output;
	}

	/**
	 * This method will always return 137 according to the new EF-PDF styles fixed heights but it hasn´t been removed for scalability
	 * @returns total flat element height as number
	 */
	private readonly calculateFlatHeight: (flat: FsgFlat) => number = (flat) => {
		this.inspectedFlat = flat;
		ɵdetectChanges(this);
		const output = this.inspectedFlatElement?.nativeElement?.getBoundingClientRect()?.height;
		this.inspectedFlat = undefined;
		return output;
	};
}
