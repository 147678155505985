<div
	#pageElements
	*ngFor="let page of pages; let i = index"
	class="page"
	[style.width.px]="pageSize.width"
	[style.height.px]="pageSize.height"
>
	<div class="index"></div>
	<div class="header">
		<b class="number">
			<u id="docEventNum"> N: {{ event.num ? event.num : event.id }}</u>
		</b>
		<div class="title">
			{{ text().LOG_FORMS.toUpperCase() }} {{ text().FOR.toUpperCase() }}
			{{ event.name.toUpperCase() }}
		</div>
	</div>
	<div class="subheader">
		<div>
			{{ text().CREATION }}: {{ event.mission!.start_time_ms | date : "dd/MM/YY HH:mm" }}
		</div>
		<div>
			<span>{{ text().FINISHED }}: </span>
			<span class="docFinishedTime" id="docEventFinishTime">
				{{
					event.mission!.closed &&
					event.mission!.end_time_ms &&
					event.mission!.end_time_ms > 0
						? (event.mission!.end_time_ms | date : "dd/MM/YY HH:mm")
						: "--"
				}}
			</span>
		</div>
		<div>
			{{ text().DOCUMENT_GENERATED_ON }}: {{ generationTime | date : "dd/MM/YY HH:mm" }}
		</div>
	</div>

	<div class="table">
		<div class="table-header">
			<div class="date">
				<div>{{ text().DATE }}</div>
			</div>
			<div class="time">
				<div>{{ text().TIME }}</div>
			</div>
			<div *ngIf="isDLType" class="type">
				<div>{{ text().TYPE }}</div>
			</div>
			<div class="content">
				<div>{{ text().CONTENT }}</div>
			</div>
			<div class="rationale">
				<div>{{ text().RATIONALE }}</div>
			</div>
			<div *ngIf="isDLOperational" class="tactical">
				<div>{{ text().TACTICAL }}</div>
			</div>
			<div *ngIf="isDLOperational" class="status">
				<div>{{ text().STATUS }}</div>
			</div>
			<div class="author">
				<div>{{ text().AUTHOR }}</div>
			</div>
		</div>
		<div *ngFor="let decision of page" class="drow">
			<div class="date">
				<div>{{ decision.timestamp_ms | date : "dd/MM/yy" }}</div>
			</div>
			<div class="time">
				<div>{{ decision.timestamp_ms | date : "HH:mm" }}</div>
			</div>
			<div *ngIf="isDLType" class="type">
				<div>{{ decision.type }}</div>
			</div>
			<div class="content">
				<div [innerHTML]="decision.description"></div>
			</div>
			<div class="rationale">
				<div>{{ decision.result }}</div>
			</div>
			<div *ngIf="isDLOperational" class="tactical">
				<div>{{ decision.tactical }}</div>
			</div>
			<div *ngIf="isDLOperational" class="status">
				<div>{{ decision.state }}</div>
			</div>
			<div class="author">
				<div><span *ngIf="isDLSignature">I.C</span> {{ decision.name }}</div>
				<div *ngIf="isDLSignature"><span>A.C</span> {{ decision.__signName }}</div>
			</div>
		</div>
	</div>
	<div class="page-index">
		<b>{{ text().PAGE }}:</b> <span>{{ i + 1 }} / {{ pages.length }}</span>
	</div>
</div>

<div class="inspector">
	<div #inspectedHeaderElement *ngIf="inspectedHeader">
		<div class="header">
			<b class="number">
				<u id="docEventNum">N: {{ event.num ? event.num : event.id }}</u>
			</b>
			<div class="title">
				{{ text().LOG_FORMS.toUpperCase() }} {{ text().FOR.toUpperCase() }}
				{{ event.name.toUpperCase() }}
			</div>
		</div>
		<div class="subheader">
			<div>
				{{ text().CREATION }}: {{ event.mission!.start_time_ms | date : "dd/MM/YY HH:mm" }}
			</div>
			<div>
				<span>{{ text().FINISHED }}: </span>
				<span class="docFinishedTime" id="docEventFinishTime">
					{{
						event.mission!.closed &&
						event.mission!.end_time_ms &&
						event.mission!.end_time_ms > 0
							? (event.mission!.end_time_ms | date : "dd/MM/YY HH:mm")
							: "--"
					}}
				</span>
			</div>
			<div>
				{{ text().DOCUMENT_GENERATED_ON }}: {{ generationTime | date : "dd/MM/YY HH:mm" }}
			</div>
		</div>

		<div class="table">
			<div class="table-header">
				<div class="date">
					<div>{{ text().DATE }}</div>
				</div>
				<div class="time">
					<div>{{ text().TIME }}</div>
				</div>
				<div *ngIf="isDLType" class="type">
					<div>{{ text().TYPE }}</div>
				</div>
				<div class="content">
					<div>{{ text().CONTENT }}</div>
				</div>
				<div class="rationale">
					<div>{{ text().RATIONALE }}</div>
				</div>
				<div *ngIf="isDLOperational" class="tactical">
					<div>{{ text().TACTICAL }}</div>
				</div>
				<div *ngIf="isDLOperational" class="status">
					<div>{{ text().STATUS }}</div>
				</div>
				<div class="author">
					<div>{{ text().AUTHOR }}</div>
				</div>
			</div>
		</div>
	</div>
	<div #inspectedDecisionElement *ngIf="inspectedDecision">
		<div class="drow">
			<div class="date">
				<div>{{ inspectedDecision.timestamp | date : "dd/MM/yy" }}</div>
			</div>
			<div class="time">
				<div>{{ inspectedDecision.timestamp | date : "HH:mm" }}</div>
			</div>
			<div *ngIf="isDLType" class="type">
				<div>{{ inspectedDecision.type }}</div>
			</div>
			<div class="content">
				<div>{{ inspectedDecision.description }}</div>
			</div>
			<div class="rationale">
				<div>{{ inspectedDecision.result }}</div>
			</div>
			<div *ngIf="isDLOperational" class="tactical">
				<div>{{ inspectedDecision.tactical }}</div>
			</div>
			<div *ngIf="isDLOperational" class="status">
				<div>{{ inspectedDecision.state }}</div>
			</div>
			<div class="author">
				<div>
					<span *ngIf="isDLSignature">I.C</span>
					{{ inspectedDecision.name }}
				</div>
				<div *ngIf="isDLSignature">
					<span>A.C</span>
					{{ inspectedDecision.__signName }}
				</div>
			</div>
		</div>
	</div>
</div>
