import { CSBlock } from "./cs-block";
import { DTO } from "../net/dto";
import { POISymbol } from "../map/poi-symbol";
import { EsriMarker } from "src/app/incident/map/components/map/esri/components/graphics/esri-marker/esri-marker.interface";

export class CSCommander extends CSBlock implements DTO {
	// icsIncidentCommander

	id_commander: number;
	id_mission: number;
	latitude: number;
	longitude: number;
	name: string;
	control_room: string;
	id_resource: number;

	id: number;
	__marker: POISymbol | EsriMarker | undefined;
	timestamp = Date.now();
	constructor(id_commander: number, id_mission: number, name: string, lat?: number, lon?: number, control_room?: string, color?: string, title?: string, x?: number, y?: number, id_resource?: number) {
		super(color ? color : "#377ab7", title ? title : "", x ? x : 0, y ? y : 0);
		this.id_commander = id_commander ? id_commander : -1;
		this.id = this.id_commander;
		this.id_mission = id_mission ? id_mission : -1;
		this.latitude = lat ? lat : 0;
		this.longitude = lon ? lon : 0;
		this.name = name ? name : "";
		this.control_room = control_room ? control_room : "TBD";
		this.id_resource = id_resource ? id_resource : -1;
	}

	public static readonly fromJson: (json: string) => CSCommander = function (json) {
		var jc = JSON.parse(json);
		return new CSCommander(jc.id_commander, jc.id_mission, jc.name, jc.lat, jc.lon, jc.control_room, jc.color, jc.title, jc.x, jc.y, jc.id_resource);
	};
	public readonly getJson: Function = () => {
		(this as any).lat = this.latitude;
		(this as any).lon = this.longitude;
		return JSON.stringify(this, ["id_commander", "id_mission", "lat", "lon", "name", "control_room", "color", "title", "x", "y", "id_resource"]);
	};
}
