import { Component, Input, OnInit } from "@angular/core";
import { ConfigurationService } from "src/app/settings/types/configuration.service";
import { EventType } from "src/app/dto/items/types/event-type";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-incident-card",
	templateUrl: "incident-card.component.html",
	styleUrls: ["incident-card.css"]
})
export class IncidentCardComponent implements OnInit {
	@Input() canDelete: boolean = false;

	@Input() incidentType!: EventType;

	@Input() saveCb: Function = () => {};

	@Input() deleteCb: Function = () => {};

	public text: () => LocaleMap;

	public showColorPicker: boolean = false;

	private readonly conf: ConfigurationService;

	constructor(conf: ConfigurationService, text: TextProvider) {
		this.conf = conf;
		this.text = text.getStringMap;
	}

	save: Function = () => {};

	delete: Function = () => {};

	ngOnInit() {
		if (!this.incidentType) throw "IncidentCardComponent initialized without a valid incidentType input";
		this.save = () => {
			this.saveCb(this.incidentType);
		};

		this.delete = () => {
			this.deleteCb(this.incidentType);
		};
	}

	public readonly getTypeColorAsBg = (): { "background-color": string } => {
		return {
			"background-color": this.incidentType.color
		};
	};

	public readonly hasIncidentBeenModified = (): boolean => {
		const originalType = this.conf.configuration.eventTypes.find((e) => e.id === this.incidentType.id);
		if (!originalType) return true;
		return this.incidentType.name.toUpperCase() !== originalType.name!.toUpperCase() || this.incidentType.color.toUpperCase() !== originalType.color!.toUpperCase();
	};

	public readonly openColorPicker = (evt: Event): boolean => {
		return (this.showColorPicker = true);
	};

	public readonly closeColorPicker = (): boolean => {
		return (this.showColorPicker = false);
	};

	public readonly changeColor = (color: string): string => {
		return (this.incidentType.color = color);
	};
}
