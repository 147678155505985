import { formatDate } from "@angular/common";
import { Component, OnDestroy, OnInit, Input } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { MAP_PROFILES } from "src/app/global/constants/enums/map-profiles";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { Event } from "src/app/dto/items/event";
import { SCREEN } from "src/app/global/constants/enums/screens";
import { EventsMissionsService } from "src/app/incident/event-mission.service";
import { NavigationService } from "src/app/navigation/navigation.service";
import { ConstantsService } from "src/app/global/constants/constants.service";
import { ConfigurationService } from "src/app/settings/types/configuration.service";
import { UserService } from "src/app/settings/user/user.service";
import { ProbeService } from "src/app/http/probe.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FunctionalityService } from "src/app/global/functionality.service";

@Component({
	selector: "app-iris-header",
	templateUrl: "app-iris-header.component.html",
	styleUrls: ["iris-header.css"]
})
export class IrisHeaderComponent implements OnInit, OnDestroy {
	public readonly version: string;
	public showIncidentInfo: boolean = false;
	public currentEvent: Event | undefined;
	public text: () => LocaleMap;
	public isOffline: boolean = false;
	public showOnlineBanner: boolean = false;
	public activatedRoute?: string;
	public isTooltipVisible: boolean = false;
	public funcServ: FunctionalityService;

	private destroy$: Subject<boolean> = new Subject();
	private readonly ems: EventsMissionsService;
	private readonly navServ: NavigationService;
	private readonly userService: UserService;
	private readonly probeService: ProbeService;
	private readonly router: Router;
	private readonly conf: ConfigurationService;

	constructor(ems: EventsMissionsService, navServ: NavigationService, cnst: ConstantsService, conf: ConfigurationService, router: Router, textProv: TextProvider, userService: UserService, probeService: ProbeService, funcServ: FunctionalityService) {
		this.navServ = navServ;
		this.ems = ems;
		this.userService = userService;
		this.text = textProv.getStringMap;
		this.probeService = probeService;
		this.conf = conf;
		this.funcServ = funcServ;

		this.version = cnst.VERSION;
		this.router = router;
	}

	ngOnInit(): void {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) this.activatedRoute = event.url.replace("/", "");
			if (this.activatedRoute === SCREEN.INCIDENT) {
				const idIncident = this.conf.configuration.id_current_mission;
				this.currentEvent = this.ems.Events.find((e) => e.id_mission == idIncident);
			}
		});
		this.probeService.$systemOffline.pipe(takeUntil(this.destroy$)).subscribe((isOffline) => (this.isOffline = isOffline));
		const isOnline = localStorage.getItem("onlineStatus");
		if (isOnline && isOnline === "online") {
			localStorage.removeItem("onlineStatus");
			this.setOnlineBanner();
		}

		if (!navigator.onLine) this.isOffline = true;
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
	}

	get isBurgerMenuVisible(): boolean {
		return this.navServ.shouldShowNavigation();
	}

	get incidentId(): string {
		const evt = this.currentEvent;
		if (!evt || !evt.__typeObj) return "";
		return `${evt.num}`;
	}

	get eventName(): string {
		if (this.currentEvent) {
			return this.currentEvent.name.toString();
		}
		return "";
	}

	public isLoginPage(): boolean {
		return !!this.activatedRoute && this.activatedRoute === SCREEN.LOGIN;
	}

	public toggleIncidentInfo(): void {
		this.showIncidentInfo = !this.showIncidentInfo;
	}

	public getDateString(): string {
		return formatDate(Date.now(), "HH:mm", "en-US");
	}

	public shouldShowIncidentInfo(): boolean {
		return (
			this.navServ.currentScreen !== SCREEN.HOME && this.navServ.currentScreen !== SCREEN.LOGIN && this.navServ.currentScreen !== SCREEN.ASSETS && this.navServ.currentScreen !== SCREEN.USERS && this.navServ.currentScreen !== SCREEN.ACCOUNT && this.navServ.currentScreen !== SCREEN.CONFIGURATION
		);
	}

	public headerNavigation(): void {
		this.clearIncidentInfoState();
		this.isInHistoric() ? this.navServ.goTo(SCREEN.HOME, undefined, 1) : this.navServ.goTo(SCREEN.HOME);
	}

	public getMapProfile(): MAP_PROFILES {
		if (!this.ems.getCurrentMission()) return MAP_PROFILES.HOMEPAGE;
		return this.ems.getCurrentMission() && !this.ems.getCurrentMission()!.closed ? MAP_PROFILES.OFV : MAP_PROFILES.HISTORIC;
	}

	public isInHistoric(): boolean {
		return this.getMapProfile() === MAP_PROFILES.HISTORIC;
	}

	public hasAccessToHomePage(): boolean {
		return this.userService.getCurrentUserLevel().all_incident_access;
	}

	public toggleTooltip(): void {
		this.isTooltipVisible = !this.isTooltipVisible;
	}

	public setOnlineBanner(): void {
		this.showOnlineBanner = true;
		setTimeout(() => (this.showOnlineBanner = false), 3000);
	}

	private clearIncidentInfoState(): void {
		this.showIncidentInfo = false;
	}
}
