import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import { UploadedFile } from "src/app/dto/net/uploaded-file";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { PdfJsDist } from "src/app/dto/pdfjs-dist/pdfJs-dist";
import { BlobObj } from "src/app/dto/blob-obj";
import { ALERT_TYPE, MainService } from "src/app/global/main.service";
import { MapItemsService } from "src/app/incident/map/map-items.service";

@Component({
	selector: "app-upload-drag-zone",
	templateUrl: "upload-drag-zone.component.html",
	styleUrls: ["upload-drag-zone.css"]
})
export class UploadDragZoneComponent implements OnInit {
	@Input() isPoi: boolean = false;
	@Input() isOverlay: boolean = false;

	@Input() attachment: boolean | undefined;
	@Input() itemId: number | undefined;

	@Output() fileUploaded = new EventEmitter<Array<UploadedFile>>();
	@Output() uploading = new EventEmitter<string>();

	public readonly text: () => LocaleMap;
	public notAccepted: boolean = false;
	public active: boolean = false;
	public loading: boolean = false;
	public supportedFiles: RegExp = /\.jpg|\.jpeg|\.png|\.svg/i;
	public supportedFilesAsArray = [".jpg", ".jpeg", ".png", ".svg"];

	private readonly wreq: WebRequestFactory;
	private readonly mis: MapItemsService
	private readonly main: MainService;

	constructor(textProv: TextProvider, wreq: WebRequestFactory, mis: MapItemsService, main: MainService) {
		this.text = textProv.getStringMap;
		this.wreq = wreq;
		this.mis = mis;
		this.main = main;
	}

	ngOnInit(): void {
		if (this.isPoi) {
			this.supportedFiles = /\.jpg|\.jpeg|\.png|\.mp4|\.pdf/i;
			this.supportedFilesAsArray = [".jpg", ".jpeg", ".png", ".mp4", ".pdf"];
		}
		if (this.isOverlay) {
			this.supportedFiles = /\.jpg|\.jpeg|\.png|\.svg|\.pdf/i;
			this.supportedFilesAsArray = [".jpg", ".jpeg", ".png", ".svg", ".pdf"];
		}
	}

	public readonly onDragOver: (event: DragEvent) => void = (event) => {
		event.preventDefault();
		this.active = false;
	};

	public readonly onDragOut: (event: MouseEvent) => void = (event) => {
		event.preventDefault();
		this.active = false;
	};

	// From drag and drop
	public readonly onDropSuccess = (event: DragEvent): void => {
		event.preventDefault();
		this.handleFilesUpload(event.dataTransfer?.files);
	};

	public readonly onSelectFile: (event: any) => void = (event) => {
		this.handleFilesUpload(event.target.files);
	};

	private handleFilesUpload(files: FileList | undefined): void {
		if (files) {
			this.notAccepted = false;
			if (this.checkValidFileName(files)) {
				if (this.isFileTooLarge(files)) {
					this.main.addAlert(ALERT_TYPE.DANGER, this.text().FILE_TO_LARGE_MESSAGE, this.text().FILE_TO_LARGE);
					return;
				}
				if (this.isPoi) this.attachFileToPoi(files);
				else if (this.isPdf(files[0].name) && this.isOverlay) this.uploadPdfAsImg(files[0]);
				else this.addImage([files[0]]);
			} else this.notAccepted = true;
		}
	}

	private readonly attachFileToPoi = async (files: FileList): Promise<void> => {
		this.loading = true;
		for (let index = 0; index < files.length; index++) {
			const file = files[index];
			this.uploading.emit(file.name);
			const uploadedFile = await this.mis.attachToPoi(this.itemId!, file);
			if (uploadedFile) {
				this.fileUploaded.emit([uploadedFile]);
				this.main.setSnackbar(this.text().FILE_ATTACHED);
			} else this.fileUploaded.emit();
			this.loading = false;
		}
	};

	private readonly uploadPdfAsImg: (file: File) => Promise<void> = async (file) => {
		const fileName = file.name;
		const ArrayBuffer: ArrayBufferLike = await file.arrayBuffer();
		const typedarray = new Uint8Array(ArrayBuffer);
		const pdfExporter: PdfJsDist = new PdfJsDist(typedarray, fileName);
		const blobObjArray: Array<BlobObj> = await pdfExporter.esportPdfToBlob();
		blobObjArray && this.canvasToBlob(blobObjArray);
	};

	private readonly canvasToBlob = (blob: BlobObj[]): void => {
		const files: File[] = [];
		blob.forEach((image) => {
			files.push(new File([image.blob], image.fileName, image.blob));
		});
		this.addImage(files);
	};

	private readonly addImage = async (files: File[]): Promise<void> => {
		if (files && files.length) {
			const filesToEmit: UploadedFile[] = [];
			try {
				for (let i = 0; i < files.length; i++) {
					const res = await this.wreq.uploadFile(files[i].name, files[i]);
					res && filesToEmit.push(res);
				}
			} catch (error) {
				this.fileUploaded.emit();
			}
			this.fileUploaded.emit(filesToEmit);
		}
	};

	private readonly isPdf = (filename: string): boolean => {
		return !!filename.match(/\.pdf/i);
	};

	private checkValidFileName = (files: FileList | File[]): boolean => {
		let isValid = true;
		for (let i = 0; i < files.length; i++) {
			const name = files[i].name;
			if (!name.match(this.supportedFiles)) {
				isValid = false;
				break;
			}
		}
		return isValid;
	};

	private isFileTooLarge(files: FileList): boolean {
		let isValid = false;
		for (let i = 0; i < files.length; i++) {
			const fileSizeInMB = files[i].size / 1024 / 1024;
			if (fileSizeInMB >= 100) {
				isValid = true;
				break;
			}
		}
		return isValid;
	}
}
