<div class="container">
	<div class="forms-submenu">
		<div
			*ngIf="isFuncionalityAvailable(functionality.DECISION)"
			(click)="goToForm.emit(formScreens.DECISION)"
			class="form-submenu-item"
			[ngClass]="{
				'form-submenu-selected': selectedScreen === formScreens.DECISION
			}"
		>
			<div *ngIf="notification(notificationTitle.DECISION)" class="item-notification"></div>
			<div class="submenu-icon decision-icon"></div>
			<span>
				{{ text().DECISION_LOG_SHORT }}
			</span>
		</div>

		<div
			*ngIf="!isInHistoric() && isFuncionalityAvailable(functionality.EF)"
			(click)="evacuationFormHandler()"
			class="form-submenu-item"
			[ngClass]="{
				'form-submenu-selected': selectedScreen === formScreens.FSG
			}"
		>
			<div *ngIf="notification(notificationTitle.FSG)" class="item-notification"></div>
			<div class="submenu-icon ef-icon"></div>
			<span>{{ text().EVACUATION_FORM_SHORT }}</span>
			<img *ngIf="isInFsgScreen()" class="chevron-right" />
		</div>

		<div
			*ngIf="!isInHistoric() && forms.length > 0"
			(click)="jesipSubMenuOpen = !jesipSubMenuOpen"
			class="form-submenu-item"
			[ngClass]="{ 'form-submenu-selected': jesipSubMenuOpen }"
		>
			<div class="submenu-icon jesip-icon"></div>
			<span>JESIP</span>
			<img
				src="resources/img/jesip/side-menu-up-down-caret.svg"
				alt="Caret"
				class="submenu-caret"
				[ngClass]="{ 'jesip-submenu-open': jesipSubMenuOpen }"
			/>
		</div>
	</div>

	<div *ngIf="jesipSubMenuOpen" class="jesip-submenu">
		<div
			*ngFor="let form of forms"
			(click)="goToForm.emit(form.type)"
			class="jesip-submenu-item"
			[ngClass]="{
				disabled: isDisable(form.type),
				'jesip-submenu-selected': selectedScreen === form.type
			}"
		>
			<span>{{ form.title }}</span>
			<p
				*ngIf="form.notificationTitle && notification(form.notificationTitle!)"
				class="jesip-submenu-item-notification"
			></p>
		</div>
	</div>
</div>
