<div class="container">
	<div class="header">
		<div class="date-col">
			<span class="title">{{ text().DATE }}</span>
			<div
				class="sort-arrow
            {{
					currentSort === sortOption.DATE
						? currentOrder === sortOrder.DESCENDENT
							? 'desc'
							: 'asc'
						: ''
				}}"
				(click)="
					orderList(
						currentOrder === sortOrder.DESCENDENT
							? sortOrder.ASCENDENT
							: sortOrder.DESCENDENT,
						sortOption.DATE
					)
				"
			></div>
		</div>
		<div *ngIf="dlType" class="type-col">
			<span class="title">{{ text().TYPE }}</span>
			<div
				#funnel
				class="funnel"
				(click)="openFilter(); $event.stopPropagation()"
				[ngClass]="'funnel ' + isFilterApplied()"
			></div>
			<app-text-filter
				appDetectClickOut
				(callback)="doneModal()"
				[hidden]="!showFilter"
				[filters]="filters"
				(applyFilterEmit)="updatefilterDecisions($event)"
				(doneModalEmit)="doneModal()"
			>
			</app-text-filter>
		</div>
		<div class="content-col">
			<span class="title">{{ text().CONTENT }}</span>
		</div>
		<div class="rationale-col">
			<span class="title">{{ text().RATIONALE }}</span>
		</div>
		<div *ngIf="dlOperational" class="ops-col">
			<span class="title">Tac.</span>
			<div
				class="sort-arrow
			{{
					currentSort === sortOption.OPERATION
						? currentOrder === sortOrder.DESCENDENT
							? 'desc'
							: 'asc'
						: ''
				}}"
				(click)="
					orderList(
						currentOrder === sortOrder.DESCENDENT
							? sortOrder.ASCENDENT
							: sortOrder.DESCENDENT,
						sortOption.OPERATION
					)
				"
			></div>
		</div>
		<div *ngIf="dlOperational" class="state-col">
			<span class="title">St.</span>
			<div
				class="sort-arrow
			{{
					currentSort === sortOption.STATE
						? currentOrder === sortOrder.DESCENDENT
							? 'desc'
							: 'asc'
						: ''
				}}"
				(click)="
					orderList(
						currentOrder === sortOrder.DESCENDENT
							? sortOrder.ASCENDENT
							: sortOrder.DESCENDENT,
						sortOption.STATE
					)
				"
			></div>
		</div>
		<div *ngIf="dlSignature" class="signed-col">
			<span class="title">{{ text().SIGNED }}</span>
			<div
				class="sort-arrow
			{{
					currentSort === sortOption.SIGNED
						? currentOrder === sortOrder.DESCENDENT
							? 'desc'
							: 'asc'
						: ''
				}}"
				(click)="
					orderList(
						currentOrder === sortOrder.DESCENDENT
							? sortOrder.ASCENDENT
							: sortOrder.DESCENDENT,
						sortOption.SIGNED
					)
				"
			></div>
		</div>
		<div class="logged-col">
			<span class="title">{{ text().LOGGED }}</span>
			<div
				class="sort-arrow
			{{
					currentSort === sortOption.LOGGED
						? currentOrder === sortOrder.DESCENDENT
							? 'desc'
							: 'asc'
						: ''
				}}"
				(click)="
					orderList(
						currentOrder === sortOrder.DESCENDENT
							? sortOrder.ASCENDENT
							: sortOrder.DESCENDENT,
						sortOption.LOGGED
					)
				"
			></div>
		</div>
		<div class="media-col">
			<span class="title">Media</span>
		</div>
	</div>
	<div class="body">
		<div
			*ngFor="let decision of items | filter : filterDecisions; let i = index"
			[id]="'decision_' + i"
			class="item"
			[class.expanded]="expand[i]"
		>
			<div class="date-col {{ isIncidentClosed() ? 'disabled' : '' }}">
				{{ getDecisionDate(decision) }}
			</div>
			<div *ngIf="dlType" class="type-col {{ isIncidentClosed() ? 'disabled' : '' }}">
				{{ getLocalizedType(decision.type) }}
			</div>
			<div class="content-col">
				<button
					*ngIf="hasAudio(decision)"
					class="log-audio-button"
					(click)="playAudio(decision, audioTypes.CONTENT)"
				>
					<img src="resources/img/016-megaphone.svg" />
				</button>
				<div *ngIf="checkAudioPlaying(decision, audioTypes.CONTENT)" class="audio-player">
					<div
						class="icon-container"
						(click)="audioPlaying.paused ? audioPlaying.play() : audioPlaying.pause()"
					>
						<div *ngIf="!audioPlaying?.paused" class="pause-icon">
							<span></span>
							<span></span>
						</div>
						<div *ngIf="audioPlaying?.paused" class="play-icon">
							<div></div>
						</div>
					</div>
					<div class="bar-container">
						<span class="bar"></span>
						<div #ball class="ball"></div>
					</div>
				</div>

				<textarea
					[id]="'contentTextarea_' + i"
					[rows]="hasAudio(decision) ? 3 : 4"
					class="content-textarea"
					[readOnly]="true"
					[class.expanded]="expand[i]"
					>{{ decision.description ? decision.description : "-" }}</textarea
				>
				<button
					data-add-action-name="expand-button-content"
					*ngIf="decision.description && showButton('content', i)"
					class="expand"
					(click)="toggleExpand(i)"
				>
					{{ expand[i] ? text().HIDE : text().SEE_ALL }}
				</button>
			</div>
			<div class="rationale-col" [class.disabled]="isIncidentClosed()">
				<textarea
					[id]="'rationaleTextarea_' + i"
					rows="4"
					[readOnly]="true"
					[class.expanded]="expand[i]"
					>{{ decision.result ? decision.result : "-" }}</textarea
				>
				<button
					data-add-action-name="expand-button-rationale"
					*ngIf="decision.result && showButton('rationale', i)"
					class="expand"
					(click)="toggleExpand(i)"
				>
					{{ expand[i] ? text().HIDE : text().SEE_ALL }}
				</button>
			</div>
			<div *ngIf="dlOperational" class="ops-col {{ isIncidentClosed() ? 'disabled' : '' }}">
				{{ getLocalizedTactics(decision) }}
			</div>
			<div *ngIf="dlOperational" class="state-col {{ isIncidentClosed() ? 'disabled' : '' }}">
				{{ getLocalizedState(decision) }}
			</div>
			<div
				*ngIf="dlSignature"
				class="signed-col no-text-transform {{ isIncidentClosed() ? 'disabled' : '' }}"
			>
				{{
					getSigningUser(decision)?.name === undefined
						? "-"
						: getSigningUser(decision)?.name
				}}
			</div>
			<div class="logged-col no-text-transform {{ isIncidentClosed() ? 'disabled' : '' }}">
				{{ decision.name }}
			</div>
			<div class="media-col">
				<div class="thumbnail-wrapper" data-cy="csThumbnailWrapper">
					<div
						*ngFor="let file of getDecisionFiles(i) | slice : 0 : 2; let i = index"
						[attr.data-cy]="'csMediaItem-' + i"
						class="media-item media-{{ i + 1 }}"
						[class.cropped]="i >= 1"
					>
						<div
							data-add-action-name="cs-open-file"
							*ngIf="!file.isImage()"
							(click)="openFile(file)"
						>
							<div class="thumbnail">
								<img src="{{ isAudioOrVideo(file) }}" width="12" height="12" />
							</div>
						</div>
						<div *ngIf="file.isImage()">
							<img
								data-add-action-name="cs-open-image-inspector"
								appIrisImage
								[id]="file.id_file"
								width="{{ i === 0 ? '32' : '27' }}"
								height="32"
								(click)="openImageInspector(file, i)"
								class="image"
							/>
						</div>
					</div>
					<button
						data-add-action-name="cs-more-button"
						*ngIf="getDecisionFiles(i)!.length > 2"
						(click)="openMoreMedia(decision, i)"
						class="more-button"
					>
						<span>...</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<app-image-viewer
	*ngIf="inspectedImage !== -1 && imageArrays[inspectedImage].length > 0"
	[images]="imageArrays[inspectedImage]"
	(cancelCb)="closeImageInspector()"
></app-image-viewer>
<app-loading-dialog
	*ngIf="dialogs.loading"
	[downloadingVideo]="true"
	[headerText]="text().DOWNLOADING_VIDEO"
	[bodyText]="text().PLAYBACK_ISSUE"
	[infoText]="dialogs.loading"
>
</app-loading-dialog>
<app-task-completed-dialog
	*ngIf="dialogs.taskCompleted"
	[headerText]="text().DOWNLOADED_VIDEO"
	[bodyText]="text().COMPLETED"
	[infoText]="text().PLAYBACK_INFO"
	(closeCallback)="dialogs.taskCompleted = false"
></app-task-completed-dialog>
