import { Component, Input } from "@angular/core";
import { Event } from "src/app/dto/items/event";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-event-header",
	templateUrl: "event-header.component.html",
	styleUrls: ["../../document.css"]
})
export class EventHeaderComponent {
	@Input() event?: Event;

	public readonly text: () => LocaleMap;

	constructor(tp: TextProvider) {
		this.text = tp.getStringMap;
	}
}
