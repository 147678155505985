import { MapOverlay } from "src/app/incident/map/components/map-overlay/map-overlay.interface";
import { MAP_ITEM_TYPE } from "src/app/global/constants/enums/map-item-type";
import { Floor } from "../map/floor";
import { DTO } from "../net/dto";
import { MapItem } from "./map-item";

export class Overlay extends MapItem implements DTO {
	id_overlay: number;
	id_img: number;
	sw_lat: number;
	sw_lon: number;
	ne_lat: number;
	ne_lon: number;
	rotation: number;
	datatype!: OVERLAY_TYPE;
	currentFloor!: number;
	hasFloors: boolean; // only used to check whether to ask backend for updates, for local comparisons use withFloors method
	timestamp_ms: number;
	blob: Blob | undefined;
	fileImg: string | undefined;
	floors: Array<Floor>;
	visible: boolean;
	relocating!: boolean;

	__mapComponent: MapOverlay | undefined;
	__floorsLoaded = false;

	timestamp = Date.now();
	constructor(
		id_overlay: number,
		id_img: number,
		sw_lat: number,
		sw_lon: number,
		ne_lat: number,
		ne_lon: number,
		rotation: number,
		datatype: string | OVERLAY_TYPE,
		id_mission: number,
		name: string,
		description?: string,
		hasFloors?: boolean,
		timestamp_ms?: number,
		blob?: Blob | null,
		floors?: Array<Floor>
	) {
		super(id_overlay, name, id_mission, description, MAP_ITEM_TYPE.OVERLAY);
		this.id_overlay = id_overlay ? id_overlay : -1;
		this.id_img = id_img ? id_img : -1;
		this.sw_lat = sw_lat;
		this.sw_lon = sw_lon;
		this.ne_lat = ne_lat;
		this.ne_lon = ne_lon;
		this.rotation = rotation; // in degrees
		if (typeof datatype === "string" && datatype !== "drawing" && datatype !== "schematic") {
			console.warn("Overlay object initialized with invalid datatype");
		} else this.datatype = datatype === "drawing" ? OVERLAY_TYPE.DRAWING : datatype === "schematic" ? OVERLAY_TYPE.SCHEMATIC : datatype;
		this.id_mission = id_mission;
		this.name = name;
		this.description = description ? description : "";
		this.hasFloors = hasFloors ? hasFloors : false;
		this.timestamp_ms = timestamp_ms ? timestamp_ms : Date.now();
		this.blob = blob ? blob : undefined; // contains the actual image in a Blob, used for drawings made without connection
		this.floors = floors ? floors : [];
		this.visible = true;
	}

	public static readonly fromJson: (json: string) => Overlay = (json: string) => {
		const jc = JSON.parse(json);
		return new Overlay(jc.id_overlay ? jc.id_overlay : jc.id_plane, jc.id_img, jc.sw_lat, jc.sw_lon, jc.ne_lat, jc.ne_lon, jc.rotation, jc.datatype, jc.id_mission, jc.name, jc.description, jc.hasFloors, jc.timestamp_ms, null);
	};

	public readonly getJson: () => string = () => {
		const asdf: any = { ...this };
		asdf.id_plane = this.id_overlay; // assuming backend still uses id_plane
		return JSON.stringify(asdf, ["id_plane", "id_img", "sw_lat", "sw_lon", "ne_lat", "ne_lon", "rotation", "datatype", "id_mission", "name", "description", "hasFloors", "timestamp_ms"]);
	};

	public readonly withFloors: () => boolean = () => {
		return this.floors !== undefined && this.floors.length > 0;
	};

	public readonly changeImage: (msgData: any, data: any) => void = (msgData, data) => {
		//// Code
		/* 		if (data && !data.id_img && (!data.tag_plane || data.tag_plane === contr.planeObject.randomTag)) {
			let img = new Image;
			img.onload = () => {
					if (data.tag_plane) contr.planeObject.id_img = data.id_image;
					contr.planeOverlay && contr.planeOverlay.changeImage(img)
			};
			loadImgSrc(data.id_image).then(function(li){ if(li) img.src = li; });
	}
	else { // just changing floor
			let img = new Image;
			img.onload = () => {
					contr.planeOverlay && contr.planeOverlay.changeImage(img)
			};
			loadImgSrc(data.id_img).then(function(li){ if(li) img.src = li; });
	}*/
	};
}
export enum OVERLAY_TYPE {
	DRAWING = "drawing",
	SCHEMATIC = "schematic"
}
