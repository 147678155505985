import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import { Overlay } from "src/app/dto/items/overlay";
import { EventsMissionsService } from "src/app/incident/event-mission.service";

@Component({
	selector: "app-blue-canvas-component",
	templateUrl: "blue-canvas.component.html",
	styleUrls: ["canvas.css"]
})
export class BlueSignatureCanvasComponent implements AfterViewInit {
	@Input() placeholderText: string = "";
	@Input() label: string = "";

	@Output() confirm = new EventEmitter<File>();
	@Output() cancel = new EventEmitter<void>();

	@ViewChild("canvasRef") canvasRef!: ElementRef;

	public focus: boolean = false;
	public colorSelectOpen: boolean = false;
	public eraserSelectOpen: boolean = false;
	public textSelectOpen: boolean = false;
	public textBoxOpen: boolean = false;
	public drawing: boolean = false;
	public drawn: boolean = false;
	public showImageDialog: boolean = false;
	public inspectImage: boolean = false;

	public isSomethingDrawnOut: boolean = false;

	public thickness: number = THICKNESS.THIN;
	public eraserThickness: number = ERASER_THICKNESS.THIN;
	public color: string = DEFAULT_COLORS.WHITE;
	public textSize: TEXT_SIZE | undefined;
	public textValue: string = "";
	public imageSrc: SafeResourceUrl | undefined;
	public drawings: Array<Overlay> = [];

	public width: number = 0;
	public height: number = 0;
	public currX: number = 0;
	public currY: number = 0;
	public prevX: number = 0;
	public prevY: number = 0;
	public imageW: number = 0;
	public imageH: number = 0;
	public imageX: number = 0;
	public imageY: number = 0;

	public currentMode: MODE = MODE.NORMAL;

	public readonly modes = MODE;
	public readonly thicknesses = THICKNESS;
	public readonly colors = DEFAULT_COLORS;
	public readonly fonts = TEXT_SIZE;
	public readonly eraserThicknesses = ERASER_THICKNESS;

	public readonly images = new Map<number, SafeResourceUrl>();
	public inspectedImage: string | undefined;

	private oldImageDatas = new Array<ImageData>();
	private redoValues = new Array<ImageData>();
	private canvas!: HTMLCanvasElement;

	private readonly ems: EventsMissionsService;
	private readonly wreq: WebRequestFactory;
	private readonly sanitizer: DomSanitizer;
	constructor(ems: EventsMissionsService, wreq: WebRequestFactory, san: DomSanitizer) {
		this.ems = ems;
		this.wreq = wreq;
		this.sanitizer = san;
	}

	ngAfterViewInit(): void {
		this.canvas = this.canvasRef.nativeElement;
		this.setCanvasDimensions();
		this.selectMode(MODE.NORMAL);
		this.color = "white";
	}

	public readonly isFilled: Function = () => {
		return this.drawing;
	};

	public readonly setFocus: Function = (evt: MouseEvent) => {
		this.focus = true;
		evt.stopPropagation();
	};

	public readonly unsetFocus: Function = () => {
		this.focus = false;
	};

	public readonly isFocus: () => boolean = () => {
		return this.focus;
	};

	public readonly setCanvasDimensions: Function = () => {
		this.canvas.width = this.canvas.offsetWidth - 4;
		this.canvas.height = this.canvas.offsetHeight - 4;
		this.width = this.canvas.width;
		this.height = this.canvas.height;
	};

	/** TEMPLATE INTERFACE */
	public readonly canvasMousedown: (evt: MouseEvent) => void = (evt) => {
		switch (this.currentMode) {
			case MODE.TEXT:
				this.textCanvasAction(evt.offsetX, evt.offsetY);
				break;
			case MODE.UPLOAD:
				break;
			default:
				this.canvasInteraction(evt.offsetX, evt.offsetY);
				break;
		}
	};

	public readonly canvasTouchdown: (evt: TouchEvent) => void = (evt) => {
		switch (this.currentMode) {
			case MODE.TEXT:
				this.textCanvasAction(evt.touches[0].clientX, evt.touches[0].clientY);
				break;
			case MODE.UPLOAD:
				break;
			default:
				const x = evt.touches[0].clientX - this.canvas.getBoundingClientRect().x;
				const y = evt.touches[0].clientY - this.canvas.getBoundingClientRect().y;
				this.canvasInteraction(x, y);
				break;
		}
	};

	public readonly canvasMousemove: (evt: MouseEvent) => void = (evt) => {
		const x = evt.offsetX;
		const y = evt.offsetY;
		this.canvasMovement(x, y);
	};

	public readonly canvasTouchmove: (evt: TouchEvent) => void = (evt) => {
		evt.cancelable && evt.preventDefault(); // Prevent scrolling.
		const x = evt.touches[0].clientX - this.canvas.getBoundingClientRect().x;
		const y = evt.touches[0].clientY - this.canvas.getBoundingClientRect().y;
		this.canvasMovement(x, y);
	};

	public readonly canvasMouseup: (evt: MouseEvent) => void = (evt) => {
		this.canvasUp();
	};

	public readonly canvasBlur: (evt: Event) => void = (evt) => {
		this.drawing = false;
	};

	public readonly canvasTouchup: (evt: TouchEvent) => void = (evt) => {
		this.canvasUp();
	};

	public readonly imageMousedown: (evt: MouseEvent) => void = (evt) => {
		this.imageInteraction(evt.clientX, evt.clientY);
		evt.preventDefault();
	};

	public readonly imageTouchdown: (evt: TouchEvent) => void = (evt) => {
		this.imageInteraction(evt.touches[0].clientX, evt.touches[0].clientY);
		evt.preventDefault();
	};

	public readonly imageCornerMousedown: (evt: MouseEvent) => void = (evt) => {
		this.imageCornerInteraction(evt.clientX, evt.clientY);
		evt.preventDefault();
		evt.stopPropagation();
	};

	public readonly imageCornerTouchdown: (evt: TouchEvent) => void = (evt) => {
		this.imageCornerInteraction(evt.touches[0].clientX, evt.touches[0].clientY);
		evt.preventDefault();
		evt.stopPropagation();
	};

	/** TEMPLATE INTERFACE END */
	/** BUTTON EVENTS */

	public selectMode: (mode: MODE) => void = (mode) => {
		this.currentMode = this.currentMode === mode ? MODE.NORMAL : mode;
		this.colorSelectOpen = this.eraserSelectOpen = this.textSelectOpen = false;
		if (this.currentMode !== MODE.TEXT) {
			this.textBoxOpen = false;
			this.textValue = "";
		}
		if (this.currentMode !== MODE.UPLOAD) {
			this.showImageDialog = false;
		}
	};

	public readonly selectThickness: (thickness: THICKNESS) => void = (thickness) => {
		this.thickness = thickness;
	};

	public readonly openColorMenu: Function = () => {
		this.colorSelectOpen = !this.colorSelectOpen;
	};

	public readonly selectColor: (color: string) => void = (color) => {
		this.color = color;
	};

	public readonly openTextMenu: Function = () => {
		this.textSelectOpen = !this.textSelectOpen;
	};

	public readonly selectText: (size: TEXT_SIZE) => void = (font) => {
		this.textSize = font;
	};

	public readonly getTextInputPosition: () => { top: number; left: number } = () => {
		return {
			top: this.currY,
			left: this.currX
		};
	};

	public readonly saveCanvasText: Function = () => {
		const ctx = this.canvas.getContext("2d")!;
		ctx.font = this.textSize + "px Arial";
		ctx.fillStyle = this.color;
		ctx.fillText(this.textValue, this.currX, this.currY + this.textSize! - 3);
		this.selectMode(MODE.NORMAL);
	};

	public readonly openEraserMenu: Function = () => {
		this.eraserSelectOpen = !this.eraserSelectOpen;
	};

	public readonly selectEraserThickness: (thickness: ERASER_THICKNESS) => void = (thickness) => {
		this.eraserThickness = thickness;
	};

	public readonly undoDraw: Function = () => {
		const ctx = this.canvas.getContext("2d")!;
		if (this.oldImageDatas.length < 1) return;
		this.redoValues.push(ctx.getImageData(0, 0, this.width, this.height));
		ctx.putImageData(this.oldImageDatas.pop()!, 0, 0);
	};

	public readonly redoDraw: Function = () => {
		const ctx = this.canvas.getContext("2d")!;
		if (this.redoValues.length < 1) return;
		this.oldImageDatas.push(ctx.getImageData(0, 0, this.width, this.height));
		ctx.putImageData(this.redoValues.pop()!, 0, 0);
	};

	public readonly eraseAllDraw: Function = () => {
		if (!this.drawn) return;
		this.drawn = false;
		this.isSomethingDrawnOut = false;
		const ctx = this.canvas.getContext("2d")!;
		this.oldImageDatas.push(ctx.getImageData(0, 0, this.width, this.height));
		ctx.clearRect(0, 0, this.width, this.height);
	};

	public readonly openImageDialog: Function = () => {
		this.currentMode = this.currentMode === this.modes.UPLOAD ? MODE.NORMAL : MODE.UPLOAD;
		this.showImageDialog = !this.showImageDialog;
	};

	public readonly loadImage: (id_img: number) => void = async (id) => {
		this.imageW = this.width / 5;
		this.imageH = this.height / 5;
		this.imageX = this.width / 2 - this.imageW / 2;
		this.imageY = this.height / 2 - this.imageH / 2;
		this.imageSrc = await this.getSanitizedUrl(id);
		this.selectMode(MODE.NORMAL);
	};

	public readonly imageSetupConfirm: Function = () => {};

	public readonly imageSetupCancel: Function = () => {
		this.imageSrc = undefined;
	};

	public readonly mainCancel: Function = () => {
		this.cancel.emit();
	};

	public readonly mainSave: Function = (callback: (file: File) => any) => {
		if (!this.canvas || !this.canvas.toBlob) return false;
		this.canvas.toBlob((blob: Blob | null) => {
			if (!blob) return;
			const file = new File([blob], "signature-" + this.ems.getCurrentMission()?.id + "-" + Number.parseFloat(Math.random().toFixed(3)) * 1000 + ".png", blob);
			if (callback) callback(file);
		});
		return true;
	};

	public readonly closeImageInspector: Function = () => {
		this.inspectImage = false;
	};

	/** IMAGE INTERACTION */

	public readonly imageInteraction: (x: number, y: number) => void = (x, y) => {
		this.prevX = x;
		this.prevY = y;
		document.body.style.cursor = "grabbing";
		document.body.addEventListener("mousemove", this.imageDrag as any);
		document.body.addEventListener("touchmove", this.imageDrag as any);
		document.body.addEventListener("mouseup", this.imageStopInteraction as any);
		document.body.addEventListener("touchend", this.imageStopInteraction as any);
	};

	public readonly imageDrag: (evt: MouseEvent | TouchEvent) => void = (evt) => {
		let x: number, y: number;
		if (evt instanceof MouseEvent) {
			x = evt.clientX;
			y = evt.clientY;
		}
		if (evt instanceof TouchEvent) {
			x = evt.touches[0].clientX;
			y = evt.touches[0].clientY;
		}
		let deltaX = x! - this.prevX;
		let deltaY = y! - this.prevY;
		this.imageX += deltaX;
		this.imageY += deltaY;
		this.prevX = x!;
		this.prevY = y!;
	};

	public readonly imageStopInteraction: Function = () => {
		document.body.removeEventListener("mousemove", this.imageDrag as any);
		document.body.removeEventListener("touchmove", this.imageDrag as any);
		document.body.removeEventListener("mouseup", this.imageStopInteraction as any);
		document.body.removeEventListener("touchend", this.imageStopInteraction as any);
		document.body.style.cursor = "auto";
	};

	public readonly imageCornerInteraction: (x: number, y: number) => void = (x, y) => {
		this.prevX = x;
		this.prevY = y;
		document.body.style.cursor = "nwse-resize";
		document.body.addEventListener("mousemove", this.imageCornerDrag as any);
		document.body.addEventListener("touchmove", this.imageCornerDrag as any);
		document.body.addEventListener("mouseup", this.imageCornerStopInteraction as any);
		document.body.addEventListener("touchend", this.imageCornerStopInteraction as any);
	};

	public readonly imageCornerDrag: (evt: MouseEvent | TouchEvent) => void = (evt) => {
		let x: number, y: number;
		if (evt instanceof MouseEvent) {
			x = evt.clientX;
			y = evt.clientY;
		}
		if (evt instanceof TouchEvent) {
			x = evt.touches[0].clientX;
			y = evt.touches[0].clientY;
		}
		let deltaX = x! - this.prevX;
		let deltaY = y! - this.prevY;
		this.imageW += deltaX;
		this.imageH += deltaY;
		this.prevX = x!;
		this.prevY = y!;
	};

	public readonly imageCornerStopInteraction: Function = () => {
		document.body.removeEventListener("mousemove", this.imageCornerDrag as any);
		document.body.removeEventListener("touchmove", this.imageCornerDrag as any);
		document.body.removeEventListener("mouseup", this.imageCornerStopInteraction as any);
		document.body.removeEventListener("touchend", this.imageCornerStopInteraction as any);
		document.body.style.cursor = "auto";
	};

	/** AUXILIAR */

	public clearCircle(x: number, y: number, radius: number): void {
		const context = this.canvas.getContext("2d")!;
		context.save();
		context.beginPath();
		context.arc(x, y, radius, 0, 2 * Math.PI, true);
		context.clip();
		context.clearRect(x - radius, y - radius, radius * 2, radius * 2);
		context.restore();
	}
	public clearLineRounded(x1: number, y1: number, x2: number, y2: number, thickness: number): void {
		const context = this.canvas.getContext("2d")!;
		var tmp,
			half_thickness = thickness / 2,
			length,
			PI15 = 1.5 * Math.PI,
			PI05 = 0.5 * Math.PI;

		// swap coordinate pairs if x-coordinates are RTL to make them LTR
		if (x2 < x1) {
			tmp = x1;
			x1 = x2;
			x2 = tmp;
			tmp = y1;
			y1 = y2;
			y2 = tmp;
		}

		length = this.dist(x1, y1, x2, y2);

		context.save();
		context.translate(x1, y1);
		context.rotate(Math.atan2(y2 - y1, x2 - x1));
		x1 = 0;
		y1 = 0;
		x2 = length - 1;
		y2 = 0;
		// draw a complex "line" shape with rounded corner caps

		context.moveTo(x1, y1 - half_thickness);
		context.lineTo(x2, y2 - half_thickness);
		context.arc(x2, y2, half_thickness, PI15, PI05, false);
		context.lineTo(x1, y1 - half_thickness + thickness);
		context.arc(x1, y1, half_thickness, PI05, PI15, false);
		context.closePath();
		x1 -= half_thickness;
		y1 -= half_thickness;

		context.clip();
		context.clearRect(x1, y1, length + thickness, thickness);
		context.restore();
	}
	public dist(x1: number, y1: number, x2: number, y2: number): number {
		x2 -= x1;
		y2 -= y1;
		return Math.sqrt(x2 * x2 + y2 * y2);
	}

	public readonly getDateFormat: (date?: Date, ms?: number) => string = (date?, ms?) => {
		let ts: Date = date ? date : ms ? new Date(ms) : new Date();
		let day = ts.getDay();
		let month = ts.getMonth() + 1;
		let monthStr = "";
		if (month < 10) monthStr = "0" + month;
		else monthStr = "" + month;
		let year = ts.getFullYear();
		let hours = ts.getHours();
		let minutes = ts.getMinutes();
		return day + "/" + monthStr + "/" + year + " " + hours + ":" + minutes;
	};

	public readonly getSanitizedUrl: (id: number) => SafeResourceUrl = async (id) => {
		const blob = await this.wreq.getFile(id);
		const url = URL.createObjectURL(blob);
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	};

	/** CANVAS INTERACTION */

	private readonly canvasInteraction: (x: number, y: number) => void = (x, y) => {
		if (this.currentMode !== MODE.ERASING) this.currentMode = MODE.NORMAL;
		const ctx = this.canvas.getContext("2d")!;
		this.colorSelectOpen = false;
		this.eraserSelectOpen = false;
		this.drawing = true;
		this.prevX = this.currX;
		this.prevY = this.currY;
		this.currX = x;
		this.currY = y;
		this.oldImageDatas.push(ctx.getImageData(0, 0, this.width, this.height));
		this.redoValues = [];
		this.drawing = true;
		this.isSomethingDrawnOut = true;
		this.currentMode === MODE.ERASING && this.clearCircle(this.currX, this.currY, this.eraserThickness / 2);
		if (this.currentMode === MODE.NORMAL) {
			ctx.beginPath();
			ctx.fillStyle = this.color;
			ctx.lineWidth = this.thickness;
			ctx.fillRect(this.currX, this.currY, 2, 2);
			ctx.closePath();
		}
	};

	private readonly textCanvasAction = (x: number, y: number): void => {};

	private readonly canvasMovement: (x: number, y: number) => void = (x, y) => {
		if (this.drawing) {
			this.drawn = true;
			this.isSomethingDrawnOut = true;
			this.prevX = this.currX;
			this.prevY = this.currY;
			this.currX = x - this.canvas.offsetLeft;
			this.currY = y - this.canvas.offsetTop;
			this.draw();
		}
	};

	private readonly canvasUp: () => void = () => {
		this.drawing = false;
	};

	private readonly draw: Function = () => {
		const ctx = this.canvas.getContext("2d")!;
		if (this.currentMode === MODE.ERASING) {
			this.clearLineRounded(this.prevX, this.prevY, this.currX, this.currY, this.eraserThickness);
		} else {
			ctx.beginPath();
			ctx.moveTo(this.prevX, this.prevY);
			ctx.lineTo(this.currX, this.currY);
			ctx.strokeStyle = this.color;
			ctx.lineWidth = this.thickness;
			ctx.stroke();
			ctx.closePath();
		}
	};
}

enum MODE {
	NORMAL,
	ERASING,
	THICKNESS,
	TEXT,
	UPLOAD
}
enum THICKNESS {
	THIN = 2,
	MEDIUM = 4,
	THICK = 6
}
enum DEFAULT_COLORS {
	WHITE = "white",
	RED = "#FF0F0F",
	GREEN = "#0C9015",
	BLUE = "#6CAAD0"
}
enum TEXT_SIZE {
	SMALL = 27,
	MEDIUM = 36,
	BIG = 42
}
enum ERASER_THICKNESS {
	THIN = 10,
	MEDIUM = 18,
	THICK = 26
}
