import { Area } from "./area";
import { WGSPoint } from "../map/location";
import { DTO } from "../net/dto";
import { SHAPE_TYPE } from "./shape-type";

export class PolygonArea extends Area implements DTO {
	shapeType: string = SHAPE_TYPE.POLYGON;
	vertices: Array<WGSPoint>;

	timestamp = Date.now();
	public constructor(id_mission: number, id: number, type: number, vertices_array: Array<WGSPoint>, name: string, description?: string, deleted?: boolean, attachment_id?: number, attachment_filename?: string, closed?: boolean, timestamp?: number) {
		super(id_mission, id, type, name, closed ? SHAPE_TYPE.POLYGON : SHAPE_TYPE.POLYLINE, description ? description : "", deleted ? deleted : false, attachment_id ? attachment_id : -1, attachment_filename ? attachment_filename : "", closed ? closed : false, timestamp ? timestamp : Date.now());

		if (vertices_array[0] && (vertices_array[0] as any).lat) {
			this.vertices = vertices_array.map((e) => new WGSPoint((e as any).lat, (e as any).lng));
		} else this.vertices = vertices_array;

		if (!closed) this.shapeType = SHAPE_TYPE.POLYLINE;
	}

	public static readonly fromJson: (json: string) => PolygonArea = (json: string) => {
		let ja: any = JSON.parse(json);
		return new PolygonArea(ja.id_mission ? ja.id_mission : ja.mission_id, ja.id, ja.type, ja.vertices, ja.name, ja.description, ja.deleted, ja.attachment_id, ja.attachment_filename, ja.closed, ja.timestamp);
	};

	public readonly getJson: Function = () => {
		this.vertices.forEach((vertice) => {
			// @ts-ignore
			vertice.lat = vertice.latitude;
			// @ts-ignore
			vertice.lng = vertice.longitude;
		});
		// @ts-ignore
		this.mission_id = this.id_mission;
		return JSON.stringify(this, ["mission_id", "id", "type", "vertices", "name", "description", "shapeType", "lat", "lng", "alt", "deleted", "attachment_filename", "attachment_id", "closed", "timestamp"]); //angular.toJson( this );
	};
}
