import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { Incident } from "src/app/dto/items/incident";
import { Personnel } from "src/app/dto/resources/personnel";
import { User } from "src/app/dto/user/user";
import { EventsMissionsService } from "src/app/incident/event-mission.service";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { ResourceService } from "src/app/settings/resource/resource.service";
import { UserService } from "src/app/settings/user/user.service";
import { SkillUserRelation } from "src/app/dto/user/skill-user-relation";
import { Resource } from "src/app/dto/resources/resource";
import { Station } from "src/app/dto/resources/station";

@Component({
	selector: "app-personnel-card-sso",
	templateUrl: "./personnel-card-sso.component.html",
	styleUrls: ["../resource-card.css"]
})
// If you modify any content of this component, please make sure this change is not required in the component PersonnelCardComponent
// otherwise kindly make the changes on both
export class PersonnelCardSsoComponent implements OnInit {
	@Input() personnel: Personnel = new Personnel(-1, -1, 0, 0, -1, -1, "");

	@Output() saveCb = new EventEmitter<Personnel>();

	@Output() assignHardwareCb = new EventEmitter<Personnel>();

	@Output() editing = new EventEmitter<boolean>();

	public text: () => LocaleMap;
	public incidents: Array<Incident> = [];
	public stations: Array<Station> = [];
	public open: boolean = false;
	public showSkillModal: boolean = false;

	private readonly ems: EventsMissionsService;
	private readonly res: ResourceService;
	private readonly userService: UserService;
	private readonly cdRef: ChangeDetectorRef;

	constructor(userService: UserService, res: ResourceService, ems: EventsMissionsService, textProv: TextProvider, cdRef: ChangeDetectorRef) {
		this.text = textProv.getStringMap;
		this.ems = ems;
		this.res = res;
		this.userService = userService;
		this.cdRef = cdRef;
	}

	ngOnInit() {
		this.incidents.push(new Incident(-1, -1, this.text().NONE));
		this.incidents.push(...this.ems.Missions.filter((incident) => !incident.closed));

		this.stations.push(new Station(-1, this.text().UNSPECIFIED));
		this.stations.push(...this.res.Stations);
	}

	public save(): void {
		this.saveCb.emit(this.personnel);
	}

	public hasResourceBeenEdited(): boolean {
		const originalResource = this.res.Resources.find((e) => e.id === this.personnel.id);
		if (!originalResource) return true;
		const originalUser = this.userService.Users.find((e) => e.id_resource === this.personnel.id);
		if (!originalUser && (this.personnel.__user as User)) return true;
		else {
			if (!(this.personnel.__user as User))
				return (
					originalResource.name.toUpperCase() !== this.personnel.name.toUpperCase() ||
					originalResource.type !== this.personnel.type ||
					originalResource.color !== this.personnel.color ||
					originalResource.fixed_position !== this.personnel.fixed_position ||
					originalResource.station !== this.personnel.station ||
					originalResource.id_mission !== this.personnel.id_mission ||
					originalResource.description !== this.personnel.description ||
					originalResource.icon_path !== this.personnel.icon_path
				);
			else {
				originalResource.name.toUpperCase() === this.personnel.name.toUpperCase() && this.editing.emit(false);
				return (
					originalResource.name.toUpperCase() !== this.personnel.name.toUpperCase() ||
					originalResource.type !== this.personnel.type ||
					originalResource.color !== this.personnel.color ||
					originalResource.fixed_position !== this.personnel.fixed_position ||
					originalResource.station !== this.personnel.station ||
					originalResource.id_mission !== this.personnel.id_mission ||
					originalResource.description !== this.personnel.description ||
					originalResource.icon_path !== this.personnel.icon_path ||
					(this.personnel.__user as User).id_level !== originalUser!.id_level ||
					originalUser!.name !== (this.personnel.__user as User).name
				);
			}
		}
	};

	public editingPersonnel(): void {
		this.editing.emit(true);
	}

	public checkChanges: (check_string: string) => void = (check_string: string) => {
		if (check_string != (this.personnel.__user as User).name) {
			(this.personnel.__user as User).name = check_string;
		}
	};

	public readonly toggleGpsSwitch: Function = () => {
		this.personnel.fixed_position = !this.personnel.fixed_position;
	};

	public readonly isResourceTracked: Function = () => {
		return !this.personnel.fixed_position;
	};

	public incidentDropdownGetMainText: (selected: any) => string = () => {
		const incident = this.ems.Missions.find((e) => e.id === this.personnel.id_mission);
		return incident ? incident.name : "";
	};

	public stationDropdownGetMainText: (selected: any) => string = () => {
		const station = this.stations.find((e) => e.name === this.personnel.station);
		return station ? station.name : "";
	};

	public incidentDropdownChangeCallback: Function = (incident: Incident) => {
		this.personnel.id_mission = incident.id;
	};

	public stationDropdownChangeCallback: Function = (station: Station) => {
		this.personnel.station = station.name;
	};

	public incidentDropdownGetOptionText: (option: any) => string = (incident: Incident) => {
		return incident.name;
	};

	public stationDropdownGetOptionText: (option: any) => string = (station: Station) => {
		return station.name;
	};

	public incidentDropdownCompareSelect: (selected: any, option: any) => boolean = (obj: Resource, incident: Incident) => {
		return obj.id_mission === incident.id;
	};

	public stationDropdownCompareSelect: (selected: any, option: any) => boolean = (obj: Resource, station: Station) => {
		return obj.station === station.name;
	};

	public readonly openSkillModal: Function = () => {
		this.showSkillModal = true;
	};

	public readonly closeSkillModal: Function = () => {
		this.showSkillModal = false;
	};

	public readonly setNewSkills = (skillAssig: Map<number, boolean>): void => {
		if (this.personnel.id !== -1) {
			for (let skill of this.res.Skills) {
				const idx = this.personnel.skills ? this.personnel.skills.findIndex((e) => e.id === skill.id) : -1;
				if (!skillAssig.get(skill.id)) {
					if (idx > -1) {
						this.res.removeSkillFromAgent(this.personnel.id, skill.id);
						this.personnel.skills?.splice(idx, 1);
					}
				} else {
					if (idx === -1) {
						this.res.addSkillToAgent(new SkillUserRelation(-1, skill.id, this.personnel.id));
						this.personnel.skills ? this.personnel.skills.push(skill) : (this.personnel.skills = [skill]);
					}
				}
			}
		}
		this.closeSkillModal();
		this.cdRef.detectChanges();
	};
}
