import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import { ResourceService } from "src/app/settings/resource/resource.service";
import { ConfigurationService } from "src/app/settings/types/configuration.service";
import { UserService } from "src/app/settings/user/user.service";
import { Incident } from "src/app/dto/items/incident";
import { Change, CHANGE_TYPE } from "src/app/dto/net/change";
import { EventsMissionsService } from "src/app/incident/event-mission.service";
import { AraService, CONTROL_MEASURE_UPDATES } from "src/app/incident/incident-tools/ara/ara.service";
import { CommandStructureService } from "src/app/incident/incident-tools/command-structure/command-structure.service";
import { DecisionLogService } from "src/app/incident/incident-tools/forms/decision-log/decision-log.service";
import { FsgService } from "src/app/incident/incident-tools/fsg/fsg.service";
import { MapItemsService } from "src/app/incident/map/map-items.service";
import { ConstantsService } from "../constants/constants.service";
import { LocaleMap } from "../constants/text/text-interface";
import { TextProvider } from "../constants/text/text-provider";
import { MESSAGE_TYPE } from "../messaging/messages";
import { MessagingService } from "../messaging/messaging.service";
import { BufferService } from "./buffer/buffer.service";
import { FormsService } from "src/app/incident/incident-tools/forms/forms.service";
import { LoginService } from "../../login/login.service";
import { FORM_SCREENS } from "src/app/incident/incident-tools/forms/screens.enum";
import { LOG_TYPE } from "../constants/enums/log_types";
import { CalloutService } from "src/app/incident/incident-tools/callout/callout-service";
import { DTOArray } from "src/app/dto/net/dto-array";

@Injectable({
	providedIn: "root"
})
export class AutomationService {
	public go = false;
	public liveMode = true;

	public readonly tick$ = new Subject<void>();
	public readonly loadPercentage$ = new Subject<number>();

	private readonly conf: ConfigurationService;
	private readonly cnst: ConstantsService;
	private readonly user: UserService;
	private readonly lgn: LoginService;
	private readonly resource: ResourceService;
	private readonly ems: EventsMissionsService;
	private readonly mis: MapItemsService;
	private readonly ara: AraService;
	private readonly fsg: FsgService;
	private readonly mssg: MessagingService;
	private readonly wreq: WebRequestFactory;
	private readonly mySocket: any;
	private readonly decisions: DecisionLogService;
	private readonly command: CommandStructureService;
	private readonly buffer: BufferService;
	private readonly jesip: FormsService;
	private readonly callout: CalloutService;

	private readonly text: () => LocaleMap;
	private lastChangeLocal = new Change(-1, 0, CHANGE_TYPE.UNSPECIFIED); //{ last_update_ms: 0, id: 0 };
	private updates: Array<Change> = [];
	private updateIntervalPromise: number | undefined;
	private syncIntervalPromise: number | undefined;
	private lastChange: Change | undefined;
	private updating = false;
	private mission: Incident | undefined;
	private initialLoading: boolean = false;

	/** variables for synchronization with other IRIS instances */
	private localChanges = new Array<Change>();
	private lastSyncTimestamp: number = Date.now();
	private readonly SYNCHRONIZATION_RATE_MS: number = 40000;

	constructor(
		conf: ConfigurationService,
		cnst: ConstantsService,
		user: UserService,
		lgn: LoginService,
		resource: ResourceService,
		ems: EventsMissionsService,
		mis: MapItemsService,
		ara: AraService,
		fsg: FsgService,
		mssg: MessagingService,
		wreq: WebRequestFactory,
		decisions: DecisionLogService,
		command: CommandStructureService,
		textProv: TextProvider,
		buff: BufferService,
		forms: FormsService,
		callout: CalloutService
	) {
		this.conf = conf;
		this.cnst = cnst;
		this.user = user;
		this.lgn = lgn;
		this.resource = resource;
		this.ems = ems;
		this.mis = mis;
		this.ara = ara;
		this.fsg = fsg;
		this.mssg = mssg;
		this.wreq = wreq;
		this.decisions = decisions;
		this.command = command;
		this.buffer = buff;
		this.jesip = forms;
		this.callout = callout;
		this.text = textProv.getStringMap;

		this.lgn.$loginFinished.subscribe((settings) => this.start(settings.fullLoad));
		mssg.registerListener(MESSAGE_TYPE.LOGOUT, this.stop, false, false);
		mssg.registerListener(MESSAGE_TYPE.LOAD_MISSION_PAGE, this.onMissionLoaded);
		mssg.registerListener(MESSAGE_TYPE.ON_ONLINE, this.fullLoad);
		mssg.registerListener(MESSAGE_TYPE.TRY_UPDATE, this.tryUpdate);
		this.ems.loadMission$.subscribe(() => this.mapLoad());
	}

	/**
	 * Gets all synchronized changes from the database after a certain timestamp
	 *
	 */
	public readonly getSyncChanges: Function = async () => {
		const syncChanges = await this.wreq.GetSyncChanges(new Change(-1, this.lastSyncTimestamp, CHANGE_TYPE.AREA));
		let syncc = new Array<Change>();
		syncc = DTOArray.UpdateFromIdlessJsonArray(syncc, syncChanges, Change);
		const updatesToDo = syncc.filter((e) => e.last_update > this.lastSyncTimestamp);
		if (updatesToDo.length) {
			this.lastSyncTimestamp = updatesToDo[updatesToDo.length - 1].last_update;
			if (
				syncc.find((change) => {
					return !this.localChanges.find((e) => e.datatype === change.datatype && e.id_data === change.id_data);
				})
			)
				await this.fullLoad();
		}
	};

	/**
	 * Tries to update. Requests last change timestamp, if greater than
	 * last request, requests an update again. Doesn't request anything if an update is in progress
	 * When on playback mode, continually updates.
	 * @method tryUpdate
	 */
	public readonly tryUpdate: Function = async (force: boolean) => {
		let changes = new Array<Change>();
		if (!this.updating && (this.liveMode || this.go)) {
			if (this.liveMode) {
				if (force === true) {
					const lastChange_json = await this.wreq.GetLastChange(this.lastChangeLocal);
					if (lastChange_json && lastChange_json.length > 0) {
						const localChanges = changes.filter((e) => e.id !== -1);
						this.lastChange = localChanges[localChanges.length - 1];
						this.setLastRequestFromDB(this.lastChange);
						return await this.update();
					}
					return true;
				}
				this.flagUpdate();
				const lastChange_json = await this.wreq.GetLastChange(this.lastChangeLocal);
				this.unflagUpdate();
				// Get last DB change
				if (lastChange_json && lastChange_json.length > 0 && (this.liveMode || this.go)) {
					// this.go might've changed while request was on flight
					DTOArray.UpdateFromJsonArray(changes, lastChange_json, Change);
					const localChanges = changes.filter((e) => e.id !== -1);
					this.lastChange = localChanges[localChanges.length - 1];
					// If it's after last request or ids don't match, update, otherwise it's no use
					if (this.lastChange!.last_update > this.lastChangeLocal.last_update || this.lastChange!.id !== this.lastChangeLocal.id) {
						this.setLastRequestFromDB(this.lastChange);
						return await this.update(lastChange_json);
					} else return true;
				}
			} else if (this.buffer.isBuffered() && this.buffer.isFilled()) {
				return await this.update(force);
			} else {
				return true;
			}
		} else return true;
	};

	public readonly pause: Function = () => {
		this.go = false;
		this.mssg.fire(MESSAGE_TYPE.PLAY_PAUSE_CHANGE, this.go);
	};

	public readonly unpause: Function = () => {
		this.go = true;
	};

	public readonly updateRecordingTimestamp: (ts: number) => void = (ts) => {
		this.wreq.setTimestamp(this.conf.getIsoDateFromMilliseconds(ts), ts);
		if (this.mission && ts > this.mission.end_time_ms) {
			// If it's finished, return to live and stop
			this.pause();
		}
	};

	public readonly update: Function = async (changes: Array<string>) => {
		if (!this.liveMode) {
			this.mission = this.ems.getCurrentMission();
			if (!this.mission) {
				this.setLiveMode();
				return true;
			}
			this.tick$.next();
		}

		//lastRequest_ms = conf.getTime();
		//lastChangeLocal.last_update_ms = conf.getTime();

		this.flagUpdate();

		//TODO: for buffered replay, configuration, users, levels, kmls and gmap already loaded at start

		if (!this.liveMode && this.buffer.isBuffered()) {
			await Promise.all([this.resource.loadBuffer(true), this.resource.loadMission(true), this.mis.load(true)]);
			return this.unflagUpdate();
		} else {
			let updatedUsers: boolean = false;
			let updatedConfiguration: boolean = false;
			let updatedDrones: boolean = false;
			let updatedKmls: boolean = false;
			let updatedMissions: boolean = false;
			let updatedMapItems: boolean = false;

			for (const change of changes) {
				this.wreq.logInformation(LOG_TYPE.CHANGE_RECV, change);
				const change_obj = Change.fromJson(change);
				this.localChanges.push(change_obj);
				if (change_obj && change_obj.datatype) {
					const data = change_obj.datatype.split(" ");
					switch (data[0]) {
						case "user":
							if (data.length > 1 && data[1] === "delete") {
								if (this.lgn.user.id === change_obj.id_data) this.stop();
								else this.deleteUser(change_obj.id_data);
							} else if (data.length > 1 && data[1] === "level") this.loadUser(null, true);
							else if (!updatedUsers) this.loadUser(change_obj.id_data, true);
							updatedUsers = true;
							break;
						case "configuration":
							if (!updatedConfiguration) await this.updateConf(change_obj.id_data);
							updatedConfiguration = true;
							if (data[1] && data[1] === "area") this.mssg.settingsUpdate$.next({ screen: "area", id: change_obj.id_data });
							if (data[1] && data[1] === "poi") this.mssg.settingsUpdate$.next({ screen: "poi", id: change_obj.id_data });
							if (data[1] && data[1] === "incident") this.mssg.settingsUpdate$.next({ screen: "incident", id: change_obj.id_data });
							break;
						case "resource":
							if (data.length > 1 && data[1] === "camera") this.updateCamera(change_obj.id_data);
							if (data.length > 1 && data[1] === "delete") this.deleteResource(change_obj.id_data);
							if (data.length > 1 && data[1] === "delete_camera") this.resource.cameraUpdate$.next({ id: change_obj.id_data, action: "delete" });
							if (data.length > 1 && data[1] === "state") this.updateState(change_obj.id_data);
							if (Date.length > 1 && data[1] === "delete_state") this.deleteState(change_obj.id_data);
							if (data.length === 1) change_obj.id_data ? await this.updateResource(change_obj.id_data) : await this.loadResources(true);
							break;
						case "drone":
							if (!updatedDrones) this.loadDrones(true);
							updatedDrones = true;
							break;
						case "kml":
							if (!updatedKmls) this.loadKMLs(true);
							updatedKmls = true;
							break;
						case "mission":
							if (!updatedMissions) this.loadMissions(true);
							updatedMissions = true;
							break;
						case "map_item":
							if (data[1]) {
								switch (data[1]) {
									case "floor":
										this.updatePlane(change_obj.id_data);
										break;
									case "floor_delete":
										const plane = this.mis.Overlays.find((ol) => ol.floors.some((floor) => floor.id === change_obj.id_data))?.id;
										plane && this.updatePlane(plane);
										break;
								}
							} else if (!updatedMapItems) this.loadMapItems(true);
							updatedMapItems = true;
							break;
						case "map_area":
							change_obj.id_data && this.belongsToCurrentIncident(change_obj.id_mission) && this.updateArea(change_obj.id_data);
							break;
						case "map_poi":
							change_obj.id_data && (!change_obj.id_mission || change_obj.id_mission === -1 || this.belongsToCurrentIncident(change_obj.id_mission)) && this.updatePoi(change_obj.id_data);
							break;
						case "map_plane":
							if (data[1] && data[1] == "delete") {
								if (change_obj.id_data) {
									this.removePlane(change_obj.id_data);
								}
							} else if (change_obj.id_data) {
								this.updatePlane(change_obj.id_data);
							}
							break;
						case "ara":
							if (data[1]) {
								switch (data[1]) {
									case "ara":
										this.ara.checkAraUpdate(change_obj.id_data, change_obj.id_mission);
										break;
									case "risk_info":
										this.ara.getRiskInfo();
										break;
									case "cm":
										this.ara.getControlMeasureType(-1, CONTROL_MEASURE_UPDATES.CONTROL_MEASURE_ADD);
										break;
									case "scenario":
										this.ara.getScenario(change_obj.id_data);
										break;
								}
							}
							break;
						case "ics":
							if (data[1]) {
								switch (data[1]) {
									case "decision":
										this.belongsToCurrentIncident(change_obj.id_mission) && this.decisions.updateAndNotify(change_obj.id_data);
										break;
									case "commander":
										this.belongsToCurrentIncident(change_obj.id_mission) && this.updateCommander(change_obj.id_data);
										break;
									case "sector":
										if (data[2] && data[2] == "delete") this.belongsToCurrentIncident(change_obj.id_mission) && this.deleteSector(change_obj.id_data);
										else this.belongsToCurrentIncident(change_obj.id_mission) && this.updateSector(change_obj.id_data);
										break;
									case "support":
										if (data[2] && data[2] == "delete") this.belongsToCurrentIncident(change_obj.id_mission) && this.deleteSupport(change_obj.id_data);
										else this.belongsToCurrentIncident(change_obj.id_mission) && this.updateSupport(change_obj.id_data);
										break;
									case "resource":
										if (data[2] && data[2] == "delete") this.belongsToCurrentIncident(change_obj.id_mission) && this.deleteIcsResource(change_obj.id_data);
										else this.belongsToCurrentIncident(change_obj.id_mission) && this.updateIcsResource(change_obj.id_data);
										break;
									case "agent_log":
										this.belongsToCurrentIncident(change_obj.id_mission) && this.updateIcsCalloutLog();
										break;
									case "cm":
										this.ara.getControlMeasureType(-1, CONTROL_MEASURE_UPDATES.CONTROL_MEASURE_DELETE);
										break;
								}
							}
							break;
						case "appl_relation":
							if (data[1]) {
								switch (data[1]) {
									case "save":
										const idx = this.updates.findIndex((e) => e.datatype == "appliancerelation" && e.id == change_obj.id_data);
										if (idx == -1) this.updateNewAppRelation(change_obj.id_data);
										else this.updates.splice(idx, 1);
										break;
									case "remove":
										this.updateDeleteAppRelation(change_obj.id_data);
										break;
									case "purge":
										this.purgeAllPersonnelFromAppliance(change_obj.id_data);
										break;
								}
							}
							break;
						case "skill":
							if (data[1]) {
								switch (data[1]) {
									case "add":
										this.updateNewSkillRelation(change_obj.id_data);
										break;
									case "delete":
										this.updateDeleteSkillRelation(change_obj.id_data);
										break;
								}
							}
							break;
						case "fsg":
							if (data[1]) {
								switch (data[1]) {
									case "fsg":
										this.updateFsg(change_obj.id_data, change_obj.id_mission);
										break;
									case "fsg_activate":
										const idMissionChanged: boolean = true;
										this.updateFsg(change_obj.id_data, change_obj.id_mission, idMissionChanged);
										break;
									case "flat":
										this.updateFlat(change_obj.id_data, change_obj.id_mission);
										break;
								}
							}
							break;
						case "decisionfile":
							this.belongsToCurrentIncident(change_obj.id_mission) && this.decisions.getDecisionFileUpdate(change_obj.id_data);
							break;
						case "jesipMethane":
							this.jesip.updateForms(FORM_SCREENS.METHANE, change_obj.id_mission);
							break;
						case "jesipJdm":
							this.jesip.updateForms(FORM_SCREENS.JDM, change_obj.id_mission);
							break;
						case "jesipIimarch":
							this.jesip.updateForms(FORM_SCREENS.IIMARCH, change_obj.id_mission);
							break;
						case "jesipDebrief":
							this.jesip.updateForms(FORM_SCREENS.DEBRIEF, change_obj.id_mission);
							break;
						case "areaType":
							await this.conf.load();
							this.mssg.settingsUpdate$.next({ screen: "area", id: change_obj.id_data });
							break;
						case "poiType":
							await this.conf.load();
							this.mssg.settingsUpdate$.next({ screen: "poi", id: change_obj.id_data });
							break;
						case "eventType":
							await this.conf.load();
							this.mssg.settingsUpdate$.next({ screen: "incident", id: change_obj.id_data });
							break;
						default:
							this.fullLoad();
							break;
					}
				}
			}
			return this.unflagUpdate();
		}
	};

	private readonly flagUpdate = (): boolean => (this.updating = true);

	private readonly unflagUpdate = (): boolean => (this.updating = false);

	private readonly setLastRequestFromDB: Function = (lastChange: Change) => {
		this.lastChangeLocal.id = lastChange.id;
		this.lastChangeLocal.last_update = lastChange.last_update;
	};

	// called when logged into the application
	private readonly loginLoad: Function = async (fullLoad?: boolean) => {
		console.trace();
		this.initialLoading = true;
		const total = 6;
		fullLoad && this.loadUser(false);
		this.loadPercentage$.next(1 / total);
		await this.loadConfiguration(false);
		this.loadPercentage$.next(2 / total);
		await this.loadMissions(false);
		this.loadPercentage$.next(3 / total);
		await this.loadResources(false);
		this.loadPercentage$.next(4 / total);
		await this.loadDrones(false);
		this.loadPercentage$.next(5 / total);
		const ans = await this.loadKMLs(true);
		this.loadPercentage$.next(1);
		this.initialLoading = false;
		return ans;
	};

	// called when entering the map view
	private readonly mapLoad: Function = async () => {
		await this.loadMapItems(false);
		await this.loadCommandStructure(false);
		await this.loadARAStatic(false);
		return await this.loadARAMission(!this.initialLoading);
	};

	private readonly fullLoad: Function = async () => {
		await this.loginLoad();
		if (this.ems.getCurrentMission()) return await this.mapLoad();
		/*await this.loadUser(false);
		await this.loadConfiguration(false);
		await this.loadMissions(false);
		await this.loadResources(false);
		await this.loadDrones(false);
		await this.loadMapItems(false);
		await this.loadKMLs(false);
		await this.loadCommandStructure(false);
		return await this.loadARAMission(true);*/
	};

	private readonly cleanData: Function = () => {
		this.user.unload();
		this.conf.unload();
		this.ems.unload();
		this.resource.unload();
		// @ts-ignore
		if (this.mySocket && this.mySocket.unloadDrones) this.mySocket.unloadDrones();
		this.mis.unload();
		this.decisions.unload();
		this.command.unload();
		this.ara.unload();
	};

	private readonly updateArea: Function = (area_id: number) => {
		this.flagUpdate();
		this.mis.getAreaUpdate(area_id);
		this.unflagUpdate();
		return true;
	};

	private readonly updatePoi: Function = (poi_id: number) => {
		this.flagUpdate();
		this.mis.getPoiUpdate(poi_id);
		this.unflagUpdate();
		return true;
	};

	private readonly updatePlane: Function = (plane_id: number) => {
		this.flagUpdate();
		this.mis.getOverlayUpdate(plane_id);
		this.unflagUpdate();
		return true;
	};

	private readonly removePlane: Function = (plane_id: number) => {
		this.flagUpdate();
		this.mis.deleteOverlayUpdate(plane_id);
		this.unflagUpdate();
		return true;
	};


	private readonly updateCamera: Function = (cam_id: number) => {
		this.flagUpdate();
		this.resource.getCameraById(cam_id);
		this.unflagUpdate();
		return true;
	};

	private readonly updateState: Function = (state_id: number) => {
		this.flagUpdate();
		this.resource.getStateById(state_id);
		this.unflagUpdate();
		return true;
	};

	private readonly deleteState: (state_id: number) => boolean = (state_id) => {
		this.flagUpdate();
		this.resource.removeStateUpdate(state_id);
		this.unflagUpdate();
		return true;
	};

	private readonly updateResource: Function = async (res_id: number) => {
		this.flagUpdate();
		return this.resource.getAgentChange(res_id).then(() => this.unflagUpdate());
	};

	private readonly deleteResource: Function = (res_id: number) => {
		this.flagUpdate();
		this.resource.deleteAgentById(res_id);
		this.unflagUpdate();
		return true;
	};

	private readonly updateNewAppRelation: Function = (id: number) => {
		this.flagUpdate();
		this.resource.newAppRelationUpdate(id);
		this.unflagUpdate();
		return true;
	};

	private readonly updateDeleteAppRelation: Function = (id: number) => {
		this.flagUpdate();
		this.resource.deleteAppRelationUpdate(id);
		this.unflagUpdate();
		return true;
	};

	private readonly purgeAllPersonnelFromAppliance: Function = (id: number) => {
		this.flagUpdate();
		this.resource.purgeRelationsFromApp(id);
		this.unflagUpdate();
		return true;
	};

	private readonly updateNewSkillRelation: Function = (id: number) => {
		this.flagUpdate();
		this.resource.newSkillRelationUpdate(id);
		this.unflagUpdate();
		return true;
	};

	private readonly updateFsg = (id: number, id_incident: number, idMissionChanged?: boolean): boolean => {
		if (idMissionChanged || this.belongsToCurrentIncident(id_incident)) {
			this.flagUpdate();
			this.fsg.getUpdateFsg(id);
			this.unflagUpdate();
		}
		return true;
	};

	private readonly updateFlat = (id: number, id_mission: number): void => {
		if (this.belongsToCurrentIncident(id_mission)) {
			this.flagUpdate();
			this.fsg.getUpdateFlat(id, id_mission);
			this.unflagUpdate();
		}
	};

	private readonly updateDeleteSkillRelation: Function = (id: number) => {
		this.flagUpdate();
		this.resource.deleteSkillRelationUpdate(id);
		this.unflagUpdate();
		return true;
	};

	private readonly updateConf: Function = async (conf_id: number) => {
		this.flagUpdate();
		await this.conf.load(true);
		return true;
	};

	private readonly loadUser: Function = async (user_id: number, lastLoading: boolean) => {
		this.mssg.fire(MESSAGE_TYPE.SET_LOADING_INFO, {
			message: this.text().USER_LOADING,
			loadingFinished: false
		});
		(await user_id) ? this.user.getUserUpdate(user_id) : this.user.load(true);
		if (lastLoading) {
			this.unflagUpdate();
			this.mssg.fire(MESSAGE_TYPE.SET_LOADING_INFO, {
				message: this.text().LOADING_FINISHED,
				loadingFinished: true
			});
		}
		return true;
	};

	private readonly deleteUser = (id: number): void => {
		this.user.deleteUserUpdate(id);
	};

	private readonly loadConfiguration: Function = async (lastLoading: boolean) => {
		this.flagUpdate();
		this.mssg.fire(MESSAGE_TYPE.SET_LOADING_INFO, {
			message: this.text().CONF_LOADING,
			loadingFinished: false
		});
		await this.conf.load(true);
		if (lastLoading) {
			this.unflagUpdate();
			this.mssg.fire(MESSAGE_TYPE.SET_LOADING_INFO, {
				message: this.text().LOADING_FINISHED,
				loadingFinished: true
			});
		}
		return true;
	};

	private readonly loadMissions: Function = async (lastLoading: boolean) => {
		this.flagUpdate();
		this.mssg.fire(MESSAGE_TYPE.SET_LOADING_INFO, {
			message: this.text().MISSIONS_LOADING,
			loadingFinished: false
		});
		await this.ems.load(true);
		if (lastLoading) {
			this.unflagUpdate();
			this.mssg.fire(MESSAGE_TYPE.SET_LOADING_INFO, {
				message: this.text().LOADING_FINISHED,
				loadingFinished: true
			});
		}
		return true;
	};

	private readonly loadResources: Function = async (lastLoading: boolean) => {
		this.flagUpdate();
		this.mssg.fire(MESSAGE_TYPE.SET_LOADING_INFO, {
			message: this.text().AGENTS_LOADING,
			loadingFinished: false
		});
		await this.resource.load(true);
		await this.resource.loadMission(true);
		if (lastLoading) {
			this.unflagUpdate();
			this.mssg.fire(MESSAGE_TYPE.SET_LOADING_INFO, {
				message: this.text().LOADING_FINISHED,
				loadingFinished: true
			});
		}
		return true;
	};

	private readonly loadDrones: Function = async (lastLoading: number) => {
		if (!this.mySocket || !this.mySocket.getAllDronesDB) return true; // TODO: implement mySocket
		this.flagUpdate();
		this.mssg.fire(MESSAGE_TYPE.SET_LOADING_INFO, {
			message: this.text().DRONES_LOADING,
			loadingFinished: false
		});
		await this.mySocket.getAllDronesDB(true);
		if (lastLoading) {
			this.unflagUpdate();
			this.mssg.fire(MESSAGE_TYPE.SET_LOADING_INFO, {
				message: this.text().LOADING_FINISHED,
				loadingFinished: true
			});
		}
		return true;
	};

	private readonly loadKMLs: Function = async (lastLoading: number) => {
		this.flagUpdate();
		this.mssg.fire(MESSAGE_TYPE.SET_LOADING_INFO, {
			message: this.text().KML_LOADING,
			loadingFinished: false
		});
		await this.mis.confLoad(true);
		if (lastLoading) {
			this.unflagUpdate();
			this.mssg.fire(MESSAGE_TYPE.SET_LOADING_INFO, {
				message: this.text().LOADING_FINISHED,
				loadingFinished: true
			});
		}
		return true;
	};

	private readonly loadMapItems: Function = async (lastLoading: number) => {
		this.flagUpdate();
		this.mssg.fire(MESSAGE_TYPE.SET_LOADING_INFO, {
			message: this.text().MAP_OBJECTS_LOADING,
			loadingFinished: false
		});
		await this.mis.load(true);
		if (lastLoading) {
			this.unflagUpdate();
			this.mssg.fire(MESSAGE_TYPE.SET_LOADING_INFO, {
				message: this.text().LOADING_FINISHED,
				loadingFinished: true
			});
		}
		return true;
	};

	private readonly loadCommandStructure: Function = async () => {
		this.flagUpdate();
		this.mssg.fire(MESSAGE_TYPE.SET_LOADING_INFO, {
			message: this.text().ICS_LOADING,
			loadingFinished: false
		});
		await this.command.loadCommand();
		return true;
	};

	private readonly loadARAStatic: Function = async (lastLoading: boolean) => {
		await this.ara.load();
		if (lastLoading) {
			this.unflagUpdate();
			this.mssg.fire(MESSAGE_TYPE.SET_LOADING_INFO, {
				message: this.text().LOADING_FINISHED,
				loadingFinished: true
			});
		}
		return true;
	};

	private readonly loadARAMission: Function = async (lastLoading: boolean) => {
		this.mssg.fire(MESSAGE_TYPE.SET_LOADING_INFO, {
			message: this.text().ARA_LOADING,
			loadingFinished: false
		});
		if (this.ems.getCurrentMission()) await this.ara.loadMissionData(this.ems.getCurrentMission()!.id);
		if (lastLoading) {
			this.unflagUpdate();
			this.mssg.fire(MESSAGE_TYPE.SET_LOADING_INFO, {
				message: this.text().LOADING_FINISHED,
				loadingFinished: true
			});
		}
		return true;
	};

	private readonly updateCommander: Function = async () => {
		this.flagUpdate();
		await this.command.updateMissionCommander();
		this.unflagUpdate();
	};

	private readonly updateSector: Function = async (id: number) => {
		this.flagUpdate();
		await this.command.updateSector(id);
		this.unflagUpdate();
	};

	private readonly deleteSector: Function = async (id: number) => {
		this.flagUpdate();
		await this.command.deleteSectorUpdate(id);
		this.unflagUpdate();
	};

	private readonly updateSupport: Function = async (id: number) => {
		this.flagUpdate();
		await this.command.updateSupport(id);
		this.unflagUpdate();
	};

	private readonly deleteSupport: Function = async (id: number) => {
		this.flagUpdate();
		await this.command.deleteSupportUpdate(id);
		this.unflagUpdate();
	};

	private readonly updateIcsResource: Function = async (id: number) => {
		this.flagUpdate();
		await this.command.updateCSResource(id);
		this.unflagUpdate();
	};

	private readonly updateIcsCalloutLog: Function = async () => {
		this.flagUpdate();
		await this.callout.updateResourceLog();
		this.unflagUpdate();
	};

	private readonly deleteIcsResource: Function = async (id: number) => {
		this.flagUpdate();
		await this.command.deleteCSResourceUpdate(id);
		this.unflagUpdate();
	};

	private readonly startBufferReplay: Function = async () => {
		//update configuration,users,levels,gmap,kmls and drones
		let result = await this.conf.load(true);
		result = await this.mis.confLoad(result);
		result = await this.user.load(result);
		result = await this.resource.loadPreBuffer;
		if (this.mySocket && this.mySocket.getAllDronesDB) result = await this.mySocket.getAllDronesDB;
		result = await this.buffer.fillBuffer();
		return result;
	};

	/**
	 * Tries to update and sets interval for successive updatings
	 *
	 * @method start
	 */
	private readonly start: Function = async (fullload?: boolean) => {
		if (!this.liveMode && this.buffer.isBuffered() && !this.buffer.isFilled()) {
			//don't start until buffer is filled, this will be called then
			return false;
		}
		this.go = true;
		this.loginLoad(fullload); // loads only the information necessary for the homepage display
		const lastChange_json = await this.wreq.GetLastChange(this.lastChangeLocal);
		if (lastChange_json && lastChange_json.length > 0) {
			this.lastChange = Change.fromJson(lastChange_json[lastChange_json.length - 1]);
			this.setLastRequestFromDB(this.lastChange);
		}
		if (this.updateIntervalPromise) clearInterval(this.updateIntervalPromise);
		if (this.syncIntervalPromise) clearInterval(this.syncIntervalPromise);

		this.updateIntervalPromise = setInterval(this.tryUpdate, this.cnst.UPDATE_REQUEST_INTERVAL_MS);
		if (this.cnst.SYNCHRONIZATION_ENABLED) this.syncIntervalPromise = setInterval(this.getSyncChanges, this.SYNCHRONIZATION_RATE_MS);

		return true;
	};

	private readonly stop: Function = () => {
		this.go = false;
		if (this.updateIntervalPromise) clearInterval(this.updateIntervalPromise);
		if (this.syncIntervalPromise) clearInterval(this.syncIntervalPromise);
		this.cleanData();
	};

	/* Playback */

	private readonly setLiveMode = () => {
		if (this.liveMode != true) {
			this.liveMode = true;
			this.wreq.setTimestamp(null, null);
			this.loadResources(true);
			this.mssg.fire(MESSAGE_TYPE.PLAY_MODE_CHANGE, {
				liveMode: this.liveMode,
				buffered: this.buffer.isBuffered(),
				currentBuffer: this.buffer
			});
		}
		if (!this.go) this.start();
	};

	private readonly setPlaybackMode = async () => {
		if (this.buffer.isBuffered()) {
			await this.startBufferReplay();
			this.liveMode = false;
			if (!this.go) this.start();
		} else {
			this.liveMode = false;
		}
		this.mssg.fire(MESSAGE_TYPE.PLAY_MODE_CHANGE, {
			liveMode: this.liveMode,
			buffered: this.buffer.isBuffered()
		});
	};

	private readonly onMissionLoaded: Function = async () => {
		const mission = this.ems.getCurrentMission();
		if (mission && mission.closed) {
			this.buffer.getChanges();
			this.setPlaybackMode();
		} else this.setLiveMode();
	};

	private belongsToCurrentIncident(id_mission: number | undefined): boolean {
		const currentMissionId = this.ems.getCurrentMission()?.id;
		if (!currentMissionId || currentMissionId === -1 || !id_mission || id_mission === -1 || id_mission !== currentMissionId) return false;
		return true;
	}
}
