import { Injectable } from "@angular/core";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import { DTOArray } from "src/app/dto/net/dto-array";
import { Incident } from "src/app/dto/items/incident";
import { Resource } from "src/app/dto/resources/resource";
import { Level } from "src/app/dto/user/level";
import { User } from "src/app/dto/user/user";
import { MessagingService } from "src/app/global/messaging/messaging.service";
import { MESSAGE_TYPE } from "src/app/global/messaging/messages";
import { CloneFactory } from "src/app/dto/net/clone-factory";
import { AccessService } from "src/app/login/access.service";
import { LoginService } from "src/app/login/login.service";
import { ConfigurationService } from "../types/configuration.service";
import { Subject } from "rxjs";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Injectable({
	providedIn: "root"
})
export class UserService {
	public readonly text: () => LocaleMap;
	public Users: Array<User> = new Array();
	public Levels: Array<Level> = new Array();

	public readonly $userLevelChanged = new Subject<Level>();
	public readonly $userChanged = new Subject<User>();

	private readonly wreq: WebRequestFactory;
	private readonly mssg: MessagingService;
	private readonly login: LoginService;
	private readonly conf: ConfigurationService;
	private readonly Access: AccessService;
	private currentUserLevel: Level | undefined;

	constructor(wreq: WebRequestFactory, mssg: MessagingService, login: LoginService, conf: ConfigurationService, acc: AccessService, textProv: TextProvider) {
		this.wreq = wreq;
		this.mssg = mssg;
		this.login = login;
		this.conf = conf;
		this.Access = acc;
		this.text = textProv.getStringMap;
		this.login.$onLogin.subscribe(() => this.onLogin());
		this.mssg.registerListener(MESSAGE_TYPE.LOGOUT, this.onLogout, false, false);
		this.mssg.registerListener(MESSAGE_TYPE.LOAD_AGENTS, this.attachResourcesToUsers);
	}

	public readonly getImgName: Function = (user: User) => {
		if (user.id_picture == -1) return false;
		return this.wreq.getFilename(user.id_picture);
	};

	public readonly updateUsers: Function = async (success: boolean) => {
		if (success) {
			const jsonArray = await this.wreq.getUsers();
			if (!jsonArray) return false;
			DTOArray.UpdateFromJsonArray(this.Users, jsonArray, User);
			this.setUsers();
			this.checkAndUpdateUserLevel();
			return true;
		} else return false;
	};

	public async getUserUpdate(id: number): Promise<boolean> {
		const userJson = await this.wreq.getUsers(id);
		if (!userJson) return false;
		const user = User.fromJson(userJson);
		const idx = this.Users.findIndex((e) => e.id === user.id);
		if (idx > -1) CloneFactory.cloneProperties(this.Users[idx], user);
		else this.Users.unshift(user);
		this.setUser(user);
		this.checkAndUpdateUserLevel();
		this.$userChanged.next(user);
		return true;
	}

	public readonly updateLevels: Function = async (success: boolean, fireLoginLoad = true) => {
		if (success) {
			const jsonArray = await this.wreq.getLevels();
			if (!jsonArray) return false;
			DTOArray.UpdateFromJsonArray(this.Levels, jsonArray, Level);
			await this.updateUserPermisions(true);
			if (fireLoginLoad) this.login.$loginFinished.next({ fullLoad: false, changeRoute: false });
			return true;
		} else return false;
	};

	public readonly saveUser: Function = async (user: User): Promise<User | null> => {
		const userJson = await this.wreq.saveUser(user);
		let ans: User | null = null;
		if (userJson) {
			//current
			ans = User.fromJson(userJson);
			ans.__level = this.Levels.find((e) => e.id === user.id_level);
			user.id = ans.id;
			user.__level = ans.__level;
			//
			// (incoming) ans = User.fromJson(userJson);
			if (user.id === this.login.user.id) {
				this.login.user = ans;
				this.login.attemptCredentials.username = ans.name;
			}
			let oldUser = this.Users.find((user) => user.id === ans?.id);
			if (oldUser) {
				oldUser.id_level = ans.id_level;
				oldUser.id_picture = ans.id_picture;
				oldUser.id_resource = ans.id_resource;
				oldUser.missions_permitted = ans.missions_permitted;
				oldUser.name = ans.name;
				oldUser.phonenumber = ans.phonenumber;
				oldUser.real_id = ans.real_id;
				oldUser.real_name = ans.real_name;
				oldUser.role = ans.role;
				oldUser.__level = ans.__level;
			} else {
				//current
				let userFromJson = User.fromJson(userJson);
				CloneFactory.cloneProperties(user, userFromJson);
				this.Users.push(user);
			}
		}
		return ans;
	};

	public readonly deleteUser: Function = async (user: User) => {
		const success = await this.wreq.deleteUser(user.id);
		if (success) {
			DTOArray.DeleteById(this.Users, user.id);
			return true;
		} else return false;
	};
	public readonly saveLevel = async (level: Level): Promise<Level | undefined> => {
		const levelJson = await this.wreq.saveLevel(level);
		if (levelJson) {
			DTOArray.AddFromJson(this.Levels, levelJson, Level);
			this.onLogin(level);
			setTimeout(() => this.$userLevelChanged.next(level));
			return Level.fromJson(levelJson);
		} else return;
	};

	public readonly grantUserPermission: Function = async (user: User, mission: Incident, grant: boolean, mission_id: number) => {
		if (!mission_id) mission_id = mission.id;
		if (user.missions_permitted.includes(mission_id)) return true;
		const success = await this.wreq.grantUserPermission(user.id, mission_id, grant);
		if (success) {
			if (!user.missions_permitted.includes(mission_id) && grant) user.missions_permitted.push(mission_id);
			if (user.missions_permitted.includes(mission_id) && !grant) user.missions_permitted.splice(user.missions_permitted.indexOf(mission_id), 1);
			return true;
		} else return false;
	};

	public readonly userIs: Function = (user: User, role: string) => {
		var level = this.Levels.filter(function (level) {
			return level.name == role;
		});
		if (level.length > 0) return user.id_level <= level[0].id;
		return false;
	};

	public readonly getCurrentUserLevel: () => Level = () => {
		return this.currentUserLevel ? this.currentUserLevel : new Level();
	};

	public getUserLevel(user: User): string {
		const level = this.Levels.find((level) => level.id === user.id_level);
		return level ? this.getLevelName(level) : "";
	}

	public getLevelName(level: Level): string {
		return level.name;
	}
	public getLevelDescription(level: Level): string {
		if (level.description === "") {
			const levelDescriptionMap: { [key: number]: string } = {
				100: (level.description = this.text().PERMISSION_INFO_ADMIN),
				200: (level.description = this.text().PERMISSION_INFO_COMMANDER),
				300: (level.description = this.text().PERMISSION_INFO_CREW),
				400: (level.description = this.text().PERMISSION_INFO_SUPPORT),
				500: (level.description = this.text().PERMISSION_INFO_GUEST)
			};
			const levelId = level.id;
			level.description = levelDescriptionMap[levelId];
		}

		return level.description;
	}
	public readonly load: Function = (success: boolean) => {
		return this.updateLevels(success, false)
			.then(() => this.updateUsers(true))
			.catch(function (error: any) {
				console.error(error);
			});
	};
	public readonly unload: Function = () => {
		this.Users.length = 0;
		this.Levels.length = 0;
	};

	public readonly isResourceWithUser: Function = (agent: Resource) => {
		for (var i = 0; i < this.Users.length; i++) {
			if (this.Users[i].id_resource == agent.id) return true;
		}
		return false;
	};

	public readonly deleteUserUpdate = (id: number): void => {
		const idx = this.Users.findIndex((e) => e.id === id);
		if (idx > -1) {
			this.Users.splice(idx, 1);
			this.mssg.fire(MESSAGE_TYPE.DELETE_USER_UPDATE, id);
		}
	};

	public loggedInUser(user: User): boolean {
		return user.id === this.login.user.id;
	}

	private readonly checkAndUpdateUserLevel = (): void => {
		const user = this.Users.find((e) => e.id === this.login.user.id);
		if (!user) return;
		user.__level = this.Levels.find((e) => e.id === user.id_level);
		this.currentUserLevel = user?.__level;
		// this.$userLevelChanged.next(this.currentUserLevel);
	};

	private readonly onLogin = async (level?: Level): Promise<void> => {
		await this.load(true);
		await this.updateUserPermisions(true);
		const goToHome = level ? level && level.id === this.login.user.id_level && !level.users_manage : true;
		this.login.$loginFinished.next({ fullLoad: level ? false : true, changeRoute: level ? (goToHome ? goToHome : false) : true });
	};

	private readonly setUsers: Function = async () => {
		for (let user of this.Users) {
			this.setUser(user);
		}
		this.mssg.fire(MESSAGE_TYPE.UPDATE_USERS);
		return true;
	};

	private readonly setUser: Function = async (user: User) => {
		if (!user.picture_filename && user.id_picture && user.id_picture > -1) var ans = await this.getImgName(user);
		user.picture_filename = ans;
		user.__level = this.Levels.find((e) => e.id === user.id_level);
	};

	private readonly attachResourcesToUsers: Function = (resources: Array<Resource>) => {
		if (!resources) return;
		for (let user of this.Users) {
			user.__resource = resources.find((e) => e.id === user.id_resource);
		}
	};

	private readonly updateUserPermisions: Function = (success: boolean) => {
		if (success) {
			this.currentUserLevel = this.Levels.find((e) => e.id === this.login.user.id_level);
			this.conf.setUserLevel(this.currentUserLevel);
			this.Access.setUserInfo(this.login.user, this.currentUserLevel);
		}
		return false;
	};

	private readonly onLogout: Function = () => {
		this.currentUserLevel = undefined;
	};
}
