import { Component, Input } from "@angular/core";
import { CommandStructure } from "src/app/dto/command-structure/command-structure";
import { Event } from "src/app/dto/items/event";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-cs-header",
	templateUrl: "./cs-header.component.html",
	styleUrls: ["../../../document.css"]
})
export class CsHeaderComponent {
	@Input() event?: Event;
	@Input() commandStructure?: CommandStructure;
	@Input() pdf: string = "";

	public readonly text: () => LocaleMap;

	constructor(tp: TextProvider) {
		this.text = tp.getStringMap;
	}

	public getIncidentCommander(): string {
		if (this.commandStructure && this.commandStructure.commander) return this.commandStructure.commander.name;
		return "-";
	}
}
