import { Injectable } from "@angular/core";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import { Change, CHANGE_TYPE } from "src/app/dto/net/change";
import { DTOArray } from "src/app/dto/net/dto-array";
import { ReplayBuffer } from "src/app/dto/replay/replay-buffer";
import { EventsMissionsService } from "src/app/incident/event-mission.service";
import { MAP_ITEM_TYPE } from "../../constants/enums/map-item-type";
import { MESSAGE_TYPE } from "../../messaging/messages";
import { MessagingService } from "../../messaging/messaging.service";
import { BufferProvider } from "./buffer.provider";
import { Overlay } from "src/app/dto/items/overlay";
import { Subject } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class BufferService {
	private readonly wreq: WebRequestFactory;
	private readonly ems: EventsMissionsService;
	private readonly mssg: MessagingService;
	private readonly bufferProvider: BufferProvider;

	constructor(wreq: WebRequestFactory, ems: EventsMissionsService, mssg: MessagingService, buffer: BufferProvider) {
		this.wreq = wreq;
		this.ems = ems;
		this.mssg = mssg;
		this.bufferProvider = buffer;

		mssg.registerListener(MESSAGE_TYPE.LOAD_MISSION_PAGE, async () => {
			if (this.ems.getCurrentMission()?.closed) {
				await this.getChanges();
				this.setReferences();
				this.bufferProvider.$filled.next();
			} else this.fillBuffer(); // for resource tracks when live incident, when close, buffer is filled in automation-service
		});
	}

	public isBuffered(): boolean {
		return this.bufferProvider.buffered;
	}

	public isFilled(): boolean {
		return this.bufferProvider.filled;
	}

	public readonly fillBuffer: Function = async () => {
		this.bufferProvider.filled = false;
		let mission = this.ems.getCurrentMission();
		if (mission) {
			const data = await this.wreq.getBufferData(mission.id);
			if (!data) return false;
			this.bufferProvider.buffer = ReplayBuffer.fromJson(data);
			const overlaydata = await this.wreq.getAllOverlays(false, mission.id);
			this.bufferProvider.buffer!.setItemArray(overlaydata, MAP_ITEM_TYPE.OVERLAY);
			this.bufferProvider.filled = true;
			return true;
		} else return false;
	};

	public readonly getChanges: Function = async () => {
		const mission = this.ems.getCurrentMission()!;
		if (mission.closed) {
			const jsonArray = await this.wreq.getChangesTimestamps(mission.id);
			if (jsonArray) {
				this.bufferProvider.changes.length = 0;
				DTOArray.UpdateFromJsonArray(this.bufferProvider.changes, jsonArray, Change);
				for (let i = 0; i < this.bufferProvider.changes.length; i++) {
					this.bufferProvider.changes[i].__time_percent = (100 * (this.bufferProvider.changes[i].last_update - mission.start_time_ms)) / mission.duration;
				}
			}
		}
	};

	private readonly setReferences = (): void => {
		this.bufferProvider.changes.forEach((change) => {
			let idx = this.bufferProvider.buffer.agents.findIndex((e) => e.timestamp === change.last_update);
			if (idx > -1) {
				change.__object = this.bufferProvider.buffer.agents[idx];
				change.datatype = CHANGE_TYPE.RESOURCE;
				return;
			}
			idx = this.bufferProvider.buffer.areas.findIndex((e) => e.timestamp === change.last_update);
			if (idx > -1) {
				change.__object = this.bufferProvider.buffer.areas[idx];
				change.datatype = CHANGE_TYPE.AREA;
				return;
			}
			idx = this.bufferProvider.buffer.pois.findIndex((e) => e.timestamp === change.last_update);
			if (idx > -1) {
				change.__object = this.bufferProvider.buffer.pois[idx];
				change.datatype = CHANGE_TYPE.POI;
				return;
			}
		});
	};
}
