import { Injectable } from "@angular/core";
import { WebRequestFactory } from "../http/web.request.factory";
import { ConfigurationService } from "../settings/types/configuration.service";
import { DTOArray } from "../dto/net/dto-array";
import { Event } from "../dto/items/event";
import { Incident } from "../dto/items/incident";
import { MessagingService } from "../global/messaging/messaging.service";
import { MESSAGE_TYPE } from "../global/messaging/messages";
import { Subject } from "rxjs";
import { MAP_PROFILES } from "../global/constants/enums/map-profiles";
import { LOG_TYPE } from "../global/constants/enums/log_types";

@Injectable({
	providedIn: "root"
})
export class EventsMissionsService {
	public readonly loadMission$ = new Subject<Incident>();

	/**
	 * Array of all the events
	 * @property Events
	 * @type Event[]
	 */
	public Events: Array<Event> = new Array<Event>();

	public currentProfile: MAP_PROFILES = MAP_PROFILES.OFV;
	public isBuffered?: boolean;
	/**
	 * Array of all missions
	 * @property Missions
	 * @type Mission[]
	 */
	public Missions: Array<Incident> = new Array<Incident>();

	private currentMission: Incident | undefined;
	private readonly wreq: WebRequestFactory;
	private readonly mssg: MessagingService;
	private readonly conf: ConfigurationService;

	constructor(wreq: WebRequestFactory, mssg: MessagingService, conf: ConfigurationService) {
		this.wreq = wreq;
		this.mssg = mssg;
		this.conf = conf;

		mssg.registerListener(MESSAGE_TYPE.PLAY_MODE_CHANGE, (params: { liveMode: boolean; buffered: boolean }) => {
			this.currentProfile = params.liveMode ? MAP_PROFILES.OFV : MAP_PROFILES.HISTORIC;
			this.isBuffered = params.buffered;
		});
	}

	public readonly isLive: () => boolean = () => {
		return this.currentProfile === MAP_PROFILES.OFV;
	};

	public readonly getCurrentMission: () => Incident | undefined = () => {
		return this.currentMission;
	};

	public readonly getCurrentEvent: Function = (): Event | null | undefined => {
		return this.currentMission ? this.Events.find((e) => e.mission && this.currentMission && e.mission.id == this.currentMission.id) : null;
	};

	// if lazyLoad is not set, the front-end will send several requests to fetch mission-related data
	public readonly setCurrentMission: (mission: Incident, lazyLoad?: boolean) => Promise<boolean> = async (mission, lazyLoad?) => {
		if (mission && (!this.currentMission || mission.id !== this.currentMission.id)) {
			this.currentMission = mission;
			await this.conf.setCurrentmissionId(mission.id);
			this.wreq.logInformation(mission.closed ? LOG_TYPE.CLOSED_INCIDENT_LOGIN : LOG_TYPE.INCIDENT_LOGIN, mission.id.toString());
			if (!lazyLoad) {
				this.loadMission$.next(mission);
				this.mssg.fire(MESSAGE_TYPE.TRY_UPDATE);
			}
			return true;
		} else return false;
	};

	public readonly isMissionSet: Function = () => {
		return this.currentMission != null;
	};

	public readonly load: Function = async (success: any) => {
		await this.updateMissions(success);
		const result = await this.updateEvents();
		this.mssg.fire(MESSAGE_TYPE.LOAD_EVENTS_MISSION);
		return result;
	};

	public readonly unload: Function = () => {
		this.unsetCurrentMission();
		this.Events.length = 0;
		this.Missions.length = 0;
	};
	/**
	 * Sends request to the webservive to save an Event to the server. Returns the Event as it was saved
	 * (if it's not able to save some part of it , it returns the area as exists on the server  )
	 * @method saveEvent
	 * @param {Event} event The event to be saved.
	 * @return {Event}
	 */
	public readonly saveEvent: Function = async (event: Event, missionName: string) => {
		if (!this.isLive()) return false;
		//If it's an existing event
		if (event.id > 0) {
			const evt = await this.wreq.saveEvent(event);
			if (evt) {
				DTOArray.AddFromJson(this.Events, event, Event, this.addMissionToEvent);
				this.mssg.fire(MESSAGE_TYPE.UPDATE_EVENTS);
				return evt;
			} else return false;
		} //If its new also create mission for it
		else {
			const emJsonArr = await this.wreq.newEventMission(event, missionName);
			if (emJsonArr) {
				this.Missions.push(Incident.fromJson(emJsonArr[1]));
				DTOArray.AddFromJson(this.Events, emJsonArr[0], Event, this.addMissionToEvent);
				this.mssg.fire(MESSAGE_TYPE.UPDATE_EVENTS);
				var newEvent = Event.fromJson(emJsonArr[0]);
				return newEvent;
			} else return false;
		}
	};
	/**
	 * Saves new event to server. Returns as it was saved in the server.
	 * Calls {{#crossLink "Main.Items.eventsService/saveEvent:method"}}{{/crossLink}
	 * @method saveNewEvent
	 * @param {String} name The event name.
	 * @param {Int} perimeter_id The event perimeter area id
	 * @param {Int} type The event custom type.
	 * @return {Event}
	 */
	public readonly saveNewEvent: Function = (name: string, perimeter_id: number, type: number, missionName: string, num: number) => {
		if (!this.isLive()) return false;
		return this.saveEvent(new Event(-1, name, perimeter_id, type, -1, num), missionName);
	};

	/**
	 * Sends request to server to delete an event.
	 * @method deleteEvent
	 * @param {Event} event The event to delete
	 * @return {Boolean} True if it was deleted, false otherwise.
	 */
	public readonly deleteEvent: Function = async (event: Event): Promise<boolean> => {
		if (!this.isLive()) return false;
		const success = await this.wreq.deleteEvent(event.id);
		if (success) {
			DTOArray.DeleteById(this.Events, event.id);
			this.mssg.fire(MESSAGE_TYPE.UPDATE_EVENTS);
			return true;
		}

		return false;
	};

	public readonly closeCurrentMission: Function = async (): Promise<boolean> => {
		if (this.currentMission) {
			this.currentMission.closed = true;
			this.currentMission.end_time = new Date().toISOString();
			this.currentMission.end_time_ms = Date.now();
			this.currentMission.closed = true;
			/* 			var ev = this.Events.find((e) => e.mission && this.currentMission && e.mission.id == this.currentMission.id);
			if (ev) ev.mission = this.currentMission; */
			await this.saveMission(this.currentMission);
			//this.mssg.fire(MESSAGE_TYPE.CLOSE_CURR_MISSION, this.currentMission ? this.currentMission.id : null);
			this.exitCurrentMission();
			return true;
		}
		return true;
	};

	public readonly reopenCurrentMission: Function = async () => {
		if (this.currentMission) {
			this.currentMission.closed = false;
			this.currentMission.end_time = "";
			this.currentMission.end_time_ms = 0;
			var ev = this.Events.find((e) => e.mission && this.currentMission && e.mission.id == this.currentMission.id);
			if (ev) ev.mission = this.currentMission;
			await this.saveMission(this.currentMission);
			this.mssg.fire(MESSAGE_TYPE.OPEN_CURR_MISSION, this.currentMission ? this.currentMission.id : null);
			this.exitCurrentMission();
			return true;
		}
		return true;
	};

	public readonly exitCurrentMission: Function = () => {
		this.unsetCurrentMission();
	};

	/**
	 * Sends request to the webservive to save an Mission to the server. Returns the Mission as it was saved
	 * (if it's not able to save some part of it , it returns the area as exists on the server  )
	 * @method saveMission
	 * @param {Mission} mission The mission to be saved.
	 * @return {Mission}
	 */
	public readonly saveMission: Function = async (mission: Incident) => {
		const missionJson = await this.wreq.saveMission(mission);
		if (missionJson) {
			DTOArray.AddFromJson(this.Missions, missionJson, Incident, null);
			this.mssg.fire(MESSAGE_TYPE.UPDATE_EVENTS);
			return true;
		} else return false;
	};

	private readonly unsetCurrentMission: Function = () => {
		this.currentMission = undefined;
		this.conf.setCurrentmissionId(-1);
		this.mssg.fire(MESSAGE_TYPE.UNLOAD_MISSION);
	};

	/**
	 * Requests all the Events data to the server.
	 * @method updateEvents
	 * @return {Event[]}
	 */
	private readonly updateEvents: Function = async () => {
		const jsonArray = await this.wreq.getAllEvents();
		if (!jsonArray) return false;

		DTOArray.UpdateFromJsonArray(this.Events, jsonArray, Event);
		for (let evt of this.Events) {
			evt.mission = this.Missions.find((e) => e.id === evt.id_mission);
			evt.__typeObj = this.conf.configuration.eventTypes.find((e) => e.id === evt.type);
		}
		this.Events.sort((a, b) => {
			return a.mission && b.mission && a.mission.name > b.mission.name ? 1 : 0;
		});
		this.mssg.fire(MESSAGE_TYPE.UPDATE_EVENTS);
		return true;
	};
	private readonly addMissionToEvent: Function = (event: Event) => {
		if (!this.isLive) return false;

		event.mission = this.Missions.find((e) => e.id === event.id_mission);
		event.__typeObj = this.conf.configuration.eventTypes.find((e) => e.id === event.type);
		return true;
	};
	/**
	 * Requests all the Missions data to the server.
	 * @method updateMissions
	 * @return {Mission[]}
	 */
	private readonly updateMissions: Function = async (success: any) => {
		if (!success) return Promise.resolve(success);
		const jsonArray = await this.wreq.getAllMissions();
		if (!jsonArray) return false;
		DTOArray.UpdateFromJsonArray(this.Missions, jsonArray, Incident);
		this.Missions.forEach(this.setMissionTimes);
		this.Missions.sort((a, b) => {
			return a.name > b.name ? 1 : 0;
		});
		//If current mission is not set but selected, assign and fire mission loaded
		//This happens when, at start, the current mission selection is retreived from user configuration,
		//but missions array is not yet retreived from server, so after we update it, notify that it has been loaded
		if (this.conf.configuration.id_current_mission > -1) {
			const mission = this.Missions.find((e) => e.id === this.conf.configuration.id_current_mission);
			if (this.currentMission == null && mission) return this.setCurrentMission(mission);
		}
		return true;
	};

	private readonly setMissionTimes: (value: Incident, index: number, array: Incident[]) => void = (mission) => {
		mission.duration = mission.end_time && mission.start_time ? mission.end_time_ms - mission.start_time_ms : -1;
	};
}
