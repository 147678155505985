<div class="container">
	<div
		#pageElements
		*ngFor="let page of pages; let i = index"
		class="page"
		[style.width.px]="pageSize.width"
		[style.height.px]="pageSize.height"
	>
		<div class="title">
			{{ text().DEBRIEF }}
		</div>
		<div class="header">
			<div class="header-row">
				<div class="header-col">
					<div>
						<b>{{ text().DATE_OF_EVENT }}:</b
						>{{ event.mission!.start_time_ms | date : "dd/MM/YYYY" }}
					</div>
					<div>
						<b>{{ text().NUMBER }}:</b>{{ event.num }}
					</div>
				</div>
				<div class="header-col">
					<div>
						<b>{{ text().TYPE_OF_INCIDENT }}:</b> {{ event.__typeObj?.name }}
					</div>
				</div>
			</div>
			<div class="header-row">
				<div>
					<b>{{ text().DEBRIEF_TEAM_NAMES }}:</b
					><span *ngFor="let member of team; let i = index"
						>{{
							member.name +
								"(" +
								(member.organization ? member.organization : text().UNSPECIFIED) +
								")"
						}}<span *ngIf="i !== team.length - 1">, </span></span
					>
				</div>
			</div>
			<div class="doc-generated-on">
				<b>{{ text().DOCUMENT_GENERATED_ON }}</b
				>:
				{{
					generationTime
						| date
							: "dd/MM/YY
				HH:mm"
				}}
			</div>
		</div>
		<div *ngFor="let option of page" class="debrief-option">
			<div class="option-title">
				<div class="line"></div>
				<span>{{ option.header }}</span>
			</div>
			<div class="option-body">
				<div *ngFor="let item of option.options" class="option-item">
					<div class="question">{{ item.question }}</div>
					<div class="answer">- {{ item.answer }}</div>
				</div>
			</div>
		</div>
		<div class="page-index">
			<b>{{ text().PAGE }}:</b> <span>{{ i + 1 }} / {{ pages.length }}</span>
		</div>
	</div>
</div>

<div class="inspector">
	<div #inspectedOptionElement *ngIf="inspectedOption" class="debrief-option">
		<div class="option-title">
			<div class="line"></div>
			<span>{{ inspectedOption.header }}</span>
		</div>
		<div class="option-body">
			<div *ngFor="let item of inspectedOption.options" class="option-item">
				<div class="question">{{ item.question }}</div>
				<div class="answer">- {{ item.answer }}</div>
			</div>
		</div>
	</div>
</div>
