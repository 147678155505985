import { MAP_ITEM_TYPE } from "src/app/global/constants/enums/map-item-type";
import { DTO } from "../net/dto";
import { MapItem } from "./map-item";

export class Kml extends MapItem implements DTO {
	timestamp: number;

	mission_id: number;
	visible: boolean;

	constructor(id: number, id_mission: number, name: string, attachment_id?: number, attachment_filename?: string, timestamp?: number) {
		super(id, name, id_mission, undefined, MAP_ITEM_TYPE.KML, attachment_id, attachment_filename);
		this.name = name;
		this.mission_id = id_mission;
		this.timestamp = timestamp ? timestamp : Date.now();
		this.visible = false;
	}

	public static readonly fromJson: (json: string) => Kml = (json: string) => {
		var jp = JSON.parse(json);
		return new Kml(jp.id, jp.id_mission ? jp.id_mission : jp.mission_id, jp.name, jp.attachment_id, jp.attachment_filename, jp.timestamp);
	};

	public readonly getJson: Function = () => {
		this.id_mission = this.mission_id;
		return JSON.stringify(this, ["mission_id", "id", "name", "attachment_id", "attachment_filename", "timestamp"]); //angular.toJson( this );
	};
}
